/* eslint-disable */
/* Implementation of Rating Function
 */

// TODO: Refactor for compliance with eslint rules

jQuery.fn.stars = function(options) {
    // Clean possibly earlier initialized states.
    jQuery(this).html('');

    let settings = jQuery.extend({
            stars: 5,
            emptyIcon: 'fa-star-o',
            filledIcon: 'fa-star',
            filledHalfIcon: 'fa-star-half-o',
            value: 0,
            text: null,
            click() {},
        }, options),

        that = this;

    for (let x = 0; x < settings.stars; x++) {
        const icon = jQuery('<i>').addClass('fa').addClass(settings.emptyIcon);
        if (settings.text) {
            icon.attr('data-rating-text', settings.text[x]);
        }
        this.append(icon);
    }

    if (settings.text) {
        const textDiv = jQuery('<div>').addClass('rating-text');
        this.append(textDiv);
    }

    const stars = this.find('i');

    stars.on('mouseover', function() {
        let index = jQuery(this).index() + 1,
            starsHovered = stars.slice(0, index);
        events.removeFilledStars(stars, settings);
        events.fillStars(starsHovered, settings);

		if (settings.text) {
			that.find(".rating-text").html(jQuery(this).data("rating-text"));
		}
	}).on("mouseout", function() {
		events.removeFilledStars(stars, settings);
		events.fillStars(stars.filter(".selected"), settings);
		var halfStars = events.fillHalfStars(starsToSelectHalf, settings);

		if (halfStars !== undefined) {
			halfStars.addClass("selected");
		}

		if (settings.text) {
			that.find(".rating-text").html("");
		}

	}).on("click", function() {
		var index = jQuery(this).index();
		settings.value = index + 1;
		// stars.removeClass("selected").slice(0, settings.value).addClass("selected");
		settings.click.call(stars.get(index), settings.value);
		events.removeFilledStars(stars, settings);
		events.fillStars(stars, settings);
	});

    try {
        var events = {
            removeFilledStars (stars, s) {
                stars.removeClass(s.filledIcon).addClass(s.emptyIcon);
                stars.removeClass(s.filledHalfIcon).addClass(s.emptyIcon);
                return stars;
            },

            fillStars (stars, s) {
                stars.removeClass(s.emptyIcon).addClass(s.filledIcon);
                return stars;
            },

			fillHalfStars: function (stars, s) {
				if (stars !== undefined) {
					stars.removeClass(s.emptyIcon).addClass(s.filledHalfIcon);
				}
				return stars;
			}
		};


        if (settings.value > 0) {
            console.log(`settings.value1: ${settings.value}`);
            const factor = 0.5;
            settings.value = Math.round(settings.value / factor) * factor;
            console.log(`settings.value2: ${settings.value}`);
            var starsToSelect = stars.slice(0, settings.value),
                starsToSelectHalf = stars.slice(settings.value, Math.round(settings.value));

            settings.value = Math.round(settings.value / factor) * factor;

            if (settings.value + 0.5 === Math.round(settings.value)) {
                events.fillStars(starsToSelect, settings).addClass('selected');
                events.fillHalfStars(starsToSelectHalf, settings).addClass('selected');
            } else {
                events.fillStars(starsToSelect, settings).addClass('selected');
            }
        }
    } catch (err) {
        console.log(err.message);
    }
};
