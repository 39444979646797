/**
 * Created by gsondermeier
 *
 * read the URL params and if 'paywall-passwort-reset-status=erfolgreich' or 'paywall-passwort-reset-status=fehlerhaft'
 * or 'paywall-passwort-reset-status=abgelaufen', display a related message.
 */

/**
 * if the status is 'erfolgreich' or 'fehlerhaft' or 'abgelaufen', call staticMessage() to display a message reflecting
 * the status
 *
 * @param {string} status
 */
function paywallPasswordResetMessage(status) {
    /* eslint-disable indent */
    switch (status) {
        case 'erfolgreich':
            staticMessage('Ihnen wurde erfolgreich ein neues Passwort zugesendet. Bitte überprüfen Sie Ihr Postfach.', 'success');
            break;
        case 'fehlerhaft':
            staticMessage('Es ist ein Fehler beim Zurücksetzen Ihres Passwortes aufgetreten. Für weitere Fragen wenden Sie sich bitte an unseren Leserservice.', 'error');
            break;
        case 'abgelaufen':
            staticMessage('Der Link zum Zurücksetzen Ihres Passwortes ist abgelaufen. Bitte versuchen Sie es noch einmal von vorn oder wenden Sie sich für weitere Fragen an unseren Leserservice.', 'error');
            break;
        default:
            break;
    }
    /* eslint-enable indent */
}

/**
 * attach a document ready listender to read the URL params. if there is one present for `paywall-passwort-reset-status`,
 * call paywallPasswordResetMessage() with its value.
 */
jQuery(() => {
    const paywallPasswordResetStatus = jQuery.utils.getUrlParameter('paywall-passwort-reset-status');
    if (paywallPasswordResetStatus) {
        paywallPasswordResetMessage(paywallPasswordResetStatus);
    }
});
