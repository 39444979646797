

function RedirectionSelectbox(rootNode) {
    const that = this;

    jQuery(rootNode).on('change', function(e) {
        const link = $(this).find('option:selected').data('redirection');
        window.location.href = link || window.location.href;
    });
}

jQuery.fn.redirectionSelectbox = function() {
    return this.each(function() {
        new RedirectionSelectbox(this);
    });
};
