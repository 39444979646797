/**
 * The plugin initializes an element which persists and handles choices of the user.
 * It contains an wrapper element with the name the resource is known by.
 *
 * Author: Matthias Klebe
 * */


(function() {
    // http://stackoverflow.com/questions/10730362/get-cookie-by-name
    /* eslint-disable */
    function getCookieByName(name) {
        const value = `; ${window.document.cookie}`,
            parts = value.split(`; ${name}=`);

        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }
    /* eslint-enable */

    // http://stackoverflow.com/questions/26021281/set-cookie-with-js-for-whole-domain-not-specific-page
    /* eslint-disable */
    function setCookie(name, value) {
        if (typeof name === 'undefined' || name.length <= 0) {
            return false;
        }
        const d = new Date(),
            expDays = 100;

        d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));

        window.document.cookie = `${name}=${value}; expires=${d.toUTCString()};path=/`;
    }
    /* eslint-enable */

    function PersistListSelection(node) {
        this.$rootNode = $(node);
        this.$listItems = $('[data-more-item]', this.$rootNode);
        this.shouldReloadAfterPick = this.$rootNode.data('should-reload') || false;
        this.chosenSelect = $('[data-chosen-entry-label]', node);
        this.$listItemsSet = [];
        this.$listItemsUnset = [];
        this.resourceName = '';
        this.choice = '';
        this.initialize();
    }

    // Is called after the user made a choice
    PersistListSelection.prototype.postChoice = function() {
        if (this.shouldReloadAfterPick) {
            window.location.reload();
        }
    };

    /* eslint-disable */
    // Sets the persistent key on a new value
    PersistListSelection.prototype.setChoice = function(value) {
        if (typeof value === 'undefined') {
            console.error('PersistListSelection cannot set choice');
            return false;
        }

        this.choice = value;
        this.setPersistantValue(value);
        this.postChoice();
    };
    /* eslint-enable */

    // Resets the persistent value
    /* eslint-disable */
    PersistListSelection.prototype.unsetChoice = function() {
        this.setPersistantValue('');
        this.postChoice();
    };
    /* eslint-enable */

    // Checks th validity of the initialized Element and sets required values
    /* eslint-disable */
    PersistListSelection.prototype.initialize = function() {
        const resourceName = this.$rootNode.data('persist-list-selection-name'),
            that = this;
        let presetValue = '';

        // Check whether there is an name configured
        if (typeof resourceName === 'undefined' || resourceName.length === 0) {
            console.error('PersistListSelection cannot initialize, no resourcename specified');
            return false;
        }

        this.resourceName = resourceName;
        presetValue = this.getPersistantValue();

        // Walk through all elements. When there is an element which is already chosen, mark it and put
        // it in the list of items which un-check the choice
        $.each(this.$listItems, function() {
            const $this = $(this),
                itemValue = $this.data('more-item') || '';

            if (itemValue.length > 0 && itemValue === presetValue) {
                that.$listItemsUnset.push(this);
                $this.addClass('current');

                that.$rootNode.removeClass('choice-empty');

                //  Display the value of the selected entry in the area.
                that.chosenSelect.text($this.data('more-label'));
            } else {
                that.$listItemsSet.push(this);
            }
        });

        // Attach click handles for setting the choice
        $(this.$listItemsSet).on('click', function() {
            that.setChoice($(this).data('more-item'));
        });

        // Attach click handles for un-setting the choice
        $(this.$listItemsUnset).on('click', () => {
            that.unsetChoice();
        });
    };
    /* eslint-enable */

    PersistListSelection.prototype.getPersistantValue = function() {
        return getCookieByName(this.resourceName);
    };

    PersistListSelection.prototype.setPersistantValue = function(value) {
        setCookie(this.resourceName, value);
    };

    // Enable programmatic initialization
    $.fn.persistListSelection = function() {
        return this.each(function() {
            new PersistListSelection(this);
        });
    };

    // Initialize declarative
    $(() => {
        $('[data-persist-list-selection-name]')
            .addClass('choice-empty')
            .persistListSelection();
    });
}());
