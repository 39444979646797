

// Community plugin
$(() => {
    const urls = {
        commentPostUrl: '/template/framework/tools/post_comment.jsp',
        extLoginUrl: '/community-webservice/service/extloginuser/',
        passwordResetUrl: '/template/framework/tools/password_reset.jsp',
        urlLogin: '/community-webservice/service/login/',
        urlLogout: '/community-webservice/service/logout/',
    };

    // Constructor
    function Community(rootElement) {
        this.settings = $.extend({}, urls);
        this.$community = $(rootElement);
        this.$credentialForm = $('.community__credential-form', this.$community);
        this.$activationForm = $('.community__activation-form', this.$community);
        this.$loginForm = $('.community-login__form', this.$community);
        this.$loginButton = $('.community-login__form__submit', this.$community);
        this.$loginError = $('.community__login-error', this.$community);
        this.$loginWrapper = $('.community__login-wrapper', this.$community);
        this.$activationContent = $('.community__activation-content', this.$community);
        this.$resetPasswordMessageTarget = $('.community__reset-password-message', this.$community);
        this.$registrationForm = $('.community__registration-form', this.$community);
        this.$registrationMessageTarget = $('.community__registration-message', this.$community);
        this.$forgotPasswordForm = $('.community__forgot-password-form', this.$community);
        this.$profileForm = $('.community__profile-form', this.$community);
        this.$profileMessageTarget = $('.community__profile-message', this.$community);
        this.$addResponseMessageTarget = $('.comment__ad-response-message', this.$community);
        this.$commentRoot = $('.community__comment-list--root', this.$community);
        this.$scrollToLogin = $('.community__textlink--login', this.$community);
        this.unknownErrorMessage = 'Es ist ein Fehler aufgetreten!';
        this.registrationCommunityMessageTarget = '.community__registration-message--community';
        this.init();
    }

    $.extend(Community.prototype, {

        /**
         * Initialize community components
         */
        init () {
            this.initCommentRespond();
            this.initReportComment();
            this.initCommentReportOpen();
            this.initShowProfile();
            this.initLogin();
            this.initScrollToLogin();
            this.initLogout();
            this.initCharCounters();
            this.initResetPassword();
            this.initRegistration();
            this.initRegistrationCommunity();
            this.initProfileUpdate();
            this.initPostComment();
            this.initToggleAnswers();
            this.initReadMore();
            this.getLoginForm();
            this.loadPage(1);
            if (this.$activationForm.length > 0) {
                this.getActivationContent();
            }
        },

        /**
         * Request the login form via Ajax and show it
         */
        getLoginForm () {
            const $target = this.$loginWrapper;
            jQuery.ajax({
                type: 'POST',
                url: $target.data('login-status-url'),
                success (html) {
                    $target.html(html);
                },
            });
        },

        /**
         * Get and show the content of the community user activation
         */
        getActivationContent () {
            const that = this;
            jQuery.ajax({
                type: 'POST',
                url: this.$activationForm.data('activation-url'),
                data: {
                    activationCode: this.$activationForm.data('activation-code'),
                    user_id: this.$activationForm.data('user-id'),
                },
                success (html) {
                    that.$activationContent.html(html);
                },
            });
        },

        /**
         * Hide all currently shown error/success messages in the community
         */
        hideMessages () {
            $('.error, .message', this.$community).hide();
        },

        /**
         * Create a html error message
         */
        getErrorMessage(msg) {
            return $('<p>').addClass('error').text(msg);
        },

        /**
         * Request and show profile data
         */
        initShowProfile () {
            const that = this;
            this.$community.on('click', '.community-profile__link', function () {
                $.ajax({
                    type: 'POST',
                    url: $(this).data('profile-url'),
                    success (html) {
                        that.$credentialForm.html(html);
                    },
                    error () {
                        const $message = that.getErrorMessage(that.unknownErrorMessage);
                        that.$credentialForm.html($message);
                    },
                });
            });
        },

        /**
         * Init login handling
         */
        initLogin () {
            const that = this;
            this.$loginForm.on('submit', function (e) {
                const $form = $(this),
                    formData = $form.serializeArray();

                e.preventDefault();
                that.$loginButton.prop('disabled', true);

                // Add cookie attribute to URL if user has checked the checkbox
                $.each(formData, (i, attribute) => {
                    if (attribute.name === 'saveLogin' && attribute.value === 'true') {
                        formData.push({
                            name: 'cookieTimeout',
                            value: 28 * 86400, // 28 days
                        });
                    }
                });

                $.ajax({
                    type: 'POST',
                    url: that.settings.urlLogin,
                    data: formData,
                    success () {
                        location.reload();
                    },
                    error () {
                        that.hideMessages();
                        that.$loginButton.prop('disabled', false);
                        that.$loginError.show();
                    },
                });
            });
        },

        /**
         * Scroll to login form and open it
         */
        initScrollToLogin () {
            this.$scrollToLogin.scrollToTop({
                callback() {
                    $(document).trigger('showLoginForm');
                },
            });
        },

        /**
         * Init logout functionality
         */
        initLogout () {
            const that = this;
            this.$community.on('click', '.community-logout__link', (e) => {
                e.preventDefault();
                $.ajax({
                    url: `https://${window.location.hostname}/secure/sso/logout`,
                    type: 'post',
                    xhrFields: {
                        withCredentials: true,
                    },
                    beforeSend (xhr) {
                        xhr.setRequestHeader('Authorization', `Basic ${btoa('bz:Relaunch')}`);
                    },
                    success (r) {
                        window.location.reload();
                        // console.log("Logout successful: " + JSON.stringify(r));
                    },
                    /* eslint-disable */
                    // TODO: Refactor for compliance with eslint rules
                    error (e) {
                        const j = JSON.parse(e.responseText);
                        console.log(`Logout failed: ${j.message}`);
                    },
                    /* eslint-enable */
                });
            });
        },

        /* Removes the disable Class to the given form. The form is a jQuery-object */
        enableForm($form) {
            $form.removeClass('form--disabled');
        },

        /* Adds the disable Class to the given form. The form is a jQuery-object */
        disableForm($form) {
            $form.addClass('form--disabled');
        },

        /**
         * Init functionality to write comments
         */
        initPostComment () {
            const that = this;
            let formDisabled = false;

            /* eslint-disable */
            // TODO: Refactor for compliance with eslint rules
            this.$community.on('submit', '.comment__response-form, .comment__comment-form', function (e) {
                if (formDisabled) {
                    return false;
                }

                const $form = $(this);

                formDisabled = true;
                e.preventDefault();
                that.hideMessages();

                that.disableForm($form);


                $.ajax({
                    type: 'POST',
                    url: $form.data('comment-url') || that.settings.commentPostUrl,
                    data: $form.serialize(),
                    success (html) {
                        let $counter,
                            $answers,
                            isError = $(html).hasClass('error');

                        formDisabled = false;
                        that.enableForm($form);

                        if (isError) {
                            $form.prepend(html).show();
                        } else {
                            $form.hide();
                            // Prepend the comment as answer
                            $answers = $form.closest('.comment').find('.community__comment-list--answer');
                            if ($answers.length > 0) {
                                // Show the answer list
                                $answers.prepend(html).show();
                                $answers.closest('.comment').find('.answers__toggle').addClass('open');
                                // Raise the answer counter
                                $counter = $answers.closest('.comment').find('.answers__counter');
                                $counter.text(parseInt($counter.text(), 10) + 1);
                            }
                            // Prepend the comment as new comment
                            else {
                                that.$community.find('.community__comment-list').first().prepend(html);
                            }
                        }
                    },
                    error () {
                        const $message = that.getErrorMessage('Beim Speichern des Kommentars ist ein Fehler aufgetreten!');
                        formDisabled = false;
                        that.enableForm($form);
                        that.$addResponseMessageTarget.html($message);
                    },
                });
            /* eslint-enable */
            });
        },

        /**
         * Init the profile update form functionality
         */
        initProfileUpdate () {
            const that = this;
            this.$profileForm.on('submit', function (e) {
                const $form = $(this);
                e.preventDefault();
                that.hideMessages();
                $.ajax({
                    type: 'POST',
                    url: $form.data('profile-update-url'),
                    data: $form.serialize(),
                    success (html) {
                        that.$profileMessageTarget.html(html);
                    },
                    error () {
                        const $message = that.getErrorMessage(that.unknownErrorMessage);
                        that.$profileMessageTarget.html($message);
                    },
                });
            });
        },

        /**
         * Init the registration form functionality
         */
        initRegistration () {
            const that = this;
            let locked = false;

            /* eslint-disable */
            // TODO: Refactor for compliance with eslint rules
            this.$registrationForm.on('submit', function (e) {
                if (locked) {
                    return false;
                }

                locked = true;

                const $form = $(this);
                e.preventDefault();
                that.hideMessages();
                $.ajax({
                    type: 'POST',
                    url: $form.data('register-url'),
                    data: $form.serialize(),
                    success (html) {
                        locked = false;
                        that.$registrationMessageTarget.html(html);
                    },
                    error () {
                        const $message = that.getErrorMessage(that.unknownErrorMessage);
                        locked = false;
                        that.$registrationMessageTarget.html($message);
                    },
                });
            });
            /* eslint-enable */
        },

        /**
         * Update community profile
         */
        initRegistrationCommunity () {
            const that = this;
            let locked = false;
            /* eslint-disable */
            // TODO: Refactor for compliance with eslint rules
            $(document).on('submit', '#loginFormCommunity', function(e) {
                const $form = $(this);

                if (locked) {
                    return false;
                }

                locked = true;


                e.preventDefault();
                that.hideMessages();

                $.ajax({
                    type: 'POST',
                    url: $form.data('register-url'),
                    xhrFields: { withCredentials: true },
                    data: $form.serialize(),
                    success (html) {
                        that.$community.find(that.registrationCommunityMessageTarget).html(html);
                        locked = false;
                    },
                    error () {
                        const $message = that.getErrorMessage(that.unknownErrorMessage);
                        that.$community.find(that.registrationCommunityMessageTarget).html($message);
                        locked = false;
                    },
                });
            });
            /* eslint-enable */
        },

        /**
         * Init the password reset form functionality
         */
        initResetPassword () {
            const that = this;
            this.$forgotPasswordForm.on('submit', function (e) {
                const $form = $(this);
                e.preventDefault();
                that.hideMessages();
                $.ajax({
                    type: 'POST',
                    url: $form.data('reset-password-url') || that.settings.passwordResetUrl,
                    data: $form.serialize(),
                    success (html) {
                        that.$resetPasswordMessageTarget.html(html);
                    },
                    error () {
                        const $errorMsg = that.getErrorMessage(that.unknownErrorMessage);
                        that.$resetPasswordMessageTarget.html($errorMsg);
                    },
                });
            });
        },

        /**
         * Init the counters to count the remaining chars in comment text areas
         */
        initCharCounters () {
            this.$community.on('keyup', '.community__text-area', function () {
                const $textArea = $(this),
                    $counter = $textArea.closest('form').find('.word_count_span'),
                    maxChars = 3000,
                    currentChars = $textArea.val().length;

                if (currentChars <= maxChars) {
                    $counter.text(maxChars - currentChars);
                }
            });
        },

        /**
         * Show the login form, if the user is logged in. Otherwise show login message
         */
        initCommentRespond () {
            const that = this;

            this.$community.on('click', '.comment__answer-button', function (e) {
                const $button = $(this),
                    $comment = $button.closest('.comment'),
                    $allResponseForms = $('.comment__response-form', that.$community),
                    $responseForm = $comment.find('.comment__response-form'),
                    $reportForm = $comment.find('.community-credentialform--report');

                e.preventDefault();
                $allResponseForms.hide();
                $reportForm.hide();
                $responseForm.show();
            });
        },

        /**
         * Open the comment report form
         */
        initCommentReportOpen () {
            const that = this;

            this.$community.on('click', '.comment__report-link--open', function (e) {
                const $button = $(this),
                    $comment = $button.closest('.comment'),
                    $reportForm = $comment.find('.community-credentialform--report'),
                    $responseForm = $comment.find('.comment__response-form');

                e.preventDefault();
                $responseForm.hide();
                $reportForm.show();
            });
        },

        /**
         * Toggle answers when clicking the toggle link
         */
        initToggleAnswers () {
            this.$community.on('click', '.answers__toggle', function (e) {
                const $toggle = $(this),
                    $toggleText = $('.comment__answer-toggle-text', $toggle),
                    $answers = $toggle.closest('.comment').find('.community__comment-list--answer');
                e.preventDefault();
                $answers.stop().slideToggle('fast', () => {
                    $toggleText.toggleClass('open');
                });
            });
        },

        /**
         * Report/blame a comment
         */
        initReportComment () {
            this.$community.on('click', '.comment__report-link--send', function (e) {
                const $link = $(this),
                    url = $link.data('report-url'),
                    firstName = $link.closest('.comment').find('input[name=firstNameReport]').val(),
                    lastName = $link.closest('.comment').find('input[name=lastNameReport]').val(),
                    email = $link.closest('.comment').find('input[name=emailReport]').val(),
                    body = $link.closest('.comment').find('textarea[name=body]').val(),
                    captchaId = $link.closest('.comment').find('input[name=captchaId]').val(),
                    captcha = $link.closest('.comment').find('input[name=captcha]').val(),
                    buttonReport = $link.closest('.comment').find('.comment__report-link--open'),
                    messageReport = $link.closest('.comment').find('.community__registration-message--report'),
                    postType = $link.data('post-type'),
                    postId = $link.data('post-id'),
                    articleId = $link.data('article-id');
                e.preventDefault();
                $.ajax({
                    type: 'POST',
                    url,
                    data: {
                        postId,
                        articleId,
                        postType,
                        firstName,
                        lastName,
                        email,
                        body,
                        captchaId,
                        captcha,
                    },
                    success (html) {
                        if ($(html).text() === 'Gemeldet') {
                            $('.community-credentialform--report').hide();
                            buttonReport.hide();
                            buttonReport.after(html);
                        } else {
                            messageReport.html(html);
                        }
                    },
                });
            });
        },

        /**
         * Init read more / read less comment toggeling
         */
        initReadMore() {
            this.$community.on('click', '.comment__show-more, .comment__show-less', function (e) {
                e.preventDefault();
                $(this)
                    .closest('.comment__text')
                    .find('.comment__full-text, .comment__short-tex')
                    .toggle();
            });
        },

        /**
         * Request and show all comments for a given page number
         *
         * @param {number} pageNumber
         */
        loadPage (pageNumber) {
            const that = this,
                ajaxUrl = this.$commentRoot.data('comment-ajax-url');

            $.ajax({
                type: 'POST',
                url: ajaxUrl,
                data: {
                    page: pageNumber,
                },
                success (html) {
                    that.$commentRoot.html(html);
                },
            });
        },

    });

    // Auto initialize the community, if it is un-initialized
    $('[data-plugin-community]').each(function () {
        if (!$.data(this, 'community')) {
            $.data(this, 'community', new Community(this));
        }
    });
});
