

function PollSelect(rootNode) {
    const that = this,
        $answerList = $(rootNode).find('.poll__answer');

    jQuery(rootNode).on('click', () => {
        $answerList.each((idx, item) => {
            const $item = $(item);

            if ($item.find('.poll__answer__input').is(':checked')) {
                $item.addClass('selected');
            } else {
                $item.removeClass('selected');
            }
        });
    });
}

jQuery.fn.pollSelect = function() {
    return this.each(function() {
        new PollSelect(this);
    });
};
