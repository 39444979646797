/* Icons auf Teasern können geklicked werden und leiten dann auf die im data-url tag stehende URL weiter */

jQuery(() => {
    jQuery('.teaser__icons').find('.share-button__icon').on('click', function() {
        const teaserIconUrl = jQuery(this).attr('data-url') || jQuery(this).closest('article').find('.teaser__link').attr('href');

        if (teaserIconUrl) {
            window.location.href = teaserIconUrl;
        }
    });
});
