/* global jQuery, window, createCookie */

/**
 * Created by aladendorf on 25.07.2016.
 * wird durch Funktionsaufruf erst nach dem erzeugten DataLayer Object initialisiert
 */


function setAdBlockActive(enabled) {
    // window.console.log("adblock-detection: About to set adblockerCookie to", enabled);

    // variable is used for additional JS-Script in google-tagmanager see:
    // publications/multi/src/main/webapp/template/widgets/tracking/view/helpers/google-tagmanager.jsp
    window.adBlockActive = enabled;
}

/**
 * detects if ads have been blocked by the client
 */
jQuery(() => {
    const adTestElem = document.createElement('div');
    adTestElem.innerHTML = '&nbsp;';
    adTestElem.className = 'adsbox';
    document.body.appendChild(adTestElem);
    window.setTimeout(() => {
        if (adTestElem.offsetHeight === 0) {
            setAdBlockActive(true);
            // window.console.log("adblock-detection: AdBlocker enabled");
        } else {
            setAdBlockActive(false);
            // window.console.log("adblock-detection: NO AdBlocker");
        }
        adTestElem.remove();
    }, 100);
});
