/**
 * Created by Vincent on 11-Jan-18.
 */


function Redirect(rootNode, opts) {
    this.rootNode = rootNode;

    this.initRedirect(rootNode, opts);
}

Redirect.prototype.initRedirect = function(rootNode, opts) {
    function getUrlParam(name) {
        /* eslint-disable */
        // TODO: Refactor for compliance with eslint rules
        name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
        /* eslint-enable */

        const regexS = `[\\?&]${name}=([^&#]*)`,
            regex = new RegExp(regexS),
            results = regex.exec(window.location.href);

        if (results == null) {
            return '';
        }
        return results[1];
    }

    const referral = getUrlParam(opts.urlParam);

    if (referral !== '') {
        let url = decodeURIComponent(referral);
        if (opts.destParam !== '' && opts.destParam !== undefined) {
            if (url.indexOf('?') !== -1) {
                url = `${url}&${opts.destParam}`;
            } else {
                url = `${url}?${opts.destParam}`;
            }
        }

        $(rootNode).find('.redirect-link').attr('href', url);
        $(rootNode).show();

        $(rootNode).find('.redirect-close').on('click', () => {
            $(rootNode).fadeOut();
        });

        if (opts.fadeOutTime > 0) {
            setTimeout(() => {
                $(rootNode).fadeOut();
            }, opts.fadeOutTime);
        }
    }
};

jQuery.fn.redirect = function(opts) {
    return this.each(function() {
        new Redirect(this, opts);
    });
};
