// Menu flyout plugin
(function ($) {
    function MenuFlyout(rootElement, options) {
        this.settings = $.extend({
            lazyOffset: 300, // delay in ms
            enableCaching: true,
        }, options);
        this.$menu = $(rootElement);
        this.isDesktop = jQuery.utils.isDesktopByWidth();
        this.visibilityClass = 'nav-main__flyout--visible';
        this.cache = {};
        this.init();
    }

    $.extend(MenuFlyout.prototype, {
        init() {
            // Only initialize on desktop devices
            if (this.isDesktop) {
                this.initToggling();
                this.initLoadContent();
            }
        },
        initToggling() {
            const that = this,
                $flyouts = that.$menu.find('.has-submenu.nav-main__item--level1');

            $flyouts.on('mouseenter mouseleave', function () {
                that.toggleHandler(this);
            });

            $.getEventManager().on('close-overlays', () => {
                $flyouts.removeClass(that.visibilityClass);
            }, that);
        },
        initLoadContent() {
            const that = this;
            that.$menu.find('[data-ajax-url]').on('mouseenter', function (e) {
                if ($(this).closest('.nav-main__flyout').length > 0) {
                    e.stopPropagation();
                }
                that.loadContentHandler(this);
            });
        },
        toggleHandler(item) {
            const $item = $(item),
                that = this;

            let flyout;

            window.setTimeout(() => {
                flyout = $item.find('.nav-main__flyout');
                if ($item.is(':hover')) {
                    $.getEventManager().fire('close-overlays', null, that);
                    flyout.addClass(that.visibilityClass);
                } else {
                    flyout.removeClass(that.visibilityClass);
                }
            }, that.settings.lazyOffset);
        },
        loadContentHandler(item) {
            const that = this,
                $item = $(item),
                url = $item.data('ajax-url'),
                $target = $item.closest('.nav-main__item--level1').find('.nav-main__teaser-container');

            if (that.settings.enableCaching && Object.prototype.hasOwnProperty.call(that.cache, url)) {
                $target.html(that.cache[url]);
                $.getEventManager().fire('picturefill');
            } else {
                jQuery.ajax({
                    url,
                    type: 'GET',
                    success(result) {
                        that.cache[url] = result;
                        $target.html(that.cache[url]);
                        $.getEventManager().fire('picturefill');
                    },
                });
            }
        },
    });

    $.fn.menuFlyout = function (options) {
        return this.each(function () {
            if (!$.data(this, 'menuFlyout')) {
                $.data(this, 'menuFlyout', new MenuFlyout(this, options));
            }
        });
    };
}(jQuery));
