/* globals ga */

/*
    Implementation of Google Event Tracking
    Author: Peter Marhewka
*/


const log = '0';


function GoogleEventTracking(node) {
    const that = this;
    setTimeout(() => {
        that.GoogleEventTrackingBind();
        console.log('Google Tracking');
    }, 1000);
}


GoogleEventTracking.prototype.GoogleEventTrackingBind = function(rootNode) {
    console.log('Google Tracking2');
    const gProtocol = jQuery(location).attr('protocol'),
        gHostname = jQuery(location).attr('hostname'),
        gPathname = jQuery(location).attr('pathname'),
        gSearch = jQuery(location).attr('search'),
        pageUrl = `${gProtocol}//${gHostname}${gPathname}${gSearch}`,
        that = this;

    // jQuery("a").on("click", function() {
    // var thisUrl = jQuery(this).attr("href");
    // ga("send", "event", "Seite: " + pageUrl, "click", thisUrl);
    // console.log("Ereigniskategorie: " + pageUrl + " Ereignislabel: " + thisUrl);
    // });

    jQuery('.sharrre').on('click', function() {
        const thisShare = jQuery(this).attr('title');
        ga('send', 'event', `Share: ${thisShare}`, 'share', pageUrl);
        // console.log("Ereigniskategorie: " + pageUrl + " Ereignislabel: " + thisShare);
    });

    // Popup Header Anmelden Button + Buttons in Popup Lightbox

    jQuery('.header-top-abo').on('click', () => {
        ga('send', 'event', 'Login', 'click', `Header - Anmelden Button: ${pageUrl}`);
        // alert("send, event, Login, click, Header - Anmelden Button: " + pageUrl);
        setTimeout(() => {
            that.GoogleEventTrackingHeaderLoginTrack();
        }, 1000);
    });

    // Popup Paywall Buttons Artikel in Popup Lightbox

    jQuery('.remodal').find('.lightbox').find('.article-block__voucher__signin').find('.article-block__voucher__button')
        .on('click', () => {
            ga('send', 'event', 'Login Paywall', 'click', `Popup - Anmelden: ${pageUrl}`);
            // alert("send, event, Login Paywall, click, Popup - Anmelden: " + pageUrl);
        });

    jQuery('.remodal').find('.lightbox').find('.abo-login__form').find('.abo-login__form__submit')
        .on('click', () => {
            ga('send', 'event', 'Login Paywall', 'click', `Popup - Anmelden Submit: ${pageUrl}`);
            // alert("send, event, Login Paywall, click, Popup - Anmelden Submit: " + pageUrl);
        });

    jQuery('.remodal').find('.lightbox').find('.article-block__voucher__column--button').find('.article-block__voucher__button')
        .on('click', () => {
            ga('send', 'event', 'Login Paywall', 'click', `Popup - Registrieren: ${pageUrl}`);
            // alert("send, event, Login Paywall, click, Popup - Registrieren: " + pageUrl);
        });

    // Paywall Buttons Artikel in Body

    jQuery('.article-abo').find('.article-block__voucher__signin').find('.article-block__voucher__button').on('click', () => {
        ga('send', 'event', 'Login Paywall', 'click', `Artikel Body - Anmelden: ${pageUrl}`);
        // alert("send, event, Login Paywall, click, Artikel Body - Anmelden: " + pageUrl);
    });

    jQuery('.article-abo').find('.abo-login__form').find('.abo-login__form__submit').on('click', () => {
        ga('send', 'event', 'Login Paywall', 'click', `Artikel Body - Anmelden Submit: ${pageUrl}`);
        // alert("send, event, Login Paywall, click, Artikel Body - Anmelden Submit: " + pageUrl);
    });

    jQuery('.article-abo').find('.article-block__voucher__column--button').find('.article-block__voucher__button').on('click', () => {
        ga('send', 'event', 'Login Paywall', 'click', `Artikel Body - Registrieren: ${pageUrl}`);
        // alert("send, event, Login Paywall, click, Artikel Body - Registrieren: " + pageUrl);
    });

    // Teaser Container Links
    jQuery('.sidebar').find('.content--teaser--container').find('.collapsable__content').find('.teaser')
        .find('a')
        .on('click', function() {
            let teaserText = jQuery(this).closest('.collapsable__content').prev().find('.block-header__icon')
                .text();
            const thisUrl = jQuery(this).attr('href');

            if (teaserText === '' && jQuery(this).closest('.content--teaser--container').prev().attr('class') === 'police-search-teaser') {
                teaserText = jQuery('.police-search-teaser').find('.block-header__icon').text();
            // alert(teaserText);
            }

            ga('send', 'event', `Teaser Sidebar: ${teaserText}`, 'click', thisUrl);
        // alert("teaserText: " + teaserText);
        });

    jQuery('.content').find('.content--teaser--container').find('a').on('click', function() {
        // alert(jQuery(this).closest(".content").prev().find(".block-header__icon").text() === "Sport");
        const thisUrl = jQuery(this).attr('href');

        // Sport

        if (jQuery(this).closest('.content').prev().find('.block-header__icon')
            .text() === 'Sport') {
            ga('send', 'event', 'Teaser Sport', 'click', thisUrl);
            // alert("Teaser Sport: " + thisUrl);
        }
    });

    jQuery('.content-wide').find('.content--teaser--container').find('.collapsable__content').find('.teaser')
        .find('a')
        .on('click', function() {
            const teaserText = jQuery(this).closest('.collapsable__content').prev().find('.block-header__icon')
                .text();
            const thisUrl = jQuery(this).attr('href');
            ga('send', 'event', `Teaser Content-Wide: ${teaserText}`, 'click', thisUrl);
        // alert("Teaser Content-Wide: " + teaserText);
        });

    jQuery('.content-double').find('.content--teaser--container').find('.collapsable__content').find('.teaser')
        .find('a')
        .on('click', function() {
            const teaserText = jQuery(this).closest('.collapsable__content').prev().find('.block-header__icon')
                .text();
            const thisUrl = jQuery(this).attr('href');
            ga('send', 'event', `Teaser Content-Double: ${teaserText}`, 'click', thisUrl);
        // alert("Teaser Content-Double: " + teaserText);
        });

    jQuery('.content-triple').find('.content--teaser--container').find('.collapsable__content').find('.teaser')
        .find('a')
        .on('click', function() {
            const teaserText = jQuery(this).closest('.collapsable__content').prev().find('.block-header__icon')
                .text();
            const thisUrl = jQuery(this).attr('href');
            ga('send', 'event', `Teaser Content-Triple: ${teaserText}`, 'click', thisUrl);
        // alert("Teaser Content-Triple: " + teaserText);
        });

    jQuery('.content-quad').find('.content--teaser--container').find('.collapsable__content').find('.teaser')
        .find('a')
        .on('click', function() {
            const teaserText = jQuery(this).closest('.collapsable__content').prev().find('.block-header__icon')
                .text();
            const thisUrl = jQuery(this).attr('href');
            ga('send', 'event', `Teaser Content-Quad: ${teaserText}`, 'click', thisUrl);
        // alert("Teaser Content-Quad: " + teaserText);
        });

    // Navigation Links

    jQuery('.nav-main--major').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Navigation Major: ', 'click', thisUrl);
        // alert("Teaser Content-Quad: " + teaserText);
    });

    jQuery('.nav-main--services').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Navigation Services', 'click', thisUrl);
        // alert("Teaser Content-Quad: " + teaserText);
    });

    jQuery('.nav-innews').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Navigation in News', 'click', thisUrl);
        // alert("Teaser Content-Quad: " + teaserText);
    });

    jQuery('.nav-meta__list').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Navigation Metalist', 'click', thisUrl);
        // alert("Teaser Content-Quad: " + teaserText);
    });

    jQuery('.header-top-logo').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Logo', 'click', thisUrl);
        // alert("Teaser Content-Quad: " + teaserText);
    });

    jQuery('.footer').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Footer', 'click', thisUrl);
        // alert("Teaser Content-Quad: " + teaserText);
    });

    jQuery('.content-wide').find('.teaser--hero').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Content-Teaser Aufmacher', 'click', thisUrl);
        // alert("Teaser Content-Quad: " + teaserText);
    });

    // Video und Bilder Galerie Startseite

    jQuery('.content-triple').find('.teaser-media').find('a').on('click', function() {
        const teaserText = jQuery(this).closest('.collapsable__content').prev().find('.block-header__icon')
                .text(),
            thisUrl = jQuery(this).attr('href');
        ga('send', 'event', `Content-Teaser - ${teaserText}`, 'click', thisUrl);
        // alert("send, event Content-Teaser - " + teaserText + ", click, " + thisUrl);
    });

    // Wetter

    jQuery('.weather__shortinfo').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Sidebar: Wetter', 'click', thisUrl);
        // alert("send, event Content-Teaser - " + teaserText + ", click, " + thisUrl);
    });

    // Flugrouten Radar Suche

    jQuery('.flugroutenradar__body').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Sidebar: Flugrouten Radar', 'click', thisUrl);
        // alert("Sidebar: Flugrouten Radar" + thisUrl);
    });

    // Whatsapp Channel

    jQuery('.content--teaser--container').find('.whatsapp').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Sidebar: Whatsapp', 'click', thisUrl);
        // alert("Sidebar: Whatsapp" + thisUrl);
    });

    // Sport Vereine Bar

    jQuery('.sport-vereine-bar').find('a').on('click', function() {
        const thisUrl = jQuery(this).attr('href');
        ga('send', 'event', 'Sportvereine', 'click', thisUrl);
        // alert("Sportvereine" + thisUrl);
    });
};

GoogleEventTracking.prototype.GoogleEventTrackingHeaderLoginTrack = function(rootNode) {
    // alert("GoogleEventTrackingHeaderLoginTrack");
    const gProtocol = jQuery(location).attr('protocol'),
        gHostname = jQuery(location).attr('hostname'),
        gPathname = jQuery(location).attr('pathname'),
        gSearch = jQuery(location).attr('search'),
        pageUrl = `${gProtocol}//${gHostname}${gPathname}${gSearch}`;

    jQuery('.remodal').find('.abo-login--overlay').find('.abo-login__form').find('.abo-login__form__submit')
        .on('click', () => {
            ga('send', 'event', 'Login', 'click', `Popup - Anmelden: ${pageUrl}`);
            // alert("send, event, Login, click, Popup - Anmelden Submit: " + pageUrl);
        });

    jQuery('.remodal').find('.abo-login--overlay').find('.abo-login__register').find('.article-block__voucher__button')
        .on('click', () => {
            ga('send', 'event', 'Login', 'click', `Popup - Registrieren: ${pageUrl}`);
            // alert("send, event, Login, click, Popup - Registrieren: " + pageUrl);
        });
};


jQuery.fn.googleEventTracking = function() {
    return this.each(function() {
        new GoogleEventTracking(this);
    });
};
