/* global Cookies, window, EventManager */
/* Common functionalities, like the usage of plugins
Author: Matthias Klebe
 */


// custom event manager instance to be used via jQuery $.getEventManager()
(function ($) {
    const eventManager = new EventManager();

    $.extend({
        getEventManager() {
            return eventManager;
        },
    });
}(jQuery));

$(document).on('opened', '.remodal', () => {
    $('.remodal .carousel').trigger('resize');
});

/**
 * Some uncommented code has been added here, not sure what exactly it does. However, it has something to do with
 * AppNexus/Springer ads and with determining if the .show-on-halfpage-ad elements are supposed to be shown or not.
 */
window.sas_loadHandler = function (f) {
    console.log(`sas_loadHandler: ${f.id} = ${f.hasAd}`);

    if (f.hasAd) {
        $(`#sas_${f.id}`).closest('.ad--asmi').show();

        if ((window.parseInt(f.id) === 4458 || window.parseInt(f.id) === 4459) && ($(`#sas_${f.id}`).height() > 350)) {
            $('.hide-on-halfpage-ad').hide();
            $('.show-on-halfpage-ad').show();
        } else if ((window.parseInt(f.id) === 4458 || window.parseInt(f.id) === 4459) && ($(`#sas_${f.id}`).height() <= 350)) {
            $('.hide-on-halfpage-ad').show();
            $('.show-on-halfpage-ad').hide();
        }
    } else if (!f.hasAd) {
        $(`#sas_${f.id}`).closest('.ad--asmi').css({
            height: 0,
            overflow: 'hidden',
        }).show();

        if (window.parseInt(f.id) === 4458 || window.parseInt(f.id) === 4459) {
            $('.hide-on-halfpage-ad').show();
        }
    }

    if (window.asmi_ads === undefined) {
        window.asmi_ads = {};
    }
    window.asmi_ads[f.id] = f.hasAd;

    $(window).trigger(`asmi-returned-${f.id}`);
};

// Reevaluate all picture-elements on the page with picturefill. Picturefill skips already processed
// elements.
$(document).ajaxComplete(() => {
    if (typeof window.picturefill === 'function') {
        window.picturefill();
    }
});

window.openLayer = function (options, successCallback) {
    let $remodalInstance = null;

    const opts = $.extend({
        // "closeOnClick": true,    // TODO
        data: null,
        method: 'GET',
        hideCloseButton: false,
        showMobileInline: false,
        url: '',
    }, options);

    if (window.layerInterval) {
        clearInterval(window.layerInterval);
    }

    function performAjax() {
        $.ajax({
            data: opts.data,
            type: opts.method,
            url: opts.url,
        })
            .done((resp) => {
                let anchorPos,
                    $container,
                    $content;

                const showInline = opts.showMobileInline && !jQuery.utils.isDesktopByWidth();

                if (showInline) {
                    $container = $('#inline-overlay');
                } else {
                    $container = $('[data-remodal-id=modal]');
                    $remodalInstance = $remodalInstance || $container.remodal();
                }

                const $closeButton = $container.find('.remodal-close');
                $content = $container.find('.remodal-content');

                if ($content.length < 1) {
                    $content = $('<div>').addClass('remodal-content');
                    $container.append($content);
                    $container.append('<div data-remodal-action="close" class="remodal-close"></div>');
                }

                $content.html(resp);

                if (showInline) {
                    // if open, close mobile menu
                    const $menuButton = $('.nav-main-toggle');
                    if ($menuButton.hasClass('nav-main-toggle--closed')) {
                        $menuButton.trigger('click');
                    }
                } else {
                    $remodalInstance.open();
                }

                if (opts.hideCloseButton || showInline) {
                    $closeButton.addClass('hidden');
                } else {
                    $closeButton.removeClass('hidden');
                }

                if (typeof successCallback === 'function') {
                    successCallback($container);
                }
            }).fail(() => {
                console.log('Error while opening layer!');
            });
    }

    window.layerInterval = setInterval(() => {
        if (document.readyState === 'complete') {
            clearInterval(window.layerInterval);
            performAjax();
        }
    }, 200);
};

// Close handler for LOGIN light box
window.closeLayer = function () {
    // lightbox / inline
    $('[data-remodal-id=modal]').find('.remodal-content').html('');

    const anchorPos = location.href.indexOf('#modal');
    if (anchorPos > 0) {
        location.href = location.href.substr(0, anchorPos);
    }

    // Inline:
    $('#inline-overlay').find('.remodal-content').html('');
};

// Close handler for NORMAL light box FUNE-2191
// Documentation: https://github.com/VodkaBears/remodal#events
$(document).on('closed', '[data-remodal-id=modal]', () => {
    $('[data-remodal-id=modal]').find('.remodal-content').html('');
});

$(document).on('click', '.collapsable .block-header', function (e) {
    if ($.utils.isMobileByWidth() || $(this).closest('.collapsable').hasClass('collapsable--desktop')) {
        e.preventDefault();
        $(this).closest('.collapsable').toggleClass('open');
    }
});

$(document).on('click', '.mediagalery__filters .active a', function (e) {
    e.preventDefault();
    $(this).closest('.mediagalery__filters__list').toggleClass('open');
});

$(document).on('click', '.tab-navigation .active a', function (e) {
    if ($.utils.isMobileByWidth()) {
        e.preventDefault();
        $(this).closest('.tab-navigation__list').toggleClass('open');
    }
});

$.getEventManager().on('picturefill', () => {
    window.picturefill();
});

// Load the content for the lightbox asynchronous.
$(document).on('click', '[data-remodal-target=modal]', (e) => {
    e.preventDefault();

    const $target = $(e.target),
        url = $target.data('ajax-url'),
        method = $target.data('ajax-method') || 'GET';

    window.openLayer({ method, url });
});

// Open video in lightbox when clicking on special links.
$(document).on('click', "[data-open-target='lightbox']", function (e) {
    if (typeof window.videoLightbox === 'function') {
        e.preventDefault();

        const $t = $(this),
            mediaId = $t.data('video-article-id') || $t.data('gallery-article-id'),
            shareUrl = $t.data('share-url') || $t.attr('href') || '';

        if ($t.data('video-article-id')) {
            window.videoLightbox(mediaId, shareUrl);
        } else if ($t.data('gallery-article-id')) {
            window.galleryLightbox(mediaId);
        }
    }
});

const animateHorizontal = function (index, value) {
    const $t = $(value);
    $t.animate({ left: parseInt($t.css('left'), 10) === 0 ? '100%' : 0 });
};

/** @function
 *    slide Toggle for specified elements
 *    @param {string} data.slider.target.id
 *    @param {string} data.direction
 *
 */
$(document).on('click', '[data-slider]', function (e) {
    e.preventDefault();
    const $t = $(this),
        targets = $t.data('slider-target-id').split(','),
        toggleCallback = function () {
            $t.toggleClass('open');
        };
    let $target;
    for (let i = 0; i < targets.length; i++) {
        $target = $(`[data-slider-id=${targets[i]}]`);
        if ($t.data('slide-direction') === 'horizontal') {
            $.each($target, animateHorizontal);
        } else {
            $target.slideToggle(toggleCallback);
        }
    }
});

/* Toggle single accordeon. */
$(document).on('click', '[data-toggle-self]', function (e) {
    const $pane = $(this).closest('[data-toggle-self-pane]');
    e.preventDefault();

    if (typeof $pane.attr('data-toggle-is-overlay') === 'string' && !$pane.hasClass('open')) {
        $.getEventManager().fire('close-overlays', null, this);
    }

    $pane.toggleClass('open');
});

$(() => {
    $('[data-toggle-is-overlay]').each(function () {
        const $pane = $(this);
        $.getEventManager().on('close-overlays', () => {
            $pane.removeClass('open');
        }, this);
    });
});

// Facade for the Create-Cookie function
window.createCookie = function (name, value, days) {
    Cookies.set(name, value, { expires: days, path: '/' });

    /* If Same Site Cookie will become a problem in the Future use this variant for cross Domain cookie handling
    Add option samesite=true for createCookie function!
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires="+d.toUTCString();
    document.cookie =  name + "=" + value +  ";" + "expires=" + expires + ";" + "path=/; secure;" + "samesite=none";
     */
};


window.readCookieJson = function (name) {
    return Cookies.getJSON(name);
};

window.readCookie = function (name) {
    return Cookies.get(name);
};

window.eraseCookie = function (name) {
    Cookies.remove(name);
};

// Reload page without reopening the lightbox:
window.reloadPage = function () {
    const anchorPos = location.href.indexOf('#');

    if (anchorPos > 0) {
        location.href = location.href.substr(0, anchorPos);
    } else {
        location.reload();
    }
};

/**
 * FDC-2973
 * If the available AppNexus ad is higher than 300px, the .show-on-halfpage-ad element needs to be shown.
 * Reference: https://github.com/spring-media/adsolutions-implementationReference/blob/master/publisher-loadHandler-reference.md
 * @param {String} targetId
 */
window.handleHalfpageAdAppnexus = (targetId) => {
    window.addEventListener('adInfo', (adSlot) => {
        if (adSlot.detail.id === targetId) {
            try {
                const heightByPlacementSize = adSlot.detail.placementSize.split('x')[1];
                const heightByCrea1 = adSlot.detail.crea1.height;
                if (heightByPlacementSize > 300 || heightByCrea1 > 300) {
                    $('.show-on-halfpage-ad').show();
                    $('.hide-on-halfpage-ad').hide();
                } else {
                    $('.show-on-halfpage-ad').hide();
                    $('.hide-on-halfpage-ad').show();
                }
            } catch (e) {
                console.log('FDP Error - handleHalfpageAdAppnexus', e);
            }
        }
    });
};
