/**
 * Functions to load the longtail ad HTML partial from an url via XHR/AJAX and append it to .ad--longtail.
 * Works together with publications/<PUB>/src/main/webapp/template/widgets/ad/view/longtail.jsp.
 *
 * @author Gregor Sondermeier
 */

/**
 * appends a HTML snippet to the .ad--marker-inner of a given element ID
 * @param {HTMLElement} elem - the element to append the HTML snippet to
 * @param {string} html - the HTML that should be appended
 */
function appendLongtailAdHtmlSnippet(elem, html) {
    const $adElem = jQuery(elem);
    $adElem.find('.ad--marker-inner').append(html);
}

/**
 * use vanilla JS to make the cross-origin XHR/ajax request, because making it with jQuery.ajax() doesn't work out of
 * the box.
 * https://www.html5rocks.com/en/tutorials/cors/#toc-making-a-cors-request
 *
 * @param {HTMLElement} elem - the element to append the HTML snippet to
 * @param {string} url - the URL to make the request to
 */
function loadLongtailAdHtmlSnippet(elem, url) {
    const method = 'GET';
    let xhr = new XMLHttpRequest();

    if ('withCredentials' in xhr) {
        // Check if the XMLHttpRequest object has a "withCredentials" property.
        // "withCredentials" only exists on XMLHTTPRequest2 objects.
        xhr.open(method, url, true);
    } else if (typeof XDomainRequest !== 'undefined') {
        // Otherwise, check if XDomainRequest.
        // XDomainRequest only exists in IE, and is IE's way of making CORS requests.
        xhr = new XDomainRequest();
        xhr.open(method, url);
    } else {
        // Otherwise, CORS is not supported by the browser.
        xhr = null;
    }

    if (!xhr) {
        throw new Error('ad-longtail: CORS not supported');
    } else {
        xhr.onload = () => {
            appendLongtailAdHtmlSnippet(elem, xhr.responseText);
        };
        xhr.onerror = () => {
            throw new Error('ad-longtail: error performing XHR request');
        };
        xhr.send();
    }
}

/**
 * Initialize longtail ad
 */
function initLongtailAdHtmlSnippet() {
    Array.prototype.slice.call(document.getElementsByClassName('ad--longtail')).forEach((elem) => {
        loadLongtailAdHtmlSnippet(elem, elem.dataset.longtailUrl);
    });
}

/**
 * Wait for consent before initialization function is triggered
 */
jQuery(() => {
    const longtailVendorId = 1016;

    jQuery.fn.cmpHelper.onConsented(longtailVendorId, () => {
        initLongtailAdHtmlSnippet();
    });
});
