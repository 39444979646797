/* eslint-disable */
function getUrlParameter(sParam) {
    const sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&');

    let sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}
/* eslint-enable */

function getDivider() {
    return 'h5';
}

function stripHtml(s) {
    return $($.parseHTML(s)).text();
}

// Function to look for page dividers (<h5></h5>) and wrap pages into divs
function wrapPages() {
    const divider = getDivider(),
        pages = $('.article__body').find(divider).length;

    if (pages === 0) {
        // Hide Paging if no divider
        $('.pager').css({
            display: 'none',
        });
    } else {
        // wrap from article beginning to first h5
        $('.article__body').children().first().nextUntil(divider)
            .addBack()
            .wrapAll("<div class='page-container page-0 page-active'></div>");

        $(divider).each(function (index) {
            const pageNumber = index + 1;

            if (pageNumber !== pages) {
                // wrap from h5 to h5
                $(this).nextUntil(divider).wrapAll(`<div class='page-container page-${pageNumber}'></div>`);
            } else {
                // wrap last element from h5 to pager
                $(this).nextUntil('.pager').wrapAll(`<div class='page-container page-${pageNumber}'></div>`);
            }
        });

        if ($('.pager.pager__top').length) {
            $('.article__body').prepend($('.pager.pager__top'));
            $('.pager.pager__top').first().addClass('moved');
        }

        $('.pager.pager__top').not('.moved').remove();

        if (getUrlParameter('displayDropdownTop')) {
            $('#pager__dropdown__top').css('display', getUrlParameter('displayDropdownTop'));
        }

        if (getUrlParameter('displayDropdownBottom')) {
            $('#pager__dropdown__bottom').css('display', getUrlParameter('displayDropdownBottom'));
        }
    }
}

// Setup the Pager under the article body
function setupPager(pageHeadlines) {
    const divider = getDivider(),
        articleBody = $('.article__body'),
        pageCount = articleBody.find(divider).length + 1;

    let i,
        pageLinks = articleBody.find('.pager .page-links__top .link-dots-before');

    if (!pageLinks.length) {
        pageLinks = articleBody.find('.pager .page-links .link-dots-before');
    }

    if (pageLinks.length) {
        for (i = 1; i <= pageCount; i++) {
            if (pageHeadlines.length === pageCount) {
                if (i === 1) {
                    linkString = `<a class='pageLink-${i - 1} page-links__item' href='${window.location.href.split('?')[0]}?displayDropdownTop=block&displayDropdownBottom=none'><strong>Seite ${i}:</strong> ${stripHtml(pageHeadlines[i - 1])}</a>`;
                } else {
                    linkString = `<a class='pageLink-${i - 1} page-links__item' href='${window.location.href.split('?')[0]}?seite=${i}&displayDropdownTop=block&displayDropdownBottom=none'><strong>Seite ${i}:</strong> ${stripHtml(pageHeadlines[i - 1])}</a>`;
                }
            } else {
                linkString = `<a class='pageLink-${i - 1} page-links__item' href='${window.location.href.split('?')[0]}?seite=${i}'>Seite ${i}</a>`;
            }
            pageLinks.before(linkString);
        }
    }

    // Bottom
    pageLinks = articleBody.find('.pager .page-links__bottom .link-dots-before');

    if (pageLinks.length) {
        for (i = 1; i <= pageCount; i++) {
            linkString = '';
            if (pageHeadlines.length === pageCount) {
                if (i === 1) {
                    linkString = `<a class='pageLink-${i - 1} page-links__item' href='${window.location.href.split('?')[0]}?displayDropdownTop=none&displayDropdownBottom=block'><strong>Seite ${i}:</strong> ${stripHtml(pageHeadlines[i - 1])}</a>`;
                } else {
                    linkString = `<a class='pageLink-${i - 1} page-links__item' href='${window.location.href.split('?')[0]}?seite=${i}&displayDropdownTop=none&displayDropdownBottom=block'><strong>Seite ${i}:</strong> ${stripHtml(pageHeadlines[i - 1])}</a>`;
                }
            } else {
                linkString = `<a class='pageLink-${i - 1} page-links__item' href='${window.location.href.split('?')[0]}?seite=${i}'>Seite ${i}</a>`;
            }
            pageLinks.before(linkString);
        }
    }

    if (pageCount > 3) {
        for (i = 4; i <= pageCount; i++) {
            const element = $(`.pager .page-links a:nth-child(${i})`);
            element.addClass('hidden-link');
        }
        articleBody.find('.pager .link-dots-after').removeClass('dots-hidden');
    }

    articleBody.find('.pager a').first().addClass('active');
}

// Update Pager and dots
function updatePager(articleBody, pageNumber, nextPageNumber, pageCount) {
    const linkString = `.pageLink-${pageNumber}`;

    articleBody.find('.pager a').removeClass('active');
    articleBody.find('.page-active').removeClass('page-active');
    articleBody.find(linkString).addClass('active');

    $('.pager__button--prev').toggleClass('pager__button--hidden', pageNumber <= 0);
    $('.pager__button--next').toggleClass('pager__button--hidden', nextPageNumber > pageCount);

    if (pageCount > 3) {
        const nextPage = parseInt(pageNumber, 10) + 2,
            prevPage = parseInt(pageNumber, 10) - 2,
            nextElement = $(`.pager .page-links a:nth-child(${nextPage})`),
            prevElement = $(`.pager .page-links a:nth-child(${pageNumber})`),
            firstElement = $(`.pager .page-links a:nth-child(${1})`),
            lastElement = $(`.pager .page-links a:nth-child(${pageCount})`);

        if (nextElement.length !== 0 && nextElement.hasClass('hidden-link')) {
            const firstVisible = parseInt(pageNumber, 10) - 1,
                firstVisibleElement = $(`.pager .page-links a:nth-child(${firstVisible})`);

            nextElement.removeClass('hidden-link');
            firstVisibleElement.addClass('hidden-link');
        }

        if (prevElement.length !== 0 && prevElement.hasClass('hidden-link')) {
            const lastVisible = parseInt(pageNumber, 10) + 3,
                lastVisibleElement = $(`.pager .page-links a:nth-child(${lastVisible})`);

            prevElement.removeClass('hidden-link');
            lastVisibleElement.addClass('hidden-link');
        }

        if (firstElement.hasClass('hidden-link')) {
            articleBody.find('.pager .link-dots-before').removeClass('dots-hidden');
        } else {
            articleBody.find('.pager .link-dots-before').addClass('dots-hidden');
        }
        if (lastElement.hasClass('hidden-link')) {
            articleBody.find('.pager .link-dots-after').removeClass('dots-hidden');
        } else {
            articleBody.find('.pager .link-dots-after').addClass('dots-hidden');
        }
    }
}

function getPageNumber(page, classIndex) {
    const pageNumber = page.attr('class').split(/\s+/)[classIndex],
        separatorIndex = pageNumber.indexOf('-') + 1;

    return pageNumber.substring(separatorIndex);
}

function goToPage(pageNumber, pageHeadlines) {
    const articleBody = $('.article__body'),
        newActivePage = articleBody.find(`.page-${pageNumber}`),
        pageCount = articleBody.find('.page-container').length;

    let url = window.location.href;
    const protocol = url.split('/');

    url = `${protocol[0]}//${window.location.hostname}${window.location.pathname}`;

    const nextPageNumber = parseInt(pageNumber, 10) + 2,
        prevPageNumber = pageNumber,
        pageNumberDisplay = parseInt(pageNumber, 10) + 1;

    if (newActivePage.length !== 0) {
        if (pageNumber === 0) {
            $('figure.hero-img').css('display', 'block');
        }

        if (pageNumber > 0) {
            $('figure.hero-img').css('display', 'none');
        }

        const bodyTop = articleBody.offset().top,
            navHeight = $('.nav-main').outerHeight(),
            stickyArticleHeight = $('.article__header__multi__container').outerHeight(),
            calculatedTop = bodyTop - navHeight - stickyArticleHeight;

        $('.article__header__headline').html(pageHeadlines[pageNumber]);
        document.title = stripHtml(pageHeadlines[pageNumber]);

        updatePager(articleBody, pageNumber, nextPageNumber, pageCount);

        if (pageCount - 1 === pageNumber) {
            // link rel letzte Seite
            $("link[rel='canonical']").attr('href', `${url}?seite=${pageNumberDisplay}`);
            if (!$("link[rel='prev']").length) {
                $(`<link rel='prev' href='${url}?seite=${prevPageNumber}' />'`).insertAfter("link[rel='canonical']");
            } else {
                $("link[rel='prev']").attr('href', `${url}?seite=${prevPageNumber}`);
            }
        } else if (pageNumber === 0) {
            // link rel erste Seite
            $("link[rel='canonical']").attr('href', url);
            if (!$("link[rel='next']").length) {
                $(`<link rel='next' href='${url}?seite=2' />'`).insertAfter("link[rel='canonical']");
            } else {
                $("link[rel='next']").attr('href', `${url}?seite=2`);
            }
        } else {
            // link rel nicht erste oder letzte Seite
            $("link[rel='canonical']").attr('href', `${url}?seite=${pageNumberDisplay}`);

            if (!$("link[rel='next']").length) {
                $(`<link rel='next' href='${url}?seite=${nextPageNumber}' />'`).insertAfter("link[rel='canonical']");
            } else {
                $("link[rel='next']").attr('href', `${url}?seite=${nextPageNumber}`);
            }
            if (!$("link[rel='prev']").length) {
                if (prevPageNumber === 1) {
                    $(`<link rel='prev' href='${url}' />'`).insertAfter("link[rel='canonical']");
                } else {
                    $(`<link rel='prev' href='${url}?seite=${prevPageNumber}' />'`).insertAfter("link[rel='canonical']");
                }
            } else if (prevPageNumber === 1) {
                $("link[rel='prev']").attr('href', url);
            } else {
                $("link[rel='prev']").attr('href', `${url}?seite=${prevPageNumber}`);
            }
        }

        newActivePage.addClass('page-active');
    }
}

function gotoNextPage() {
    const activePage = $('.article__body').find('.page-active'),
        pageNumber = getPageNumber(activePage, 1),
        newPageNumber = parseInt(pageNumber, 10) + 2;
    window.location.href = `${window.location.href.split('?')[0]}?seite=${newPageNumber}&displayDropdownTop=${$('#pager__dropdown__top').css('display')}&displayDropdownBottom=${$('#pager__dropdown__bottom').css('display')}`;
}

function gotoPrevPage() {
    const activePage = $('.article__body').find('.page-active'),
        pageNumber = getPageNumber(activePage, 1),
        newPageNumber = parseInt(pageNumber, 10);
    window.location.href = `${window.location.href.split('?')[0]}?seite=${newPageNumber}&displayDropdownTop=${$('#pager__dropdown__top').css('display')}&displayDropdownBottom=${$('#pager__dropdown__bottom').css('display')}`;
}

function getArticleHeadline() {
    const $articleHeadline = $('.article__header__headline');

    if ($articleHeadline.text().length > 0) {
        return $articleHeadline.html();
    }

    return $('#pageMainHeadline').html();
}

function initPaging() {
    const divider = getDivider(),
        articleBody = $('.article__body'),
        pageHeadlines = [];

    pageHeadlines.push(getArticleHeadline());

    const pageHeadlinesObj = articleBody.find(divider);

    $.each(pageHeadlinesObj, (key, value) => {
        pageHeadlines.push(value.innerHTML);
    });

    wrapPages();
    setupPager(pageHeadlines);

    let page = 1;

    if (getUrlParameter('seite')) {
        page = getUrlParameter('seite');
    }

    page = parseInt(page) - 1;

    if (page !== undefined && page !== '') {
        goToPage(page, pageHeadlines);
    }

    $('#nextPage__top, #nextPage__bottom').on('click', () => {
        gotoNextPage();
    });

    $('#prevPage__top, #prevPage__bottom').on('click', () => {
        gotoPrevPage();
    });

    $('#paging__dropdown__click__top').on('click', () => {
        $('#pager__dropdown__top').toggle();
    });

    $('#paging__dropdown__click__bottom').on('click', () => {
        $('#pager__dropdown__bottom').toggle();
    });

    $('#paging__single__click__top, #paging__single__click__bottom').on('click', () => {
        const url = window.location.href,
            protocol = url.split('/');
        window.location.href = `${protocol[0]}//${window.location.hostname}${window.location.pathname}?one=true`;
    });

    $('.paging__dropdown__top, .paging__dropdown__bottom')
        .append('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="11px" height="12px" viewBox="0 0 11 12" xml:space="preserve"><path d="M5.6,1L10,5H1L5.6,1z M5.4,11L1,7h9L5.4,11z" fill="currentColor"/></svg>');
}

function hidePager() {
    $('.pager').css({
        display: 'none',
    });
}

function handlePianoPayWall() {
    let isHandled = false;
    window.tp.push(['addHandler', 'experienceExecute', (e) => {
        if (isHandled) {
            return;
        }

        let isFullArticleAccessible = false;

        try {
            if (e.accessList && e.accessList.length > 0) {
                for (let i = 0; i < e.accessList.length; i++) {
                    if (e.accessList[i].aid === tp.aid) {
                        isFullArticleAccessible = true;
                        break;
                    }
                }
            }
        } catch (error) {
            console.info('Could not read Piano accessList[].');
        }

        if (isFullArticleAccessible) {
            const $payWallContainer = $('#paywall-container');
            $payWallContainer.children().insertBefore($payWallContainer);
            initPaging();
        } else {
            hidePager();
        }

        isHandled = true;
    }]);
}

$(() => {
    const hasPager = $('.article__body').find('.pager').length,
        $pianoPayWallContainer = $('#paywall-container');

    let onePager = false;

    if (getUrlParameter('one')) {
        onePager = getUrlParameter('one');
    }

    if (onePager) {
        hidePager();
    }

    if (hasPager && !onePager) {
        if ($pianoPayWallContainer.length > 0 && typeof window.tp !== 'undefined') {
            handlePianoPayWall();
        } else {
            initPaging();
        }
    }
});
