/* Autoplay Brightcove Video
   Author: Peter Marhewka
*/


// Autoplay Checkbox

function VideoAutoplay(rootNode, opts) {
    this.rootNode = rootNode;
    this.cookie();
}

VideoAutoplay.prototype.cookie = function () {
    const that = this;
    jQuery(this.rootNode).on('change', function() {
        const $input = $(this);

        if ($input.prop('checked') === true) {
            window.createCookie('videoAutoplay', 'no', 14);
            window.createCookie('videoAutoplayUserChoice', 'no', 14);
            // window.console.log("STATE A", $input.prop( "checked" ));
        }

        if ($input.prop('checked') === false) {
            window.eraseCookie('videoAutoplay');
            window.eraseCookie('videoAutoplayUserChoice');
            // window.console.log("STATE B", $input.prop( "checked" ));
        }

        // user changed himself autoplay setting
        if (window.readCookie('videoAutoplayUserChanged') == null) {
            window.createCookie('videoAutoplayUserChanged', 'yes', 14);
        }
    });

    if (window.readCookie('autoplayByLoginStatus') === 'yes' && window.readCookie('videoAutoplayUserChanged') === null || (window.readCookie('videoAutoplayUserChoice') === 'no' && window.readCookie('videoAutoplay') === 'no') || (window.readCookie('videoAutoplayUserChanged') === 'yes' && window.readCookie('autoplayByLoginStatus') === 'yes')) {
        jQuery(this.rootNode).prop('checked', true);
    }
};

jQuery.fn.videoAutoplay = function (opts) {
    return this.each(function () {
        new VideoAutoplay(this, opts);
    });
};
