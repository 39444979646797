
/* Implementation of Lightbox Carousell Mediagallerie Functions
    Author: Peter Marhewka
 */


$.fn.lightboxCarousel = function() {
    return this.each(() => {
        function orientationOut() {
            const windowHeight = $(window).height(),
                windowWidth = $(window).width();
            if (windowHeight < windowWidth) {
                $('.lightbox--media .hero-media__media').height(windowHeight);
                $('.lightbox--media .carousel__list').height(windowHeight);
                $('.lightbox--media .hero-media__caption').hide();
            } else if (windowHeight > windowWidth) {
                $('.carousel').carousel();
                $('.lightbox--media .hero-media__media').removeAttr('style');
                $('.lightbox--media .hero-media__caption').show();
            }
        }

        function getWindowDimensions() {
            const mobileWidth = 767;
            if ($(window).width() < mobileWidth) {
                $('.lightbox--media .hero-media__media').removeAttr('style');
                orientationOut();
            }
        }

        $(window).on('orientationchange', (event) => {
            getWindowDimensions();
        });
        $(window).on('resize', () => {
            getWindowDimensions();
        });
        $(document).on('click', '.carousel__caption__button', () => {
            $('.lightbox--media .hero-media__caption').slideToggle();
        });
        getWindowDimensions();
    });
};
