/**
 * Track clicks for social buttons: follow (FDC-1900) and share (FDC-2661)
 *
 * add request parameter ?gtm-debug to URL to display additional debugging information into console
 */


$(() => {
    const enableDebuggingMessages = window.location.search.indexOf('gtm-debug') > -1;

    /** *********************************************
     * register click listeners for follow buttons *
     ********************************************** */
    const socialFollowElementSelectors = [
        '.follow-us a', // BMO (XHTML Widget)
        '.socialmedia-icons-header a', // News38 (Header)
        '.header-top .social-channels a', // Fuzo (Header)
        '.nav-meta__list a', // Bdf (Header & Footer)
        '.header-social-icons a', // Goka (Header & Mobile-Header)
        '.header-top__links.socialbar a', // BZV (Header)
        '.footer__social a', // NRW-MP
    ];

    socialFollowElementSelectors.forEach((selector) => {
        jQuery(selector).each((idx, elem) => {
            const $elem = $(elem);

            if ($elem.length && $elem.is(':visible')) {
                /* eslint-disable */
                // TODO: Refactor for compliance with eslint rules
                let href = $elem.attr('href'),
                    title = $elem.attr('title'),
                    network = '',
                    action = 'follow';

                if (href) {
                    if (href.indexOf('facebook') !== -1) {
                        network = 'Facebook';
                        action = 'like-facebook-page';
                    } else if (href.indexOf('twitter') !== -1) {
                        network = 'Twitter';
                    } else if (href.indexOf('pinterest') !== -1) {
                        network = 'Pinterest';
                    } else if (href.indexOf('plus.google') !== -1 || title === 'Google+') {
                        network = 'Google Plus';
                        action = 'plus-one';
                    } else if (href.indexOf('linkedin') !== -1) {
                        network = 'LinkedIn';
                        action = 'connect';
                    } else if (href.indexOf('youtube') !== -1) {
                        network = 'YouTube';
                    } else if (href.indexOf('instagram') !== -1) {
                        network = 'Instagram';
                    } else if (href.indexOf('xing') !== -1) {
                        network = 'Xing';
                    } else if (href.indexOf('flipboard') !== -1) {
                        network = 'Flipboard';
                    }

                    if (network !== '') {
                        if (enableDebuggingMessages) {
                            window.console.debug('gtm-debug - social-tracking.js: registering click handler for selector %c%s', 'font-weight: bold', selector);
                        }
                        (function($elem, network, action, selector, enableDebuggingMessages) {
                /* eslint-enable */
                            $elem.on('click', (e) => {
                                const data = {
                                    event: 'socialTrigger',
                                    socialNetwork: network,
                                    socialAction: action,
                                };
                                if (enableDebuggingMessages) {
                                    window.console.debug('gtm-debug - social-tracking.js: clicked on ', e.delegateTarget);
                                    window.console.debug('gtm-debug - social-tracking.js: click handler called because of selector %c%s', 'font-weight: bold', selector);
                                    window.console.debug('gtm-debug - social-tracking.js: calling window.dataLayer.push(%o)', data);
                                }
                                window.dataLayer.push(data);
                            });
                        }($elem, network, action, selector, enableDebuggingMessages));
                    }
                }
            }
        });
    });

    /** ********************************************
     * register click listeners for share buttons *
     ********************************************* */
    /**
     * This array contains objects that describe
     * - selector: the selector for the specific share button
     * - network: the value for the 'network' as expected by Google Tag Manager
     * - action: the value for the 'action' as expected by Google Tag Manager
     * @type {{selector: String, network: String, action: String}[]}
     */
    const shareActionDescriptors = [
        { selector: 'article .share-button.facebook', network: 'Facebook', action: 'share' },
        { selector: 'article .share-button.twitter', network: 'Twitter', action: 'tweet' },
        { selector: 'article .share-button.pinterest', network: 'Pinterest', action: 'pin' },
        { selector: 'article .share-button.pinterest2', network: 'Pinterest', action: 'pin' },
        { selector: 'article .share-button.whatsapp', network: 'WhatsApp', action: 'share' },
        { selector: 'article .share-button.fb-messenger', network: 'Facebook Messenger', action: 'share' },
        { selector: 'article .share-button.email', network: 'email', action: 'share' },
        { selector: 'article .share-button.instagram', network: 'Instagram', action: 'share' },
        { selector: 'article .share-button.xing', network: 'Xing', action: 'share' },
        { selector: 'article .share-button.flipboard', network: 'Flipboard', action: 'share' },
        { selector: 'article .share-button.linkedin', network: 'LinkedIn', action: 'share' },
    ];

    // register click handlers for the various share buttons, but only of they are actually being used and are visible
    // basically call window.dataLayer.push() upon a click on a .share-button
    shareActionDescriptors.forEach((descriptor) => {
        const $elem = jQuery(descriptor.selector);
        if ($elem.length && $elem.is(':visible')) {
            if (enableDebuggingMessages) {
                window.console.debug('gtm-debug - social-tracking.js: registering click handler for selector %c%s%c (%i match/es)', 'font-weight: bold', descriptor.selector, 'font-weight: normal', $elem.length);
            }
            $elem.on('click', (e) => {
                const data = {
                    event: 'socialTrigger',
                    socialNetwork: descriptor.network,
                    socialAction: descriptor.action,

                };
                if (enableDebuggingMessages) {
                    window.console.debug('gtm-debug - social-tracking.js: clicked on ', e.delegateTarget);
                    window.console.debug('gtm-debug - social-tracking.js: click handler called because of selector %c%s', 'font-weight: bold', descriptor.selector);
                    window.console.debug('gtm-debug - social-tracking.js: calling window.dataLayer.push(%o)', data);
                }
                window.dataLayer.push(data);
            });
        }
    });
});
