/* globals grecaptcha */

$.fn.ajaxForm = function (options) {
    function returnHtml(data, $that, opts) {
        const tempDom = $('<div>').append($.parseHTML(data));
        if (!tempDom.find("input[name='success']").length && $that.find('.form-result').length) {
            $that.find('.form-result').html(data);
        } else {
            $that.html(data);
            $(window).scrollTop(parseInt($that.offset().top) - 200);
        }
        if (typeof opts.done === 'function') {
            opts.done.call();
        }
    }

    return this.each(function () {
        const that = this,
            $that = $(this),
            $form = $('form', this),
            $submitLink = $('a.submit', this),

            opts = $.extend({
                before: null,
                context: document.body,
                fail: null,
                done: null,
                type: 'POST',
                url: '',
                reCaptcha: $form.find('.g-recaptcha').length,
            }, options);

        if ($form.find('.g-recaptcha').length > 0) {
            $form.off('submit').on('submit', function () {
                let data = $(this).serializeArray();

                if (typeof opts.before === 'function') {
                    data = opts.before.call(this, data);
                }

                /* eslint-disable */
                $.ajax({
                    cache: false,
                    context: opts.context,
                    data,
                    dataType: 'HTML',
                    type: opts.type,
                    url: opts.url,
                }).done((data, textStatus, jqXHR) => {
                    console.log('ajax done');
                    returnHtml(data, $that, opts);
                }).fail((jqXHR, textStatus, failThrown) => {
                    // log(jqXHR, textStatus, failThrown);
                    if (typeof opts.fail === 'function') {
                        opts.fail.call();
                    }
                });

                return false;

            });
        } else {
            $that.on('submit', 'form', function () {
                let data = $(this).serializeArray();

                if (typeof opts.before === 'function') {
                    data = opts.before.call(this, data);
                }

                $.ajax({
                    cache: false,
                    context: opts.context,
                    data,
                    type: opts.type,
                    url: opts.url,
                }).done((data, textStatus, jqXHR) => {
                    returnHtml(data, $that, opts);
                }).fail((jqXHR, textStatus, failThrown) => {
                    // log(jqXHR, textStatus, failThrown);
                    if (typeof opts.fail === 'function') {
                        opts.fail.call();
                    }
                });
                return false;
                /* eslint-enable */
            });
        }


        if (!opts.reCaptcha) {
            $submitLink.on('click', function () {
                $(this).closest('form').trigger('submit');
                return false;
            });

            $('input[type=submit]', this).on('click', function () {
                $('input[name=action]', that).val($(this).attr('name'));
            });
        } else if (opts.submit) {
            $form.trigger('submit');
        }
    });
};


$.fn.ajaxLink = function (options) {
    return this.each(function () {
        const that = this,

            opts = $.extend({
                before: null,
                context: document.body,
                data: null,
                fail: null,
                done: null,
                target: null,
                type: 'POST',
                url: $(this).data('ajax-url') || $(this).attr('href'),
            }, options);


        $(this)
            .on('click', function () {
                let { data } = opts;
                if (!data && (opts.type === 'POST')) {
                    data = `nocache=${Math.random() * 100000}`;
                }

                if (typeof opts.before === 'function') {
                    data = opts.before.call(this, data);
                }
                /*eslint-disable */
                $.ajax({
                    cache: false,
                    context: opts.context,
                    data,
                    type: opts.type,
                    url: opts.url,
                })
                    .done((data, textStatus, jqXHR) => {
                        const $lightbox = $(that).closest('.lightbox');
                        if ($lightbox.length) {
                            $lightbox.trigger('gallery_slide_changed');
                        }
                        $(opts.target).html(data);
                        if (typeof opts.done === 'function') {
                            opts.done.call();
                        }
                        // Wait for ad to load and center it afterwards
                        window.setTimeout(() => {
                            $(opts.target).trigger('center-ads');
                        }, 500);
                    })
                    .fail((jqXHR, textStatus, failThrown) => {
                        // log(jqXHR, textStatus, failThrown);
                        if (typeof opts.fail === 'function') {
                            opts.fail.call();
                        }
                    });

                return false;
                /* eslint-enable */
            });
    });
};


/**
 * Lädt den Inhalt des angebundenen Elements von der im Parameter <code>url</code> übergebenen
 * AJAX-URL in periodischem Abstand immer wieder.
 *
 */
$.fn.ajaxRefresh = function (options) {
    return this.each(function () {
        const that = this,

            opts = $.extend({
                before: null,
                context: document.body,
                data: null,
                fail: null,
                initial: true,
                interval: null,
                refresh: true,
                done: null,
                type: 'GET',
                url: null,
            }, options);

        if (opts.initial) {
            $(that).ajaxUpdate(opts);
            opts.initial = false;
        }

        if (opts.refresh) {
            window.setTimeout(() => {
                $(that).ajaxUpdate(opts).ajaxRefresh(opts);
            }, opts.interval);
        }
    });
};


/**
 * Lädt den Inhalt des angebundenen Elements von der im Parameter <code>url</code> übergebenen
 * AJAX-URL.
 *
 * Beispiel: $("#elementId").ajaxUpdate({"url": "..."});
 */
$.fn.ajaxUpdate = function (options) {
    return this.each(function () {
        const that = this,

            opts = $.extend({
                before: null,
                context: document.body,
                data: null,
                fail: null,
                done: null,
                type: 'GET',
                url: null,
            }, options);

        let { data } = opts;


        if (!data && (opts.type === 'POST')) {
            data = `nocache=${Math.random() * 100000}`;
        }

        if (typeof opts.before === 'function') {
            data = opts.before.call(this, data);
        }
        /*eslint-disable */
        $.ajax({
            cache: false,
            context: opts.context,
            data,
            type: opts.type,
            url: opts.url,
        })
            .done((data) => {
                $(that).html(data);
                if (typeof opts.done === 'function') {
                    opts.done.call();
                }
            })
            .fail(() => {
                if (typeof opts.fail === 'function') {
                    opts.fail.call();
                }
            });
        /* eslint-enable */
    });
};


// Init --------------------------------------------------------------------------------------------

$.ajaxSetup({
    // Wichtig f. iOS 6:
    cache: 'false',
    headers: { 'cache-control': 'no-cache' },
});
