/* Implemenation of various Utility-functions.
    Author: Matthias Klebe
 */


const mobileThreshold = 768,
    utils = {
        isMobileByWidth() {
            return $(window).width() < mobileThreshold;
        },

        isDesktopByWidth() {
            return $(window).width() >= mobileThreshold;
        },
        isMobileByUseragent() {
            // by https://stackoverflow.com/a/24600597/3628926
            return /Mobi/.test(navigator.userAgent);
        },

        /**
         * returns the value of a specific URL parameter
         *
         * from http://www.jquerybyexample.net/2012/06/get-url-parameters-using-jquery.html
         *
         * @param {string} sParam
         * @returns {null|*}
         */
        getUrlParameter(sParam) {
            const sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&');

            let sParameterName;

            for (let i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }

            return null;
        },
    };

if (jQuery.utils === undefined) {
    window.jQuery.utils = {};
}

window.jQuery.extend(jQuery.utils, utils);
