

/**
 * This is a simple jQuery tab plugin.
 * Required CSS class names can be modified by configuration.
 */
function Tabs(rootNode, opts) {
    const that = this;

    this.rootNode = rootNode;
    this.tabClass = opts.tabClass;
    this.bodyClass = opts.bodyClass;
    this.triggerEvent = opts.triggerEvent;

    this.activeTabClass = `${this.tabClass}--active`;
    this.activeBodyClass = `${this.bodyClass}--active`;
    this.$tabs = $(`.${this.tabClass}`, this.rootNode);
    this.$bodyItems = $(`.${this.bodyClass}`, this.rootNode);
    this.$tabs.on(this.triggerEvent, function (evt) {
        evt.preventDefault();
        evt.stopPropagation();

        if (!$(this).hasClass(that.activeTabClass)) {
            that.switchToTab(this);
        }
    });
}

Tabs.prototype.switchToTab = function (tab) {
    const $activeTab = $(tab),
        activeIndex = $activeTab.prevAll(`.${this.tabClass}`).length,
        $activeBody = $(this.$bodyItems.get(activeIndex));

    this.$tabs.removeClass(this.activeTabClass);
    this.$bodyItems.removeClass(this.activeBodyClass);
    $activeTab.addClass(this.activeTabClass);
    $activeBody.addClass(this.activeBodyClass);
};

$.fn.tabs = function (options) {
    return this.each(function () {
        new Tabs(this, $.extend({
            tabClass: 'tabs__tab',
            bodyClass: 'tabs__body',
            triggerEvent: 'click',
        }, options));
    });
};
