

jQuery(() => {
    /* eslint-disable */
    // TODO: Refactor for compliance with eslint rules
    const triggerHref = function(eventObject) {
        const that = this;
        if (!jQuery(eventObject.target).attr('href')) {
            let href = jQuery(that).parents('.teaser-leader').find('.teaser-headline-block .headline').find('.headline')
                .find('.teaser__link')
                .attr('href');
            if (!href) {
                href = jQuery(that).parents('.teaser-leader').find('.teaser__link').attr('href');
            }
            if (href) {
                window.location.href = href;
                return false;
            }
        }
    };
    /* eslint-disable */

    jQuery('.teaser-leader .overlay').on('click', triggerHref);
    jQuery('.teaser-leader .headline').on('click', triggerHref);
});
