/**
 * Created by gsondermeier
 *
 * show a static message that will be closed automatically and can be closed manually earlier on
 */

/**
 * show a static message by appending a div.fd-static-message to the body
 *
 * @todo
 * * add a close icon to give the user feedback that he can close this message
 *
 * @param {string} message
 * @param {'success'|'warning'|'error'|null} type
 */
window.staticMessage = function staticMessage(message, type) {
    if (message) {
        const displayDurationInMs = 10000,
            fadeoutDurationInMs = 500,
            elementClassName = 'fd-static-message',
            specificClassName = ['success', 'warning', 'error'].indexOf(type) !== -1 ? `${elementClassName}-${type}` : '',
            $elem = jQuery(`<div class="${elementClassName} ${specificClassName}"><p>${message}</p></div>`);

        // attach our element to the .page-wrapper
        $elem.appendTo('.page-wrapper');

        // if there is a superbanner, move our element further down so that it doesn't overlap the header
        const $adSuperbanner = jQuery('.ad__superbanner');
        if ($adSuperbanner.is(':visible')) {
            $elem.css({
                top: `${$elem.position().top + $adSuperbanner.height()}px`,
            });
        }

        // fade out the element and remove it after displayDurationInMs
        const timeout = window.setTimeout(() => {
            $elem.fadeOut(fadeoutDurationInMs, () => {
                $elem.remove();
            });
        }, displayDurationInMs - fadeoutDurationInMs);

        // attach a click listener to remove the element instantly when clicked on
        $elem.on('click', () => {
            $elem.fadeOut(fadeoutDurationInMs/2, () => {
                $elem.remove();
                clearTimeout(timeout);
            });
        });
    }
};
