/* Video Sticky Mode
    Author: Peter Marhewka
    https://localhost:3000/wirtschaft/article214319613/Technik-Panne-legt-Sparda-Banken-deutschlandweit-lahm.html
    Make Player sticky if scrolling hit top of the player.
*/


let videoIsPlay = '',
    videoIsPause = '';

function VideoStickyMode(rootNode, opts) {
    this.rootNode = rootNode;
    this.scroll();
}

VideoStickyMode.prototype.scroll = function () {
    try {
        const that = this,
            debug = false,
            elementId = jQuery(this.rootNode).attr('id'),
            mediaBoxes = document.querySelector(`#${elementId} .video-js`),
            mediaId = mediaBoxes.dataset.videoId || mediaBoxes.dataset.playlistId;


        // create and dispatch the event. This defines Variables witch are usesed in the script. the will be updated at some points.
        const makeFixed = new CustomEvent('videosticky', {
                detail: {
                    brightcovePlayerPlay: false,
                    brightcovePlayer: window.bc(`video-${mediaId}`),
                    element: document.getElementById(elementId),
                    elementHeight: jQuery(this.rootNode)[0].getBoundingClientRect().height,
                    elementTop: jQuery(this.rootNode)[0].getBoundingClientRect().top,
                    elementLeft: jQuery(this.rootNode)[0].getBoundingClientRect().left,
                    pageWrapperLeft: jQuery('.main')[0].getBoundingClientRect().left,
                    pageInnerSpace: parseInt(jQuery('.page').css('paddingLeft')),
                    placeholderTop: 0,
                    placeholderId: `placeholder-${jQuery(this.rootNode).attr('id')}`,
                    closeButtonId: `player-fixed--close-${jQuery(this.rootNode).attr('id')}`,
                    stickyState: false,
                    videoIsPlay: '',
                    videoIsPause: '',
                    headerHeight: 20,
                    minimumSpaceLeft: 300,
                    fixedElementSpace: 16,
                    playerFixedMaxWidth: 500,
                },
            }),
            mf = makeFixed.detail;

        // Event Listener
        mf.element.addEventListener('videosticky', (e) => {
            // DEBUG Output Set Global var debug to true
            if (debug === true) {
                console.table([
                    ['brightcovePlayerPlay', e.detail.brightcovePlayerPlay],
                    ['brightcovePlayer', e.detail.brightcovePlayer],
                    ['element', e.detail.element],
                    ['elementHeight', e.detail.elementHeight],
                    ['elementTop', e.detail.elementTop],
                    ['elementLeft', e.detail.elementLeft],
                    ['pageWrapperLeft', e.detail.pageWrapperLeft],
                    ['placeholderTop', e.detail.placeholderTop],
                    ['placeholderId', e.detail.placeholderId],
                    ['closeButtonId', e.detail.closeButtonId],
                    ['stickyState', e.detail.stickyState],
                    ['videoIsPlay', e.detail.videoIsPlay],
                    ['videoIsPause', e.detail.videoIsPause],
                ]);
            }

            // END Debug

            function removeSticky() {
                jQuery(that.rootNode).removeClass('player-fixed player-fixed--mobile player-fixed--desktop player-fixed-hover').removeAttr('style');

                if (e.detail.pageWrapperLeft > e.detail.minimumSpaceLeft) {
                    if (e.detail.brightcovePlayerPlay === true) {
                        jQuery(`#${e.detail.placeholderId}`).remove();
                    } else {
                        jQuery(that.rootNode).one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', () => {
                            jQuery(`#${e.detail.placeholderId}`).remove();
                        });
                    }
                } else {
                    jQuery(`#${e.detail.placeholderId}`).remove();
                }
            }

            /* eslint-disable */
            if (e.detail.brightcovePlayer.id_ !== e.detail.videoIsPlay && e.detail.videoIsPause !== '' && e.detail.videoIsPlay !== e.detail.videoIsPause) {
                jQuery(that.rootNode).removeClass('player-fixed--locked');
            }
            /* eslint-enable */

            if (e.detail.brightcovePlayerPlay === true) {
                if (e.detail.pageWrapperLeft > e.detail.minimumSpaceLeft) {
                    if (!jQuery(that.rootNode).hasClass('video-transition')) {
                        jQuery(that.rootNode).addClass('video-transition');
                        e.detail.elementHeight = jQuery(that.rootNode)[0].getBoundingClientRect().height;
                    }
                } else {
                    jQuery(that.rootNode).removeClass('video-transition');
                }

                /* eslint-disable */
                if (e.detail.videoIsPause.id_ !== '' && e.detail.videoIsPause.id_ !== e.detail.videoIsPlay.id_) {
                    if (jQuery(that.rootNode).find(`#${e.detail.videoIsPause.id_}`).length > 0) {
                        jQuery(that.rootNode).removeClass('player-fixed player-fixed--mobile player-fixed--desktop player-fixed-hover').removeAttr('style');
                    }
                }
                /* eslint-enable */

                if (e.detail.stickyState === true) {
                    if (jQuery(`#${e.detail.placeholderId}`).length === 0) {
                        jQuery(`<div id='${e.detail.placeholderId}' class='video-placeholder'></div>`).insertBefore(that.rootNode).css({
                            height: e.detail.elementHeight,
                        });

                        if (e.detail.pageWrapperLeft > e.detail.minimumSpaceLeft) {
                            jQuery(that.rootNode).addClass('player-fixed player-fixed--desktop');

                            jQuery(`#${e.detail.placeholderId}`).css({
                                height: e.detail.elementHeight,
                            }).addClass('video-placeholder--styled');

                            if (e.detail.pageWrapperLeft < e.detail.playerFixedMaxWidth) {
                                that.pageInnerSpace = e.detail.pageInnerSpace;
                                that.elementStickyWidth = e.detail.pageWrapperLeft - (e.detail.fixedElementSpace * 2) - e.detail.pageInnerSpace;
                            } else {
                                that.pageInnerSpace = 0;
                                that.elementStickyWidth = e.detail.playerFixedMaxWidth;
                            }

                            jQuery(that.rootNode).css({
                                width: that.elementStickyWidth,
                                transform: `translate3d(${-(that.elementStickyWidth - (e.detail.pageWrapperLeft - e.detail.elementLeft - e.detail.fixedElementSpace) + that.pageInnerSpace)}px, ${e.detail.fixedElementSpace}px , 0px)`,
                            }).addClass('player-fixed-hover');
                        } else {
                            jQuery(that.rootNode).addClass('player-fixed player-fixed--mobile');
                            if (jQuery(window).width() < 768) {
                                jQuery(that.rootNode).css({
                                    transform: `translate3d(${-(e.detail.elementLeft - e.detail.pageWrapperLeft)}px, 0px, 0px)`,
                                    top: e.detail.headerHeight - 1,
                                });
                            } else {
                                jQuery(that.rootNode).css({
                                    transform: `translate3d(${-(e.detail.elementLeft - e.detail.pageWrapperLeft)}px, 0px, 0px)`,
                                    top: e.detail.headerHeight - 1,
                                });
                            }
                        }
                    } else {
                        mf.placeholderTop = jQuery(`#${e.detail.placeholderId}`)[0].getBoundingClientRect().top;
                    }
                } else {
                    removeSticky();
                }
                /* eslint-disable */
            } else if (e.detail.videoIsPause.id_ !== '' && e.detail.videoIsPlay !== e.detail.videoIsPause) {
                e.detail.stickyState = false;
                removeSticky();
            }
            /* eslint-enable */

            if (jQuery(`#${e.detail.placeholderId}`).length > 0) {
                if (e.detail.brightcovePlayerPlay === false && jQuery(`#${e.detail.placeholderId}`)[0].getBoundingClientRect().top > 0) {
                    mf.placeholderTop = 0;
                    removeSticky();
                }
            }
        });

        // #### Window Events ####

        jQuery(window).on('resize', () => {
            jQuery(`#${mf.placeholderId}`).remove();
            jQuery(that.rootNode).removeClass('player-fixed player-fixed--mobile player-fixed--desktop player-fixed-hover').removeAttr('style');
            jQuery('.player-fixed--close').remove();
            if (mf.elementTop < mf.headerHeight && mf.placeholderTop <= mf.headerHeight) {
                mf.stickyState = false;
                mf.element.dispatchEvent(makeFixed);
            } else {
                mf.placeholderTop = 0;
                mf.stickyState = false;
                mf.element.dispatchEvent(makeFixed);
            }

            mf.elementLeft = jQuery(that.rootNode)[0].getBoundingClientRect().left;
            mf.element.dispatchEvent(makeFixed);
        });

        // Window Scroll
        jQuery(window).on('scroll', () => {
            mf.videoIsPause = videoIsPause;
            mf.videoIsPlay = videoIsPlay;
            mf.pageWrapperLeft = jQuery('.main')[0].getBoundingClientRect().left;
            mf.elementTop = jQuery(that.rootNode)[0].getBoundingClientRect().top;
            mf.element.dispatchEvent(makeFixed);

            if (jQuery(window).width() < 768) {
                const elem = document.getElementsByClassName('header-top')[0],
                    headerTopPosition = window.getComputedStyle(elem, null).getPropertyValue('position');

                if (headerTopPosition === 'fixed') {
                    mf.headerHeight = jQuery('.header-top')[0].getBoundingClientRect().height + 10;
                } else {
                    mf.headerHeight = 1;
                }

                mf.element.dispatchEvent(makeFixed);
            }

            if (jQuery(window).width() > 768) {
                if (mf.pageWrapperLeft < mf.minimumSpaceLeft) {
                    if (jQuery('.header').hasClass('fixed') || jQuery('.header').hasClass('header--sticky')) {
                        mf.headerHeight = jQuery('.header').height() + 5;
                    }

                    // BDF only
                    if (jQuery('body').hasClass('pub-bdf')) {
                        mf.headerHeight = jQuery('.nav-main').height() + 10;
                    }
                    mf.element.dispatchEvent(makeFixed);
                }
            }

            if (jQuery(`#${mf.closeButtonId}`).length === 0) {
                if (mf.pageWrapperLeft < mf.minimumSpaceLeft) {
                    jQuery(that.rootNode).prepend(`<div id='${mf.closeButtonId}' class='player-fixed--close'></div>`);
                }

                jQuery(`#${mf.closeButtonId}`).on('click', () => {
                    jQuery(that.rootNode).addClass('player-fixed--locked');
                    jQuery('.player-fixed--close').remove();
                    mf.stickyState = false;
                    mf.element.dispatchEvent(makeFixed);
                });
            }

            if (mf.elementTop < mf.headerHeight && mf.placeholderTop <= mf.headerHeight) {
                if (!jQuery(that.rootNode).hasClass('player-fixed--locked')) {
                    mf.stickyState = true;
                    mf.element.dispatchEvent(makeFixed);
                } else {
                    jQuery('.player-fixed--close').remove();
                    mf.stickyState = false;
                    mf.element.dispatchEvent(makeFixed);
                }
            } else {
                mf.placeholderTop = 0;
                mf.stickyState = false;
                mf.element.dispatchEvent(makeFixed);
            }
        });

        const playerIsPlaying = function() {
            /* eslint-disable */
            videoIsPlay = mf.brightcovePlayer.id_;
            /* eslint-enable */
            mf.videoIsPlay = videoIsPlay;
            mf.brightcovePlayerPlay = true;
            mf.placeholderTop = jQuery(that.rootNode)[0].getBoundingClientRect().top;
            mf.element.dispatchEvent(makeFixed);
        };

        const playerIsPaused = function() {
            /* eslint-disable */
            videoIsPause = mf.brightcovePlayer.id_;
            /* eslint-enable */
            mf.videoIsPause = videoIsPause;
            mf.brightcovePlayerPlay = false;
            mf.placeholderTop = jQuery(that.rootNode)[0].getBoundingClientRect().top;
            mf.element.dispatchEvent(makeFixed);
        };

        // Brightcove Player Events
        mf.brightcovePlayer.ready(() => {
            mf.brightcovePlayer.on('play', () => {
                playerIsPlaying();
            });

            mf.brightcovePlayer.on('pause', () => {
                playerIsPaused();
            });

            mf.brightcovePlayer.on('ended', () => {
                mf.brightcovePlayerPlay = false;
                mf.element.dispatchEvent(makeFixed);
            });
        });

        mf.brightcovePlayer.ima3.ready(() => {
            mf.brightcovePlayer.on('ads-play', () => {
                playerIsPlaying();
            });

            mf.brightcovePlayer.on('ads-pause', () => {
                playerIsPaused();
            });

            mf.brightcovePlayer.on('ads-ad-started', () => {
                playerIsPlaying();
            });
        });
    } catch (err) {
        window.console.log('video-sticky-mode:', err.message);
    }
};

jQuery.fn.videoStickyMode = function (opts) {
    return this.each(function () {
        new VideoStickyMode(this, opts);
    });
};
