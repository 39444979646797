function PaywallHeaderMobile(rootNode) {
    this.rootNode = rootNode;
    this.loginButton = document.getElementById('paywall-header-button-one');
    this.mobileModifier = document.getElementById('paywall-mobile-user');
    this.state = {
        menuButton: false,
        userButton: false,
    };
    this.clickEvents();
}

PaywallHeaderMobile.prototype.setState = function(state) {
    const that = this;
    Object.keys(this.state).forEach((key) => {
        if (state[key] === undefined) {
            that.state[key] = false;
        } else {
            that.state[key] = !that.state[key];
        }

        that.loginButton.checked = that.state[key];
        that.mobileModifier.checked = that.state[key];
    });
};

PaywallHeaderMobile.prototype.clickEvents = function() {
    const that = this;
    let modalInst;

    jQuery(document).on('click', '.header-top--mobile .header-top-button', (event) => {
        const clickedElem = event.target,
            $clickedElem = jQuery(clickedElem),
            isKeycloakLoginButton = $clickedElem.hasClass('header-top-button--keycloak-login');

        // Do not register login listeners when using Keycloak SSO
        if (isKeycloakLoginButton) {
            return;
        }

        jQuery('.nav-main-toggle--closed').trigger('click');

        if ($clickedElem.hasClass('header-top-button--close')) {
            modalInst.close();
        } else {
            modalInst = jQuery(`[data-remodal-id=${clickedElem.dataset.fdpmodalTarget}]`).remodal();
        }

        that.setState({ userButton: !that.state.userButton });
    });

    jQuery(document).on('click', '.nav-main-toggle', () => {
        that.setState({ menuButton: !that.state.menuButton });
    });
};

jQuery.fn.paywallHeaderMobile = function(opts) {
    return this.each(function() {
        new PaywallHeaderMobile(this, opts);
    });
};
