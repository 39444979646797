(($) => {
    $.fn.cmpHelper = {
        onConsentReady(callback) {
            if ($(document).data('CmpConsentReady')) {
                callback({});
            } else {
                $(document).one('CmpConsentReady', (e, cmpSource) => {
                    callback(cmpSource);
                });
            }
        },
        isConsented(vendorCode) {
            let hasConsent = false;

            if (typeof __cmp === 'function') {
                const cmpData = __cmp('getCMPData');

                if (typeof cmpData === 'object' && typeof cmpData.vendorConsents === 'object') {
                    hasConsent = !!cmpData.vendorConsents[vendorCode];
                }
            }

            return hasConsent;
        },
        setConsent(vendorCode, isConsented) {
            if (typeof __cmp === 'function') {
                // This triggers 'CmpConsentChanged' automatically
                __cmp('setVendorConsent', [vendorCode, isConsented ? 1 : 0]);
            }
        },
        onConsentChange(callback) {
            $(document).on('CmpConsentChanged', (e, cmpSource) => {
                callback(cmpSource);
            });
        },
        onConsented(vendorCode, callback) {
            if ($.fn.cmpHelper.isConsented(vendorCode)) {
                callback({});
            } else {
                let wasConsented = false;
                $(document).on('CmpConsentReady CmpConsentChanged', (e, cmpSource) => {
                    if (!wasConsented && $.fn.cmpHelper.isConsented(vendorCode)) {
                        wasConsented = true;
                        callback(cmpSource);
                    }
                });
            }
        },
        triggerConsentChange(cmpSource) {
            $(document).trigger('CmpConsentChanged', cmpSource || {});
        },
    };

    function apiCallback(eventName, cmpSource) {
        const $document = $(document);
        if (!$document.data('CmpConsentReady')) {
            $document.data('CmpConsentReady', true);
            $document.trigger('CmpConsentReady', cmpSource);
        } else {
            $document.trigger('CmpConsentChanged', cmpSource);
        }
    }

    // Start listening on __cmp events as soon as it is loaded
    if (typeof __cmp === 'undefined') {
        const cmpInterval = setInterval(() => {
            if (typeof __cmp !== 'undefined') {
                window.clearInterval(cmpInterval);
                __cmp('addEventListener', ['consent', apiCallback, false], null);
            }
        }, 200);
    } else {
        __cmp('addEventListener', ['consent', apiCallback, false], null);
    }
})(jQuery);
