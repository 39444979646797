$(() => {
    const outbrainVendorId = 164;

    function embedOutbrainScript() {
        const outbrainContainers = document.getElementsByClassName('OUTBRAIN');
        if (outbrainContainers.length) {
            const dsId = outbrainContainers[0].dataset.dsId || '';
            const dsParam = dsId.length ? `?i=${dsId}` : '';
            const outbrainJsSrcUrl = `//widgets.outbrain.com/outbrain.js${dsParam}`;
            const scriptElem = document.createElement('script');
            scriptElem.type = 'text/javascript';
            scriptElem.async = true;
            scriptElem.src = outbrainJsSrcUrl;
            document.body.appendChild(scriptElem);
        }
    }

    jQuery.fn.cmpHelper.onConsented(outbrainVendorId, () => {
        embedOutbrainScript();
    });
});
