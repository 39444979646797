

function Calendar(rootNode) {
    const that = this;

    this.rootNode = rootNode;

    $(rootNode).on('click', '.calendar__control', function(e) {
        e.preventDefault();
        const url = $(this).data('ajax-url');

        that.replaceCalendar(that.rootNode, url);
    });
}

Calendar.prototype.replaceCalendar = function(calendar, url) {
    const request = $.ajax({
        url,
    });

    request.done((data, textStatus, jqXHR) => {
        const $html = $(data).html();
        $(calendar).html($html);
    });

    request.fail((jqXHR, textStatus, errorThrown) => {
        console.error('$.fn.calendar ajax request failed', calendar, jqXHR, textStatus, errorThrown);
    });
};

$.fn.calendar = function() {
    return this.each(function() {
        new Calendar(this);
    });
};
