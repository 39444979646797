/* globals readCookie */


function LoginLayer(rootNode, opts) {
    this.rootNode = rootNode;
    this.loginLayer(rootNode, opts);
}


LoginLayer.prototype.loginLayer = function(rootNode, opts) {
    const that = this;

    function setLoggedinCookie() {
        document.cookie = 'login-popup=blocked;';
    }

    function checkLoggedinCookie() {
        if (document.cookie.indexOf('login-popup') <= 0) {
            setLoggedinCookie();
        }
    }

    if (readCookie('fpcacc') && (document.cookie.indexOf('login-popup') <= 0)) {
        setLoggedinCookie();
        const modalSuccess = jQuery.remodal.lookup[jQuery('[data-remodal-id=loginmodal]').data('remodal')];
        modalSuccess.open();

        jQuery('.header-top-abo').addClass('header-top-abo-logged-in');

        setTimeout(() => {
            modalSuccess.close();
            setLoggedinCookie();
        }, 4000);
        checkLoggedinCookie();
    }
};

jQuery.fn.loginLayer = function(opts) {
    return this.each(function() {
        new LoginLayer(this, opts);
    });
};
