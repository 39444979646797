function DeobfuscateText(rootNode, opts) {
    this.rootNode = rootNode;
    this.init();
}

DeobfuscateText.prototype.init = function () {
    jQuery('.obfuscated').each((index, elem) => {
        this.replaceTextContent(elem);
        jQuery(elem).removeClass('obfuscated').addClass('deobfuscated');
    });
    jQuery(document).trigger('paywall-deobfuscation-complete');
};

DeobfuscateText.prototype.replaceTextContent = function (elem) {
    const el = jQuery(elem);
    if (el.hasClass('obfuscated')) {
        const obfuscatedText = elem.textContent;
        let deobfuscatedText = '';

        for (let i = 0; i < obfuscatedText.length; i++) {
            const charValue = obfuscatedText.charCodeAt(i);
            if (charValue === 177) {
                deobfuscatedText += '%';
            } else if (charValue === 178) {
                deobfuscatedText += '!';
            } else if (charValue === 180) {
                deobfuscatedText += ';';
            } else if (charValue === 181) {
                deobfuscatedText += '=';
            } else if (charValue === 32) {
                deobfuscatedText += ' ';
            } else if (charValue === 10) {
                deobfuscatedText += '\n';
            } else if (charValue > 33) {
                deobfuscatedText += String.fromCharCode(charValue - 1);
            }
        }
        el.html(deobfuscatedText);
    }
};

jQuery.fn.deobfuscateText = function (opts) {
    return this.each(function () {
        new DeobfuscateText(this, opts);
    });
};

jQuery(() => {
    if (sessionStorage.deobfuscate === 'true') {
        jQuery('.article__body').deobfuscateText();
    }
});
