

function PinterestInlineResizer(rootNode, opts) {
    this.rootNode = rootNode;
    this.pinterestInlineBlockCheck();
}

PinterestInlineResizer.prototype.pinterestInlineBlockCheck = function () {
    const that = this;
    jQuery(this.rootNode).each(function (index) {
        if (jQuery(this).find('span').first().length) {
            that.pinterestResizer();
        }
    });
};

PinterestInlineResizer.prototype.pinterestResizer = function () {
    /*eslint-disable */
    const that = this,
        myInterval = setInterval(() => {
            pinterestInlineResizer();
        }, 200);

    function pinterestInlineResizer() {
        const pinContainer = jQuery(that.rootNode).find('span').first();

        if (pinContainer.attr('data-pin-log') === 'embed_pin_large' || pinContainer.attr('data-pin-log') === 'embed_pin_medium' || pinContainer.attr('data-pin-log') === 'embed_pin_small') {
            let inlineBlockWidth = jQuery(that.rootNode).outerWidth(),
                pinterestWidth = pinContainer.outerWidth(),
                pinterestScale = (100 / pinterestWidth * inlineBlockWidth) / 100;

            // window.console.log(pinterestScale);

            if (inlineBlockWidth > pinterestWidth) {
                // window.console.log("if", inlineBlockWidth, ">", pinterestWidth);
                if (pinterestScale > 1) {
                    pinterestScale = 1;
                } else {
                    pinterestScale = 2 - pinterestScale;
                }
            } else {
                // window.console.log("Else", inlineBlockWidth, "<", pinterestWidth);
                pinterestScale = pinterestScale;

                jQuery(pinContainer).css({
                    padding: '12px 12px 0',
                });
            }

            pinContainer.css({
                '-webkit-transform-origin': '0 0',
                '-moz-transform-origin': '0 0',
                '-ms-transform-origin': '0 0',
                '-o-transform-origin': '0 0',
                'transform-origin': '0 0',
                '-webkit-transform': `scale(${pinterestScale})`,
                '-moz-transform': `scale(${pinterestScale})`,
                '-ms-transform': `scale(${pinterestScale})`,
                '-o-transform': `scale(${pinterestScale})`,
                'transform': `scale(${pinterestScale})`,
            });
            // window.console.log("inlineBlockWidth " + inlineBlockWidth, "pinterestWidth " + pinterestWidth, pinterestScale);

            const dimensions = pinContainer[0].getBoundingClientRect();

            jQuery(that.rootNode).height(dimensions.height);

            clearInterval(myInterval);
        }
        /* eslint-enable */
    }
};

// init
jQuery.fn.pinterestInlineResizer = function (opts) {
    return this.each(function () {
        new PinterestInlineResizer(this, opts);
    });
};

jQuery(window).on('load', () => {
    if (!jQuery('.inline-block--left').hasClass('social-embed')) {
        jQuery('.inline-block--left').pinterestInlineResizer();
    }

    if (!jQuery('.inline-block--right').hasClass('social-embed')) {
        jQuery('.inline-block--right').pinterestInlineResizer();
    }

    if (!jQuery('.inline-block--left-25').hasClass('social-embed')) {
        jQuery('.inline-block--left-25').pinterestInlineResizer();
    }

    if (!jQuery('.inline-block--right-25').hasClass('social-embed')) {
        jQuery('.inline-block--right-25').pinterestInlineResizer();
    }

    if (!jQuery('.inline-block--wide').hasClass('social-embed')) {
        jQuery('.inline-block--wide').pinterestInlineResizer();
    }
});
