/* Back to Home Button */
/* Author: Peter Marhewka */

/* CodeReview: akr: TODO: to be optimised in matter of code cleaning */


function BackToHome(rootNode, opts) {
    this.rootNode = rootNode;
    this.opts = opts;
    this.init(opts);
}

BackToHome.prototype.init = function (opts) {
    // var that is required if you want call this from first Level
    const that = this,
        options = jQuery.extend({
            timetoredirect: '2000',
            previewOpacity: '0.5',
            previewCustomClass: 'custom-article_preview',
            disableRedirect: false,
            showButtonUpscroll: false,
            fadeOutDelay: '2500',
        }, opts);
    let lastScrollTop = 0,
        fadeOutTimeout = null;

    jQuery(`.${options.previewCustomClass}`).css({
        opacity: options.previewOpacity,
    }).addClass('back-to-home_overlay');

    window.addEventListener('scroll', function (event) {
        const top = this.scrollY,
            documentHeight = jQuery(document).outerHeight(),
            windowHeight = jQuery(window).innerHeight(),
            rectElement = jQuery(`.${options.previewCustomClass}`).position(),
            maxSpace = documentHeight - rectElement.top,
            maxDeg = 360,
            mobileBreakpoint = 768,
            scrollValue = Math.abs((documentHeight - maxSpace) - (top + windowHeight)),
            calcFactor = (maxSpace / maxDeg),
            calcDeg = scrollValue / calcFactor,
            radialProgressElement = jQuery(that.rootNode).find('.radial-progress'),
            linktoPage = jQuery(that.rootNode).find('.back-to-home_link').attr('href');

        // window.console.log("Scroll-Top", top, "documentHeight", documentHeight, "windowHeight", windowHeight, "calcDeg", calcDeg, "scrollValue", scrollValue);
        // window.console.log(top + scrollValue, ">", rectElement.top + (jQuery("." + options.previewCustomClass).height() / 2));
        // window.console.log(rectElement.top + (jQuery("." + options.previewCustomClass).height() / 2), "<", top + windowHeight);

        if (rectElement.top + jQuery(`.${options.previewCustomClass}`).height() + (radialProgressElement.height() / 2) < top + windowHeight && jQuery(window).width() > mobileBreakpoint) {
            if (!radialProgressElement.hasClass('no-fix')) {
                radialProgressElement.addClass('no-fix').css({
                    top: `${rectElement.top + (jQuery(`.${options.previewCustomClass}`).height() / 2) - (radialProgressElement.height() / 2)}px`,
                });
            }
        }

        if (options.disableRedirect === false) {
            if (documentHeight - maxSpace < top + windowHeight) {
                jQuery(that.rootNode).removeClass('back-to-home_hidden');
                radialProgressElement.find('.fix').css({ transform: `rotate(${calcDeg}deg)` });

                if (calcDeg < 180) {
                    radialProgressElement.find('.full').css({ transform: 'rotate(0deg)' });
                    radialProgressElement.find('.full').find('.fill').css({ transform: `rotate(${calcDeg}deg)` });
                    radialProgressElement.find('.half').removeAttr('style');
                    radialProgressElement.find('.half').find('.fill').removeAttr('style');
                } else {
                    radialProgressElement.find('.full').find('.fill').css({ transform: 'rotate(180deg)' });
                    radialProgressElement.find('.half').css({ transform: 'rotate(180deg)' });
                    radialProgressElement.find('.half').find('.fill').css({ transform: `rotate(${calcDeg - 180}deg)` });
                }
            } else {
                radialProgressElement.find('.full').removeAttr('style');
                radialProgressElement.find('.half').removeAttr('style');
                radialProgressElement.find('.fill').removeAttr('style');
                jQuery(that.rootNode).addClass('back-to-home_hidden');

                if (options.showButtonUpscroll === true) {
                    if (top < lastScrollTop) {
                        jQuery(that.rootNode).addClass('back-to-home_disableRedirect').removeClass('back-to-home_hidden');

                        clearTimeout(fadeOutTimeout);

                        fadeOutTimeout = setTimeout(() => {
                            jQuery(that.rootNode).removeClass('back-to-home_disableRedirect').addClass('back-to-home_hidden');
                        }, options.fadeOutDelay);
                    } else {
                        jQuery(that.rootNode).removeClass('back-to-home_disableRedirect');
                    }

                    lastScrollTop = top;
                }
            }

            if (documentHeight - 5 < top + windowHeight) {
                jQuery(that.rootNode).addClass('back-to-home_redirect');

                radialProgressElement.find('.full').removeAttr('style');
                radialProgressElement.find('.half').removeAttr('style');
                radialProgressElement.find('.full').find('.fill').removeAttr('style');
                radialProgressElement.find('.half').find('.fill').removeAttr('style');

                setTimeout(() => {
                    if (jQuery(that.rootNode).hasClass('back-to-home_redirect')) {
                        window.dataLayer.push({
                            event: 'backToHome',
                        });
                        window.location.href = linktoPage;
                    }
                }, options.timetoredirect);
            } else {
                jQuery(that.rootNode).removeClass('back-to-home_redirect');
            }
        } else {
            if (documentHeight - maxSpace < top + windowHeight) {
                jQuery(that.rootNode).removeClass('back-to-home_hidden').addClass('back-to-home_disableRedirect');
            } else {
                radialProgressElement.find('.full').removeAttr('style');
                radialProgressElement.find('.half').removeAttr('style');
                radialProgressElement.find('.fill').removeAttr('style');
                jQuery(that.rootNode).addClass('back-to-home_hidden');
            }

            if (options.showButtonUpscroll === true) {
                if (top < lastScrollTop) {
                    jQuery(that.rootNode).addClass('back-to-home_disableRedirect').removeClass('back-to-home_hidden');

                    clearTimeout(fadeOutTimeout);

                    fadeOutTimeout = setTimeout(() => {
                        jQuery(that.rootNode).removeClass('back-to-home_disableRedirect').addClass('back-to-home_hidden');
                    }, options.fadeOutDelay);
                } else {
                    jQuery(that.rootNode).removeClass('back-to-home_disableRedirect');

                    if (options.disableRedirect === true) {
                        jQuery(that.rootNode).addClass('back-to-home_disableRedirect');
                    }
                }

                lastScrollTop = top;
            }
        }
    }, false);
};

jQuery.fn.backToHome = function (opts) {
    return this.each(function () {
        new BackToHome(this, opts);
    });
};
