/* Youtube Player
    Author: Peter Marhewka
*/


// Setup Player
function YoutubePlayer(rootNode, opts) {
    this.rootNode = rootNode;
    this.contentId = opts.contentId;
    this.autoplayYoutube = opts.autoplayYoutube || '0';
    this.init();
}

YoutubePlayer.prototype.init = function (opts) {
    const that = this;

    function onPlayerReady(event) {
        console.log('Youtube Player - is Ready');

        jQuery('body').on('videoPlay', (videoIdEvent) => {
            // console.log("Youtube", videoIdEvent.id, "!==", that.contentId);
            if (videoIdEvent.id !== that.contentId) {
                that.player.stopVideo();
            }
        });

        if (that.autoplayYoutube === '1') {
            that.player.playVideo();
        }
    }

    function onPlayerStateChange(event) {
        console.log('Youtube Player - State', event.data);
        if (event.data === YT.PlayerState.PLAYING) {
            console.log('Youtube Player - is Playing');

            // Eskalationslayout only grosse-nachrichtenlage.jsp */
            jQuery('.video-youtube').next('.grosse-nachrichtenlage-headline').addClass('hidden');

            // Stop Player when other Player starts to Play video-autostop_player.js
            jQuery('body').trigger(that.videoIdEvent);
        }

        if (event.data === YT.PlayerState.ENDED) {
            stopVideo();
        }

        if (event.data === YT.PlayerState.ENDED || event.data === YT.PlayerState.PAUSED) {
            jQuery(that.rootNode).next('.grosse-nachrichtenlage-headline').removeClass('hidden');
            console.log('Youtube Player - has Ended');
        }
    }

    this.player = new YT.Player(`youtube-${this.contentId}`, {
        playerVars: {
            autoplay: this.autoplayYoutube,
        },
        events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
        },
    });

    this.videoIdEvent = jQuery.Event('videoPlay', {
        id: this.contentId,
    });
};

jQuery.fn.youtubePlayer = function (opts) {
    return this.each(function () {
        new YoutubePlayer(this, opts);
    });
};
