/* Implementation of the StickyBar
    This Plugin implements a functionality like the Bootstrap-Affix plugin (http://getbootstrap.com/javascript/#affix).
    It toggles the Class of an given Element, when it leaves the Viewport so it cat be positioned fix via css.

    Author: Matthias Klebe
 */


function PanelEqualizer(node) {
    const that = this;
    that.draw(node);

    $(node).on('redraw', () => {
        that.redraw(node);
    });
}

PanelEqualizer.prototype.redraw = function(rootNode) {
    $.each($(rootNode).find('.panel'), (idx, val) => {
        $(val).css('height', '');
    });

    this.draw(rootNode);
};

PanelEqualizer.prototype.draw = function(rootNode) {
    if ($(rootNode).width() <= 0) {
        console.error('Wraping Container is corrupt');
        return false;
    }

    if ($(rootNode).find('.panel').length < 2) {
        console.error('Wrapping Container does not contain enough panels');
        return false;
    }

    const $list = $(rootNode).find('.panel'),
        colCount = Math.round($(rootNode).width() / $list.first().width(), 0);


    console.log('Columncount:', colCount);
    console.log('Last of us:', $list.last());

    const temp = [];
    let tHeight = 0;

    $.each($list, (idx, val) => {
        const nodeHeight = $(val).height();
        temp.push(nodeHeight);

        if ((idx + 1) % colCount === 0) {
            /* eslint-disable */
            temp[idx] = temp[idx - 1] = (nodeHeight > tHeight) ? nodeHeight : tHeight;
            /* eslint-enable */
            tHeight = 0;
        } else {
            tHeight = nodeHeight;
        }
    });

    for (let i = 0; i < temp.length; i++) {
        $($list[i]).height(temp[i]);
    }

    return true;
};

$.fn.panelEqualizer = function() {
    return this.each(function() {
        new PanelEqualizer(this);
    });
};
