/* global Hammer, window */


function Carousel(rootNode, opts) {
    this.rootNode = rootNode;
    this.index = 0;
    this.length = jQuery('.carousel__item', this.rootNode).length;
    this.$list = jQuery('.carousel__list', this.rootNode);
    this.$items = jQuery('.carousel__item', this.rootNode);
    this.$index = jQuery('.carousel__index', this.rootNode);
    this.isTripple = jQuery(this.rootNode).data('carousel-type') === 'tripple';
    this.enableTracking = opts.enableTracking;
    this.afterRotateCallback = opts.afterRotateCallback;

    this.resize();
    this.orderElements();
    this.resizePortraitImages();
    this.bindEvents();
}

Carousel.prototype.rotateDone = function() {
    if (typeof this.afterRotateCallback === 'function') {
        this.afterRotateCallback();
    }
};

Carousel.prototype.refreshList = function() {
    this.$items = jQuery('.carousel__item', this.rootNode);
};

Carousel.prototype.getIndex = function() {
    return this.index;
};

Carousel.prototype.bindEvents = function() {
    const that = this,
        hammertime = new Hammer(this.rootNode);


    function isIndexInRange(index) {
        return index >= 0 && index <= that.length - 1;
    }

    jQuery(window).on('resize load', () => {
        that.resize();
    });

    jQuery(window).on('refreshList', () => {
        window.console.log('refreshList');
        that.refreshList();
    });

    // touch events
    hammertime
        .get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 5, velocity: 0.3 });

    hammertime
        .on('swiperight', (e) => {
            e.preventDefault();
            if (isIndexInRange(that.index - 1)) {
                that.rotate(that.index -= 1);
            }
            that.track();
            window.dataLayer.push({
                event: 'galleryswipe',
                bildCount: that.index,
            });
        });

    hammertime
        .on('swipeleft', (e) => {
            e.preventDefault();
            if (isIndexInRange(that.index + 1)) {
                that.rotate(that.index += 1);
            }
            that.track();
            window.dataLayer.push({
                event: 'galleryswipe',
                bildCount: that.index,
            });
        });

    // click events
    jQuery(this.rootNode)
        .on('click', '.carousel__control--prev', (e) => {
            e.preventDefault();
            if (isIndexInRange(that.index - 1)) {
                that.rotate(that.index -= 1);
            }
            that.track();
            window.dataLayer.push({
                event: 'galleryclick',
                bildCount: that.index,
            });
        })
        .on('click', '.carousel__control--next', (e) => {
            e.preventDefault();
            if (isIndexInRange(that.index + 1)) {
                that.rotate(that.index += 1);
            }
            that.track();
            window.dataLayer.push({
                event: 'galleryclick',
                bildCount: that.index,
            });
        });
};

Carousel.prototype.track = function() {
    if (this.enableTracking) {
        if (typeof window.countIVW === 'function') {
            window.countIVW();
        }
        if (typeof window.countAT === 'function') {
            window.countAT();
        }
    }
};

Carousel.prototype.resize = function() {
    const $currentSlide = jQuery(this.$items[this.index]).children(':first-child');

    this.$list.stop(true, true).animate({
        height: $currentSlide.height() || '100px',
    });
};

Carousel.prototype.orderElements = function(newIndex) {
    if (this.isTripple && jQuery.utils.isDesktopByWidth()) {
        this.$items.removeClass('carousel__item--prev carousel__item--center carousel__item--next carousel__item--next-after carousel__item--prev-after');
        this.$items.eq(0).addClass('carousel__item--prev');
        this.$items.eq(1).addClass('carousel__item--center');
        this.$items.eq(2).addClass('carousel__item--next');
        this.$items.eq(3).addClass('carousel__item--next-after');
        jQuery(this.rootNode).find('.carousel__positions .carousel__index:nth-last-child(2)').css('display', 'none');
        jQuery(this.rootNode).find('.carousel__positions .carousel__index:nth-last-child(1)').css('display', 'none');
        jQuery(this.rootNode).find('.carousel__controls .carousel__control--prev').css('display', 'none');
    }
};

Carousel.prototype.rotate = function(newIndex) {
    function getIndex(index, length) {
        if (index === -2) {
            return length - 2;
        } if (index < 0) {
            return length - 1;
        } if (index === length) {
            return 0;
        } if (index > length) {
            return 1;
        }

        return index;
    }

    this.resize();

    if (this.isTripple && jQuery.utils.isDesktopByWidth()) {
        jQuery(this.rootNode).find('.carousel__controls .carousel__control--prev').removeAttr('style');
        if (newIndex + 3 <= this.length) {
            this.$items
                .removeClass('carousel__item--prev carousel__item--center carousel__item--next carousel__item--next-after carousel__item--prev-after')
                .eq(getIndex(newIndex - 1, this.length))
                .addClass('carousel__item--prev-after')
                .end()
                .eq(getIndex(newIndex + 1, this.length))
                .addClass('carousel__item--center')
                .end()
                .eq(newIndex)
                .addClass('carousel__item--prev')
                .end()
                .eq(getIndex(newIndex + 2, this.length))
                .addClass('carousel__item--next')
                .removeClass('carousel__item--center')
                .end()
                .eq(getIndex(newIndex + 3, this.length))
                .addClass('carousel__item--next-after')
                .removeClass('carousel__item--next')
                .end();
        }

        if (newIndex + 3 === this.length) {
            jQuery(this.rootNode).find('.carousel__controls .carousel__control--next').css('display', 'none');
        }

        if (newIndex + 3 < this.length) {
            jQuery(this.rootNode).find('.carousel__controls .carousel__control--next').removeAttr('style');
        }

        if (newIndex === 0) {
            jQuery(this.rootNode).find('.carousel__controls .carousel__control--prev').css('display', 'none');
        }
    } else {
        this.$items
            .removeClass('carousel__item--prev carousel__item--center carousel__item--next carousel__item--next-after carousel__item--prev-after')
            .eq(getIndex(newIndex - 1, this.length))
            .addClass('carousel__item--prev')
            .end()
            .eq(getIndex(newIndex + 1, this.length))
            .addClass('carousel__item--next')
            .end()
            .eq(newIndex)
            .addClass('carousel__item--center')
            .end()
            .eq(getIndex(newIndex + 2, this.length))
            .addClass('carousel__item--next-after')
            .removeClass('carousel__item--next')
            .end()
            .eq(getIndex(newIndex - 2, this.length))
            .addClass('carousel__item--prev-after')
            .removeClass('carousel__item--prev');

        if (newIndex + 1 === this.length) {
            jQuery(this.rootNode).find('.carousel__controls .carousel__control--next').css('display', 'none');
        }

        if (newIndex + 1 < this.length) {
            jQuery(this.rootNode).find('.carousel__controls .carousel__control--next').removeAttr('style');
        }

        if (newIndex === 0) {
            jQuery(this.rootNode).find('.carousel__controls .carousel__control--prev').css('display', 'none');
        }
        if (newIndex !== 0) {
            jQuery(this.rootNode).find('.carousel__controls .carousel__control--prev').removeAttr('style');
        }
    }

    this.$index
        .removeClass('carousel__index--active')
        .eq(newIndex)
        .addClass('carousel__index--active');

    this.rotateDone();
};

Carousel.prototype.resizePortraitImages = function() {
    const that = this;
    jQuery(window).on('load', () => {
        jQuery('.carousel__item', that.rootNode).css('display', 'block');
        const landscapeHeight = jQuery(that.rootNode).find('.medialandscape').find('img').height();
        jQuery('.carousel__item', that.rootNode).removeAttr('style');
        jQuery(that.rootNode).find('.mediaportrait').find('img').height(landscapeHeight);
    });
};

jQuery.fn.carousel = function(options) {
    return this.each(function() {
        const opts = jQuery.extend({
            enableTracking: false,
        }, options);

        if (!$.data(this, 'carousel')) {
            $.data(this, 'carousel', new Carousel(this, opts));
        }
    });
};
