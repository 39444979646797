/* jslint evil: true */
/* globals SmartBanner */


jQuery(() => {
    if (typeof SmartBanner !== 'undefined') {
        new SmartBanner({
            daysHidden: 30, // days to hide banner after close button is clicked (defaults to 15)
            daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
            appStoreLanguage: 'de', // language code for the App Store (defaults to user"s browser language)
            title: 'Hamburger Abendblatt News',
            author: 'Hamburger Abendblatt',
            button: 'Laden',
            store: {
                ios: 'Im Apple Store',
                android: 'Im Google Play Store',
                windows: 'Im Windows Store',
            },
            price: {
                ios: 'Gratis',
                android: 'Gratis',
                windows: 'Gratis',
            },
            // , theme: "" // put platform type ("ios", "android", etc.) here to force single theme on all device
            // , icon: "" // full path to icon image if not using website icon image
            // force: "android" // Uncomment for platform emulation
        });
    } else {
        console.debug('SmartBanner is not defined');
    }
});
