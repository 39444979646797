/* globals readCookie */
/* globals logout */


function LogoutLayer(rootNode, opts) {
    this.rootNode = rootNode;
    this.logoutLayer(rootNode, opts);
}

LogoutLayer.prototype.logoutLayer = function(rootNode, opts) {
    const that = this;
    if (readCookie('fpcacc') || readCookie('pcsi')) {
        jQuery('#header-top-abo').attr('href', '#logoutmodal');

        jQuery(rootNode).on('click', () => {
            jQuery('p.abo-logout__text').css('display', 'none');
            jQuery('button.abo-notlogout').css('display', 'none');
            jQuery('button.abo-logout').css('display', 'none');
            jQuery('p.abmeldung__erfolgreich').css('display', 'block');
            setTimeout(() => {
                jQuery('.remodal').fadeOut('fast');
                logout();
            }, 5000);
            jQuery('.header-top-abo').removeClass('header-top-abo-logged-in');
        });

        jQuery('.abo-notlogout').on('click', () => {
            jQuery('#header-top-abo').removeClass('header-top-abo--closed');
        });

        jQuery('.remodal-close').on('click', () => {
            jQuery('#header-top-abo').removeClass('header-top-abo--closed');
        });
    }
};

jQuery.fn.logoutLayer = function(opts) {
    return this.each(function() {
        new LogoutLayer(this, opts);
    });
};
