/**
 * Created by Vincent on 03-May-18.
 */


$(() => {
    // Simulate hover on touch menu
    $('.nav-main li.has-submenu a').on('touchstart touchend', function(e) {
        if (window.innerWidth >= 768) {
            const isSubmenu = ($(this).closest('.nav-li--level2').length !== 0 || $(this).closest('.nav-main__list--level2').length !== 0);

            if (!$(this).hasClass('hover-simulate') && !isSubmenu) {
                e.preventDefault();
                $('.hover-simulate').each(function () {
                    $(this).removeClass('hover-simulate');
                });
                $(this).closest('li.has-submenu').addClass('hover-simulate');
                $(this).addClass('hover-simulate');
            }
        }
    });

    // close menu on touch somewhere else if menu open
    $('body').on('touchstart touchend', (e) => {
        if (window.innerWidth >= 768) {
            const clickedOn = $(e.target),
                isMenu = clickedOn.parents().addBack().is('.nav-main');
            if (!isMenu) {
                const openMenu = $('.hover-simulate');
                if (openMenu.length) {
                    e.preventDefault();

                    openMenu.each(function () {
                        $(this).removeClass('hover-simulate');
                    });
                }
            }
        }
    });
});
