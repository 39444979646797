
/* Implementation of Inline Table Functions
    Author: Peter Marhewka
*/


function InlineTable(node) {
    const that = this;
    that.draw(node);
    $(node).on('redraw', () => {
        that.redraw(node);
    });
    $(node).off('click').on('click', '.inline-table-button-prev', () => {
        that.switchColum('prev', node);
    });
    $(node).on('click', '.inline-table-button-next', () => {
        that.switchColum('next', node);
    });
}

function InlineTableColSize(node) {
    const that = this;
    that.draw(node);
    $(node).on('redraw', () => {
        that.redraw(node);
    });
}

/*  Berechnung der Spaltenbreiten: In diesem Teil werden die Breiten der Spalten (Nur Desktop) berechnet. Dabei wird die erste Spalte
    über einen Faktor eine andere Breite zugewiesen. Diese Breite entspricht dann bei z.B.: Faktor 4 > 1/4 mehr als die anderen Spalten.
*/
InlineTableColSize.prototype.draw = function(rootNode) {
    const $columnCount = $(rootNode).find('tr').first().find('td').length,
        faktor = 0,
        $tableWidth = $(rootNode).width(),
        newWidthFirstCol = ($tableWidth / $columnCount + ($tableWidth / $columnCount) / faktor),
        newWidthCol = $tableWidth / $columnCount - ($tableWidth / $columnCount) / faktor / ($columnCount - 1);

    $(rootNode).find('table tr').first().find('td')
        .css('width', `${newWidthCol}px`);
    $(rootNode).find('table tr').first().find('td')
        .first()
        .css('width', `${newWidthFirstCol}px`);
};

InlineTable.prototype.draw = function(rootNode) {
    const $list = $(rootNode).find('td'),
        $columnCount = $(rootNode).find('tr').first().find('td').length,
        temp = [];

    console.log($columnCount);

    if ($columnCount <= 2) {
        $(rootNode).find('.inline-table-button-prev').css('display', 'none');
        $(rootNode).find('.inline-table-button-next').css('display', 'none');
    }

    $.each($list, (idx, val) => {
        const nodeElement = '1';
        temp.push(nodeElement);
    });

    for (let i = 0; i < temp.length; i++) {
        if ($($list[i]).is(':nth-child(2)')) {
            $($list[i]).addClass('inline-table-show');
        }
        if (!$($list[i]).is(':first-child') && !$($list[i]).is(':nth-child(2)')) {
            $($list[i]).addClass('inline-table-hidden');
        }
    }

    return true;
};

InlineTable.prototype.switchColum = function(direction, node) {
    if (direction === 'prev') {
        $(node).find('.inline-table-show').each(function(i) {
            if (!$(this).prev().attr('class')) {
                $(this).removeClass('inline-table-show').addClass('inline-table-hidden');
                $(node).find('tr').children(':last-child').removeClass('inline-table-hidden')
                    .addClass('inline-table-show');
            } else {
                $(this).removeClass('inline-table-show').addClass('inline-table-hidden');
                $(this).prev().removeClass('inline-table-hidden').addClass('inline-table-show');
            }
        });
    }

    if (direction === 'next') {
        $(node).find('.inline-table-show').each(function(i) {
            if ($(this).next().length !== 0) {
                $(this).removeClass('inline-table-show').addClass('inline-table-hidden');
                $(this).next().removeClass('inline-table-hidden').addClass('inline-table-show');
            } else {
                $(this).removeClass('inline-table-show').addClass('inline-table-hidden');
                $(node).find('tr').children(':nth-child(2)').removeClass('inline-table-hidden')
                    .addClass('inline-table-show');
            }
        });
    }
};

$.fn.inlineTable = function() {
    return this.each(function() {
        const mobileWidth = 767;
        if ($(window).width() <= mobileWidth) {
            new InlineTable(this);
        } else {
            new InlineTableColSize(this);
        }
    });
};
