/* Implementation of print view Functions
 Author: Matthias Klebe
 */


function Print(rootNode) {
    const that = this;

    this.$body = jQuery('body');
    this.$document = jQuery(document);

    jQuery(rootNode).on('click', () => {
        that.$body.fadeToggle('slow', () => {
            that.openPrint();
        });
    });

    if (!this.$document.data('print-initialized')) {
        this.initToolbar();
    }
}

Print.prototype.initToolbar = function () {
    const that = this;

    this.$document.data('print-initialized', true);

    this.$document.on('click', '.body__print .print-noimages', () => {
        that.hideImagesPrint();
    });

    this.$document.on('click', '.body__print .print-back', () => {
        that.$body.fadeToggle('slow', function () {
            jQuery(this).removeClass('body__print');
            jQuery('.print-bar').remove();
            jQuery('.print-header-adress').remove();

            that.closePrint();
        });
    });
};

Print.prototype.closePrint = function () {
    this.$body.fadeToggle('slow', function () {
        jQuery(this).removeAttr('style');
    });
};

Print.prototype.openPrint = function () {
    if (!this.$body.hasClass('body__print')) {
        const url = window.location.href,
            printBar = "<div class='print-bar'>"
    + "<div class='print-nav'><span class='print-noimages'>Bilder ausblenden</span>"
    + "<span class='print-page'> <a href='javascript:window.print();'>Seite drucken</a>"
    + "</span><span class='print-back'>zurück zum Artikel</span></div></div>";

        this.$body.addClass('body__print');

        jQuery('.page-wrapper').prepend(printBar);

        jQuery('.nav-main ').append(`${"<div class='print-header-adress'>Adresse dieses Artikels:"
   + "<a class='print-header-adress-link' href='"}${url}'>${url}</a></div>`);

        jQuery(window).scrollTop(0);

        this.$body.fadeToggle('slow', function () {
            jQuery(this).removeAttr('style');
        });
    }
};

Print.prototype.hideImagesPrint = function () {
    if (!this.$body.hasClass('body__print--imagehide')) {
        this.$body.addClass('body__print--imagehide');
        jQuery('.print-noimages').text('Bilder einblenden');
    } else {
        this.$body.removeClass('body__print--imagehide');
        jQuery('.print-noimages').text('Bilder ausblenden');
    }
};

jQuery.fn.print = function (options) {
    return this.each(function () {
        new Print(this, options);
    });
};
