

/* globals grecaptcha */


jQuery.fn.ajaxComments = function (options, recaptcha) {
    /* eslint-disable */
    return this.each(function () {
        const that = this,
            $that = jQuery(this),
            $form = jQuery('form', this),
            $commentsList = jQuery('#community-comments-list'),
            $submitLink = jQuery('a.submit', this),

            opts = jQuery.extend({
                before: null,
                context: document.body,
                fail: null,
                done: null,
                type: 'POST',
                url: '',
                reCaptcha: $that.find('.g-recaptcha').length,
            }, options);


        if ($that.find('.g-recaptcha').length > 0) {
            let data = jQuery(this).serializeArray();

            if (typeof opts.before === 'function') {
                data = opts.before.call(this, data);
            }

            jQuery.ajax({
                cache: false,
                context: opts.context,
                data,
                dataType: 'HTML',
                type: opts.type,
                url: opts.url,
            }).done((data, textStatus, jqXHR) => {
                const tempDom = jQuery('<div>').append(jQuery.parseHTML(data));
                if (!tempDom.find('.comment_success').length) {
                    $that.find('.error.comment_error').remove();
                    $that.prepend(data);
                } else if ($that.hasClass('comment__response-form')) {
                    $that.nextAll('.comment__answers.answers').find('ul').prepend(data).show();
                    $that.hide();
                    $that.trigger('reset');
                    $that.find('.error.comment_error').remove();
                } else {
                    $commentsList.prepend(data);
                    $that.html('');
                    jQuery(window).scrollTop(parseInt($that.offset().top) - 200);
                }

                if (typeof opts.done === 'function') {
                    opts.done.call();
                }
            }).fail((jqXHR, textStatus, failThrown) => {
                if (typeof opts.fail === 'function') {
                    opts.fail.call();
                }
            });

            return false;
        }


        $that.on('submit', 'form', function () {
            let data = jQuery(this).serializeArray();

            if (typeof opts.before === 'function') {
                data = opts.before.call(this, data);
            }

            jQuery.ajax({
                cache: false,
                context: opts.context,
                data,
                type: opts.type,
                url: opts.url,
            }).done((data, textStatus, jqXHR) => {
                $that.html(data);
                if (typeof opts.done === 'function') {
                    opts.done.call();
                }
            }).fail((jqXHR, textStatus, failThrown) => {
                if (typeof opts.fail === 'function') {
                    opts.fail.call();
                }
            });

            return false;
        });
        /* eslint-enable */

        if (!opts.reCaptcha) {
            $submitLink.on('click', function () {
                jQuery(this).closest('form').trigger('submit');
                return false;
            });

            jQuery('input[type=submit]', this).on('click', function () {
                jQuery('input[name=action]', that).val(jQuery(this).attr('name'));
            });
        } else {
            $form.trigger('submit');
        }
    });
};
