

function Navigation(rootNode, opts) {
    this.rootNode = rootNode;
    this.$navButton = $('.nav-main-toggle', this.rootNode);
    this.$nav = $('.nav-main, .search--header', this.rootNode);

    this.$submenuButtons = $('.nav-main__toggle-sub', this.rootNode);
    this.options = $.extend({
        iconClassOpen: null,
        iconClassClose: null,
        iconButtonCloseClass: 'nav-main-toggle--closed',
        navPrimaryActiveClass: 'visible',
        navSecondaryActiveClass: 'nav-main__list--level2--active',
    }, opts);

    // set default class for submenu buttons
    this.$submenuButtons.addClass(opts.iconClassOpen);

    this.bindEvents();
}


Navigation.prototype.bindEvents = function() {
    const that = this;

    // click on menu icon shows or hides first level nav
    $(this.rootNode)
        .on('click', '.nav-main-toggle', (e) => {
            e.preventDefault();
            that.onMenuToggle(that.options.navPrimaryActiveClass, that.options.iconButtonCloseClass);
        });

    // click on arrow left shows or hides second level nav
    $(this.rootNode)
        .on('click', '.nav-main__toggle-sub', function(e) {
            e.preventDefault();
            if ($(this).closest('li').hasClass('has-submenu')) {
                that.onSubMenuToggle(this, that.options.iconClassClose, that.options.iconClassOpen, that.options.navSecondaryActiveClass);
            }
        });

    $('.has-submenu a:first').on('touchstart', function (e) {
        const link = jQuery(this);
        if (link.hasClass('hover')) {
            return true;
        }

        link.addClass('hover');
        jQuery('.has-submenu a:first').not(this).removeClass('hover');
        e.preventDefault();
        return false;
    });
};

Navigation.prototype.onMenuToggle = function(navPrimaryActiveClass, iconButtonCloseClass) {
    this.$navButton.toggleClass(iconButtonCloseClass);
    this.$nav.toggleClass(navPrimaryActiveClass);

    if (jQuery('.nav-main').hasClass('visible')) {
        const windowWidth = $(window).width();

        if (windowWidth < 768) {
            const h = $(window).height();
            console.log(`Height: ${h}`);
            $('body').addClass('sticky--body');
            $('.nav-main').height(h - 80);
        }
    } else {
        $('.nav-main').removeAttr('style');
        $('body').removeClass('sticky--body');
    }
};

Navigation.prototype.onSubMenuToggle = function(callee, iconClassClose, iconClassOpen, navSecondaryActiveClass) {
    const $callee = $(callee),
        toggleButtons = $(callee).closest('.has-submenu').find('.nav-main__toggle-sub');

    $(toggleButtons)
        .toggleClass(function() {
            if ($(this).hasClass(iconClassClose)) {
                $(this).removeClass(iconClassClose);
                return iconClassOpen;
            }

            $(this).removeClass(iconClassOpen);
            return iconClassClose;
        });

    $callee.siblings("[class*='nav-main__list--level2']")
        .toggleClass(navSecondaryActiveClass);
};

$.fn.navigation = function(opts) {
    return this.each(function() {
        new Navigation(this, opts);
    });
};
