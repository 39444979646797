

function ScrollToTop(element, options) {
    this.settings = $.extend({
        callback: false,
    }, options);
    this.$element = $(element);
    this.callback = this.settings.callback;
    this.init();
}

$.extend(ScrollToTop.prototype, {
    init () {
        const that = this;

        this.$element.on('click', (e) => {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, 'fast', () => {
                if (typeof that.callback === 'function') {
                    that.callback.apply();
                }
            });
        });
    },
});

$.fn.scrollToTop = function (options) {
    return this.each(function () {
        if (!$.data(this, 'scrollToTop')) {
            $.data(this, 'scrollToTop', new ScrollToTop(this, options));
        }
    });
};
