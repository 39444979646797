/* Kaltura player sticky mode
   Make Kaltura player sticky if scrolling hits the top of the player.
*/
(function ($) {
    function KalturaPlayerStickyMode(rootNode, settings) {
        this.rootNode = rootNode;
        this.isSticky = false;
        this.isPlaying = false;
        this.isPaused = false;
        this.$player = $(this.rootNode);
        this.player = this.rootNode;
        this.$pageWrapper = $('.main').first();
        this.playerId = this.$player.attr('id');
        this.$dummyOverlay = this.$player.closest('.video').find('.kaltura_dummy_overlay');
        this.$placeholder = $(`<div id='${`placeholder-${this.playerId}`}'></div>`).addClass('video-placeholder');
        this.$closeButton = $(`<div id='${`player-fixed--close-${this.playerId}`}'></div>`).addClass('player-fixed--close');
        this.$playerTitle = $(`#${this.playerId}-title`);
        this.stickyDisabledByUser = false;
        this.isBildDerFrau = $('body').hasClass('pub-bdf');
        this.playerAspectRatio = 16/9;
        this.settings = $.extend({
            minimumSpaceLeft: 300,
            fixedElementSpace: 16,
            maxMobileWidth: 767,
        }, settings);
        this.iframeHead = this.$player.find('iframe').contents().find('head');
        this.playerStyles = this.iframeHead.find(`#stylePlayer_${this.playerId}`);
        this.playlistStyles = this.iframeHead.find(`#stylePlaylist_${this.playerId}`);

        if (typeof this.player.kBind === 'function' && typeof this.player.sendNotification === 'function') {
            this.init();
        } else {
            console.error('KalturaPlayerStickyMode plugin: The Kaltura video player is not working as expected.');
        }
    }

    KalturaPlayerStickyMode.prototype.init = function() {
        this.$player.append(this.$closeButton);
        this.$player.append(this.$playerTitle);
        this.$placeholder.insertBefore(this.$player);
        this.$placeholder.hide();
        this.$dummyOverlay.addClass('kaltura_dummy_overlay_with_controlbar');
        this.bindHandlers();
        this.refresh();

        $('<style/>', {
            type: 'text/css',
            id: `stylePlayer_${this.playerId}`,
        }).appendTo(this.iframeHead);

        $('<style/>', {
            type: 'text/css',
            id: `stylePlaylist_${this.playerId}`,
        }).appendTo(this.iframeHead);

        this.playerStyles = this.iframeHead.find(`#stylePlayer_${this.playerId}`);
        this.playlistStyles = this.iframeHead.find(`#stylePlaylist_${this.playerId}`);
    };

    KalturaPlayerStickyMode.prototype.bindHandlers = function() {
        this.player.kBind('userInitiatedPlay', () => {
            this.stickyDisabledByUser = false;
        });

        this.player.kBind('playerPlayed', () => {
            this.isPlaying = true;
            this.isPaused = false;
            this.refresh();
        });

        this.player.kBind('onAdPlay', () => {
            this.isPlaying = true;
            this.isPaused = false;
            this.refresh();
        });

        this.player.kBind('playerPaused', () => {
            this.isPaused = true;
            this.isPlaying = false;
            this.refresh();
        });

        this.player.kBind('playerPlayEnd', () => {
            this.playerPlaying = false;
            this.isPaused = false;
            this.refresh();
        });

        this.$closeButton.on('click', () => {
            this.stickyDisabledByUser = true;
            this.player.sendNotification('changeVolume', 0);
            this.removeSticky();
        });

        this.player.kBind('openFullScreen', () => {
            this.stickyDisabledByUser = true;
            this.removeSticky();
        });

        this.player.kBind('closeFullScreen', () => {
            // wait until the player is really closed
            window.setTimeout(() => {
                this.stickyDisabledByUser = false;
                this.refresh();
            }, 20);
        });

        $(window).on('scroll', () => {
            this.refresh();
        });

        $(window).on('resize', () => {
            this.refreshPlayerTransition();
            this.refresh(true);
        });
    };

    KalturaPlayerStickyMode.prototype.isMobile = function() {
        return $(window).width() <= this.settings.maxMobileWidth;
    };

    KalturaPlayerStickyMode.prototype.removeSticky = function() {
        this.hidePlaylist(false);
        this.$dummyOverlay.show();
        this.$playerTitle.hide();
        this.$player
            .removeClass('player-fixed player-fixed--mobile player-fixed--desktop player-fixed-hover video-transition')
            .removeAttr('style');

        this.$player.find('iframe')
            .css({
                minHeight: '100%',
            });
        this.$placeholder
            .removeClass('video-placeholder--styled')
            .hide();
        this.isSticky = false;

        /* Remove FDP Styles from Kaltura iFrame */
        this.playerStyles.text('FDP - No Change');
        this.playlistStyles.text('FDP - No Change');
    };

    KalturaPlayerStickyMode.prototype.makeSticky = function() {
        this.$placeholder.css({
            height: this.$player.height(),
        }).addClass('video-placeholder--styled');
        this.$dummyOverlay.hide();
        this.$player
            .addClass('player-fixed player-fixed--mobile player-fixed--desktop player-fixed-hover');
        this.$placeholder.show();
        this.refreshPlayerTransition();
        this.isSticky = true;
    };

    KalturaPlayerStickyMode.prototype.refreshPlayerTransition = function() {
        const spaceLeft = this.$pageWrapper[0].getBoundingClientRect().left,
            headerHeight = this.getHeaderHeight(),
            controlBarExtraHeight = $(`#${this.playerId}_ifp`).contents().find('.controlBarContainer').outerHeight(),
            spaceToContent = 50,
            playerStyles = '.unMuteOverlayButton span{display: none} .unMuteOverlayButton {width: auto}';

        this.$player.removeClass('player-fixed--mobile player-fixed--desktop');

        if (spaceLeft > this.settings.minimumSpaceLeft) {
            this.hidePlaylist(true, 'desktop');

            this.$player
                .addClass('player-fixed--desktop video-transition player-fixed')
                .css({
                    height: Math.round(((spaceLeft - spaceToContent) / this.playerAspectRatio) + controlBarExtraHeight),
                    width: Math.round(spaceLeft - spaceToContent),
                });

            this.$player.find('iframe')
                .css({
                    minHeight: Math.round(((spaceLeft - spaceToContent) / this.playerAspectRatio) + controlBarExtraHeight),
                });

            this.$playerTitle.hide();
            this.playerStyles.text(playerStyles);
        } else {
            this.hidePlaylist(true, 'mobile');
            this.$player
                .addClass('player-fixed--mobile video-transition player-fixed')
                .css({
                    left: spaceLeft,
                    top: headerHeight,
                });

            this.$playerTitle.show();
            this.playerStyles.text(playerStyles);
        }
    };

    KalturaPlayerStickyMode.prototype.hidePlaylist = function(el, uag) {
        let playlistStyles = '.mwPlayerContainer{height: 100% !important} .playlistAPI{display: none !important;}';

        if (uag === 'mobile') {
            playlistStyles = `${playlistStyles} .controlBarContainer{display: none !important;}`;
        }

        if (el === true) {
            this.playlistStyles.text(playlistStyles);
        } else {
            this.playlistStyles.text('FDP - No Change');
        }
    };

    KalturaPlayerStickyMode.prototype.isElementBelowViewportTop = function(el) {
        const $el = $(el),
            rect = $el[0].getBoundingClientRect(),
            threshold = $el.height() / 3;

        return (rect.top + threshold) >= 0;
    };

    KalturaPlayerStickyMode.prototype.getHeaderHeight = function() {
        if (this.isMobile()) {
            const $mobileHeader = $('.header-top'),
                headerPosition = $mobileHeader.css('position');

            if (headerPosition === 'fixed') {
                return $mobileHeader[0].getBoundingClientRect().height;
            }
        } else {
            const $header = $('.header');

            if (this.isBildDerFrau) {
                return $('.nav-main').height() + 10;
            }

            if ($header.hasClass('fixed') || $header.hasClass('header--sticky')) {
                return $header.height() + 5;
            }
        }

        return 0;
    };

    KalturaPlayerStickyMode.prototype.refresh = function(fullRefresh) {
        if (this.stickyDisabledByUser) {
            if (this.$player.hasClass('player-fixed')) {
                this.removeSticky();
            }

            return;
        }

        if (this.isPlaying || this.isPaused) {
            if ((fullRefresh || this.isSticky) && this.isElementBelowViewportTop(this.$placeholder)) {
                this.removeSticky();
            } else if ((fullRefresh || !this.isSticky) && !this.isElementBelowViewportTop(this.$player)) {
                this.makeSticky();
            }
        } else {
            this.removeSticky();
        }
    };

    jQuery.fn.kalturaPlayerStickyMode = function (opts) {
        return this.each(function () {
            new KalturaPlayerStickyMode(this, opts);
        });
    };
}(jQuery));
