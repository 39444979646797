/* Cookie Notification Banner */
/* Author: Peter Marhewka */


function CookieNotification(rootNode, opts) {
    this.rootNode = rootNode;
    this.init();
}

CookieNotification.prototype.init = function (opts) {
    const that = this;
    // Try to read acceptcookie if success with value "yes" remove Element else show Banner
    if (window.readCookie('acceptcookies') !== 'yes') {
        if (jQuery(this.rootNode).hasClass('cookie-note--middle')) {
            this.bannerPosition();
        }

        jQuery(this.rootNode).removeClass('hidden');

        // Create Cookie "acceptcookie with value "yes" and hide Element cookie expires after 14 days
        jQuery(this.rootNode).find('.cookie-note__button').on('click', () => {
            window.createCookie('acceptcookies', 'yes', 14);
            jQuery(that.rootNode).addClass('hidden');
        });
    }

    jQuery(window).on('resize', () => {
        if (jQuery(that.rootNode).hasClass('cookie-note--middle')) {
            that.bannerPosition();
        }
    });
};

CookieNotification.prototype.bannerPosition = function (opts) {
    const windowWidth = jQuery(window).width(),
        windowHeight = jQuery(window).height(),
        rootNodeWidth = jQuery(this.rootNode).width(),
        rootNodeHeight = jQuery(this.rootNode).height(),
        bannerLeftPosition = windowWidth / 2 - rootNodeWidth / 2,
        bannerTopPosition = windowHeight / 2 - rootNodeHeight / 2 - 30;

    jQuery(this.rootNode).css({
        left: bannerLeftPosition,
        top: bannerTopPosition,
    });
};

jQuery.fn.cookieNotification = function (opts) {
    return this.each(function () {
        new CookieNotification(this, opts);
    });
};
