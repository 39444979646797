/* Implementation of the StickyBar
    This Plugin implements a functionality like the Bootstrap-Affix plugin (http://getbootstrap.com/javascript/#affix).
    It toggles the Class of an given Element, when it leaves the Viewport so it cat be positioned fix via css.

    Author: Matthias Klebe

    @todo investigate / refactor for FDSUPPORT-5956 (paywall-container on mobile safari)
 */


function StickyContent(node) {
    const that = this,
        $node = $(node),
        contentHeight = $node.outerHeight(true),
        mobileWidth = 767,
        refElement = $('.article__body')[0];
    $(window).on('resize scroll touchend', function(e) {
        // on mobile devices there is no sticky content(.
        try {
            const socialbarHeight = $('.socialbar').height() * 2 + contentHeight,
                stickyStop = Math.round($node.closest('article').offset().top + $node.closest('article').height() - socialbarHeight);
            if ($(window).width() > mobileWidth && refElement !== undefined && $('.body__print').length === 0) {
                const scrollPosition = $(document).scrollTop();
                if (refElement.getBoundingClientRect().top < 0 && scrollPosition <= stickyStop) {
                    $node.addClass('sticky');
                    $node.fadeIn('0.5', 'linear');
                }
                if (refElement.getBoundingClientRect().top > 0) {
                    $node.removeAttr('style');
                    $node.removeClass('sticky');
                }
                if ($node.hasClass('sticky') && $(document).scrollTop() > (stickyStop + socialbarHeight / 2 - 15)) {
                    $node.addClass('solid').removeClass('sticky');
                    $node.css('left', '0    ');
                }
                if ($node.hasClass('solid') && $(document).scrollTop() <= (stickyStop + socialbarHeight / 2 - 15)) {
                    $node.removeClass('solid').addClass('sticky');
                }
            } else {
                $node.removeClass('sticky').removeClass('solid');
            }
            const offsetArticle = $('.article').offset();
            $('.sticky').css({
                left: offsetArticle.left - $(this).scrollLeft(),
            });
        } catch (err) {
            console.log('social-sticky-bar:', err.message);
        }
    });
}

$.fn.stickyContent = function() {
    return this.each(function() {
        new StickyContent(this);
    });
};
