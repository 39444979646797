
/* Implementation of Quiz Functions
    Author: Matthias Klebe
*/


function Quiz(rootNode) {
    this.result = [];
    this.$rootNode = $(rootNode);
    this.currentCount = 0;
    this.totalCount = this.$rootNode.find('.quiz__item').length;

    const that = this;

    this.bindEvents();
    this.updateUI();

    this.$rootNode.on('questionAnswered', (event, pickNode) => {
        that.result.push(that.evaluateAnswer(pickNode));
        that.updateUI(pickNode);
        that.showAnswer(pickNode);
    });

    // Whe the next page is triggert decide wether the next question or the result should be shown
    this.$rootNode.on('nextPage', () => {
        if (that.result.length === that.totalCount) {
            that.showResult(rootNode, that.result);
        } else {
            that.nextQuestion();
            that.currentCount++;
        }
    });
}

// Bind the UI-Events to the Quizlogic
Quiz.prototype.bindEvents = function() {
    const that = this;

    // Trigger the restart of the quiz
    this.$rootNode.on('click', '.quiz__restart', (e) => {
        e.preventDefault();
        that.restartQuiz();
    });

    // Trigger the next Page
    this.$rootNode.on('click', '.quiz-frage__next', () => {
        that.$rootNode.trigger('nextPage');
    });

    // Trigger the click on an answer
    this.$rootNode.on('click', '.quiz-answer', function() {
        if ($(this).closest('.lock').length === 0) {
            that.$rootNode.trigger('questionAnswered', [this]);
        }
    });
};

Quiz.prototype.showAnswer = function(pickNode) {
    const $pickNode = $(pickNode);

    if ($pickNode.find('.quiz-richtig').length > 0) {
        this.$rootNode
            .find('.quiz-antwort').removeClass('quiz-antwort--falsch').addClass('quiz-antwort--richtig')
            .find('p:first-child')
            .text('Das war richtig!');
        $pickNode.find('.quiz-richtig').addClass('quiz-richtig--selected');
    } else {
        this.$rootNode
            .find('.quiz-antwort').removeClass('quiz-antwort--richtig').addClass('quiz-antwort--falsch')
            .find('p:first-child')
            .text('Das war leider falsch!');
        $pickNode.find('.quiz-falsch').addClass('quiz-falsch--selected');
    }

    this.$rootNode.find('.quiz-antwort').slideToggle('fast');
};

// Update the result accourding the answer
Quiz.prototype.evaluateAnswer = function(pickNode) {
    return ($(pickNode).find('.quiz-richtig').length > 0) ? 1 : 0;
};

// Draw
Quiz.prototype.updateUI = function(pickNode) {
    $(pickNode).closest('.quiz__item').addClass('lock');

    if (this.totalCount === (this.currentCount + 1)) {
        this.$rootNode.find('.quiz-frage__next').text('zum Ergebnis');
    }
};

Quiz.prototype.restartQuiz = function() {
    window.location.reload();
};

Quiz.prototype.nextQuestion = function() {
    const $idxItem = this.$rootNode.find('.quiz__index').eq(this.currentCount),
        $questionItem = this.$rootNode.find('.quiz__item').eq(this.currentCount).hide().next()
            .show();

    this.$rootNode.find('.quiz__page').text(`Frage ${this.currentCount + 2} von ${this.totalCount}`);
    $idxItem.addClass('quiz__index--done').next().addClass('quiz__index--active');
    this.$rootNode.find('.quiz-antwort').hide();
    $questionItem.trigger('redraw');
};

Quiz.prototype.showResult = function() {
    const $resultList = this.$rootNode.find('.quiz-result__questions__item'),
        that = this;
    let rightCount = 0;

    this.$rootNode.find('.quiz__page').hide();
    this.$rootNode.find('.quiz__positions').hide();
    this.$rootNode.find('.quiz__list').hide();
    this.$rootNode.find('.quiz-form').show();

    for (let i = 0; i < this.result.length; i++) {
        rightCount += this.result[i];
    }

    const answerValue = 100 / this.totalCount,
        rightRelative = Math.round(answerValue * rightCount),
        wrongRelative = 100 - rightRelative;

    this.$rootNode.find('.quiz-result__bar--right').width(`${rightRelative}%`);
    this.$rootNode.find('.quiz-result__bar--wrong').width(`${wrongRelative}%`);
    this.$rootNode.find('.quiz-result__result').text(`${rightCount} von ${this.totalCount}`);

    $resultList.each((idx, value) => {
        $(value).addClass(that.result[idx] ? 'right' : 'wrong');
    });
};

$.fn.quiz = function() {
    return this.each(function() {
        new Quiz(this);
    });
};
