function FacebookInlineResizer(rootNode, opts) {
    this.rootNode = rootNode;
    this.facebookInlineBlockCheck(opts);
    this.rootNodeWidth = jQuery(this.rootNode).width();
}

FacebookInlineResizer.prototype.facebookInlineBlockCheck = function (opts) {
    const that = this,
        options = jQuery.extend({
            type: '',
        }, opts);

    if (options.type === 'socialEmbed') {
        this.facebookResizerSocialWidget();
    } else {
        that.facebookResizer();

        jQuery(window).on('resize', () => {
            const newRootNodeWidth = jQuery(that.rootNode).width();
            // window.console.log(jQuery(that.rootNode).attr("id"), that.rootNodeWidth, newRootNodeWidth);
            if (that.rootNodeWidth !== newRootNodeWidth) {
                jQuery(that.rootNode).removeClass(that.currentClass);
                jQuery(that.rootNode).find('style').remove();
                jQuery(that.rootNode).find('.fb-post').find('span').width(351);
                that.facebookResizer();
                that.rootNodeWidth = newRootNodeWidth;
            }

            const observerInterval = setInterval(() => {
                if (newRootNodeWidth === that.rootNodeWidth) {
                    clearInterval(observerInterval);
                }
            }, 2000);
        });
    }
};

FacebookInlineResizer.prototype.facebookResizer = function () {
    const that = this;

    // window.console.log(jQuery(that.rootNode).attr("id"), "Jetzt wird Facebook geresized");
    const inlineBlockWidthM = jQuery(that.rootNode).width(),
        randClass = `fb-width-${Math.floor((Math.random() * 10000) + 1)}`;

    this.currentClass = randClass;

    if (jQuery(this.rootNode).hasClass('social-facebook')) {
        // window.console.log(jQuery(that.rootNode).attr("id"), "Has social-facebook Class");
        jQuery(this.rootNode).addClass(randClass);
        jQuery(this.rootNode).prepend(`
            <style>
                .${randClass} iframe { 
                    width: ${inlineBlockWidthM}px !important
                } 
                
                .${randClass} .fb_iframe_widget {
                    width: ${inlineBlockWidthM}px !important; 
                    max-width: 1000px !important
                }
                
                .${randClass} .fb_iframe_widget span {
                    width:${inlineBlockWidthM}px !important; 
                    max-width: 1000px !important
                }
            </style>
        `);
        setTimeout(() => {
            // window.console.log(jQuery(that.rootNode).attr("id"), "Init XFBML");
            window.FB.XFBML.parse(document.getElementById(jQuery(that.rootNode).attr('id')));
        }, 200);
    }
};

FacebookInlineResizer.prototype.facebookResizerSocialWidget = function () {
    const that = this;
    let myCheckIntervallisRendered = false;
    this.facebookWidgetId = jQuery(this.rootNode).attr('id');
    this.rootNodeWidth = jQuery(this.rootNode).width();
    this.Random = Math.floor((Math.random() * 1000) + 300);

    this.myCheckIntervall = setInterval(() => {
        if (myCheckIntervallisRendered === false) {
            jQuery(document).trigger('myElementRendered');
        }
        if (myCheckIntervallisRendered === true) {
            clearInterval(that.myCheckIntervall);
        }
    }, 1000);

    // scale .fb-post when available width is smaller than 350px
    if (jQuery(that.rootNode).find('.fb-post').length) {
        if (this.rootNodeWidth > 350) {
            this.style = `#${this.facebookWidgetId} iframe {`
    + `width:${this.rootNodeWidth}px !important;`
    + '}'
    + `#${this.facebookWidgetId} span {`
    + `width:${this.rootNodeWidth}px !important;`
    + '}';
        } else {
            this.style = `#${this.facebookWidgetId} iframe {`
    + 'width: 350px !important;'
    + '}'
    + `#${this.facebookWidgetId} span {`
    + `width:${this.rootNodeWidth}px !important;`
    + '}';
        }

        jQuery(this.rootNode).append(`<style>${this.style}</style>`).ready(() => {
            window.FB.XFBML.parse(document.getElementById(that.facebookWidgetId));

            if (that.rootNodeWidth < 350) {
                const facebookScale = (100 / 350) * that.rootNodeWidth / 100;
                jQuery(that.rootNode).find('.fb-post').css({
                    '-webkit-transform-origin': '0 0',
                    '-moz-transform-origin': '0 0',
                    '-ms-transform-origin': '0 0',
                    '-o-transform-origin': '0 0',
                    'transform-origin': '0 0',
                    '-webkit-transform': `scale(${facebookScale}`,
                    '-moz-transform': `scale(${facebookScale}`,
                    '-ms-transform': `scale(${facebookScale}`,
                    '-o-transform': `scale(${facebookScale}`,
                    'transform': `scale(${facebookScale}`,
                });

                jQuery(document).on('myElementRendered', () => {
                    that.elementRenderData = 'fb-xfbml-state';
                    if (jQuery(that.rootNode).find('.fb-post').attr(that.elementRenderData) === 'rendered') {
                        if (myCheckIntervallisRendered === false) {
                            myCheckIntervallisRendered = true;
                            clearInterval(that.myCheckIntervall);

                            const dimensions = jQuery(that.rootNode).find('.fb-post')[0].getBoundingClientRect();
                            jQuery(that.rootNode).height(dimensions.height);
                        }
                    }
                });
            }
        });
    }
};

// init
jQuery.fn.facebookInlineResizer = function (opts) {
    return this.each(function () {
        new FacebookInlineResizer(this, opts);
    });
};

jQuery(window).on('load', () => {
    if (!jQuery('.inline-block--left').hasClass('social-embed')) {
        jQuery('.inline-block--left').facebookInlineResizer();
    }

    if (!jQuery('.inline-block--right').hasClass('social-embed')) {
        jQuery('.inline-block--right').facebookInlineResizer();
    }

    if (!jQuery('.inline-block--left-25').hasClass('social-embed')) {
        jQuery('.inline-block--left-25').facebookInlineResizer();
    }

    if (!jQuery('.inline-block--right-25').hasClass('social-embed')) {
        jQuery('.inline-block--right-25').facebookInlineResizer();
    }

    if (!jQuery('.inline-block--wide').hasClass('social-embed')) {
        jQuery('.inline-block--wide').facebookInlineResizer();
    }
});
