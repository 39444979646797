

jQuery(window).on('load', (e) => {
    // if (jQuery(window).width() > 767) {
    jQuery(window).on('scroll touchend', () => {
        const $header = jQuery('.header'),
            $breakingNewsElem = jQuery('.teaser--breaking-news');

        if ($breakingNewsElem.length) {
            if (jQuery(window).scrollTop() > $header.offset().top + $header.outerHeight()) {
                $header.addClass('fixed');
            } else {
                $header.removeClass('fixed');
            }
        }
    });
});
