/* jslint evil: true */
/* globals Swiper, ga */


function TrippleCarousel(rootNode, opts) {
    this.rootNode = rootNode;
    this.loadTrippleCarousel(opts);
}

TrippleCarousel.prototype.loadTrippleCarousel = function(opts) {
    const that = this,
        len = jQuery(this.rootNode).find('.swiper-slide').length,
        imageCount = 0;

    let smallestHeight = 0,
        portraitLog = 0;

    // Setup Slides (Desktop / Mobile) Slide Heights and Elements

    jQuery(this.rootNode).find('.swiper-slide').each(function(index) {
        const imgHeight = jQuery(this).find('img').attr('height'),
            imgWidth = jQuery(this).find('img').attr('width');

        if (imgHeight > imgWidth || imgHeight === imgWidth) {
            jQuery(this).find('picture').addClass('mediaportrait');
            portraitLog += 1;
        } else {
            jQuery(this).find('picture').addClass('medialandscape');
        }

        if (imgHeight > smallestHeight && (!jQuery(this).find('picture').hasClass('mediaportrait'))) {
            smallestHeight = imgHeight;
            console.log(smallestHeight);
        }

        if (opts.view === 'desktop') {
            if (portraitLog === len) {
                const gallerieWidth = jQuery(that.rootNode).width() / 1.5;
                console.log(`gallerieWidth${gallerieWidth}`);
                jQuery(that.rootNode).find('picture').css('max-height', `${gallerieWidth}px`).css('overflow', 'hidden');
                jQuery(that.rootNode).find('picture').find('img').height(gallerieWidth)
                    .css('width', 'auto');
                jQuery(that.rootNode).find('.swiper-buttons').height(gallerieWidth).css('display', 'block');
            } else {
                jQuery(that.rootNode).find('picture').css('max-height', `${smallestHeight}px`).css('overflow', 'hidden');
                jQuery(that.rootNode).find('.swiper-buttons').height(smallestHeight).css('display', 'block');

                if (jQuery(that.rootNode).find('picture').hasClass('mediaportrait')) {
                    jQuery(that.rootNode).find('.mediaportrait').find('img').height(smallestHeight)
                        .css('width', 'auto');
                }
            }
        }

        if (index + 1 === len) {
            that.setupTrippleCarousel(opts, imageCount);
        }
    });
};

TrippleCarousel.prototype.setupTrippleCarousel = function(opts, imageCount) {
    const that = this,
        thisSwiperId = jQuery(this.rootNode).attr('id'),
        paginationClass = `.swiper-pagination-${thisSwiperId}`,
        nextClass = `.swiper-button-next-${thisSwiperId}`,
        prevClass = `.swiper-button-prev-${thisSwiperId}`;

    let thisSwiper = thisSwiperId;


    if (opts.view === 'desktop') {
        thisSwiper = new Swiper(`#${thisSwiperId}`, {
            pagination: paginationClass,
            grabCursor: true,
            nextButton: nextClass,
            prevButton: prevClass,
            preloadImages: true,
            paginationClickable: true,
            slidesPerView: 3,
            spaceBetween: 30,
            loop: true,
            autoplay: 3000,
            setWrapperSize: true,
        });
    }

    if (opts.view === 'mobile') {
        thisSwiper = new Swiper(`#${thisSwiperId}`, {
            onInit() {
                jQuery(that.rootNode).find('article.teaser').css('display', 'block');
            },
            pagination: paginationClass,
            effect: 'cube',
            grabCursor: true,
            lazyLoading: false,
            preloadImages: false,
            paginationClickable: true,
            updateOnImagesReady: true,
            cube: {
                shadow: false,
                slideShadows: false,
                shadowOffset: 20,
                shadowScale: 0.94,
            },
            loop: true,
            autoplay: 3000,
            setWrapperSize: true,
        });
    }
};


jQuery.fn.trippleCarousel = function(opts) {
    return this.each(function() {
        new TrippleCarousel(this, opts);
    });
};
