/* jslint evil: true */

/* globals Swiper, ga */


function CarouselAjax(rootNode, opts) {
    this.rootNode = rootNode;
    this.$rootNode = jQuery(this.rootNode);
    this.staticHeight = 0;

    // EXPAND OPTS
    this.ajax = opts.ajax;
    this.autoplay = opts.autoplay;
    this.autoplayDuration = opts.autoplayDuration;
    /* Autoplay time integer / if not defined no Autoplay */
    this.centeredSlides = opts.centeredSlides;
    this.cube = opts.cube;
    this.countPrefix = opts.countPrefix || '';
    this.countSplitter = opts.countSplitter || '/';
    this.direction = opts.direction;
    this.loop = opts.loop;
    this.mousewheelControl = opts.mousewheelControl;
    this.paginationType = opts.paginationType;
    this.pageCount = opts.pageCount;
    /* true / false / undefined */
    this.slidesPerView = opts.slidesPerView;
    this.spaceBetween = opts.spaceBetween;
    this.start = opts.start;
    this.initialSlide = opts.initialSlide;
    /* Start slide integer */
    this.slidesPerColumn = opts.slidesPerColumn;
    this.view = opts.view;
    this.watchSlidesVisibility = true;
    this.paginationOnImageBottom = opts.paginationOnImageBottom;
    this.dfpAdHandlingEnabled = typeof opts.dfpAdHandlingEnabled === 'boolean' ? opts.dfpAdHandlingEnabled : true;
    this.dfpGalleryAds = opts.dfpGalleryAds;
    this.dfpGlobalAdRefresh = opts.dfpGlobalAdRefresh;
    this.outbrainRecommendationsOnendEnabled = opts.outbrainRecommendationsOnendEnabled;
    this.preloadImages = opts.preloadImages || false;
    this.updateOnImagesReady = opts.updateOnImagesReady || false;
    this.galleryTitle = opts.galleryTitle || false;
    this.minImageHeight = 200;

    if (this.slidesPerView !== 1) {
        this.autoHeight = false;
    } else {
        this.autoHeight = true;
    }

    if (this.autoplay !== true) {
        this.autoplayDuration = 0;
    }

    // Develop
    // this.slidesPerGroup = 2;

    this.setupSwiper();
    this.count = 2;
}

// SETUP SWIPER

CarouselAjax.prototype.setupSwiper = function () {
    const that = this,

        swiperInstanceId = that.$rootNode.attr('id'),
        paginationClass = `.swiper-pagination-${swiperInstanceId}`,
        nextClass = `.swiper-button-next-${swiperInstanceId}`,
        prevClass = `.swiper-button-prev-${swiperInstanceId}`;

    if (that.slidesPerView > 1) {
        that.$rootNode.addClass('multi-columns');
    }

    if (that.slidesPerView === 3 && that.view === 'mobile') {
        that.slidesPerView = 1;
        that.start = 1;
    }

    if (that.slidesPerView === 2 && that.view === 'mobile' && that.cube === false && that.ajax === false) {
        that.slidesPerView = 2;
        that.start = 1;
    }

    if ((that.view === 'desktop') || (that.view === 'mobile' && that.cube === false)) {
        that.swiperInstance = new Swiper(that.rootNode, {
            nextButton: nextClass,
            pagination: paginationClass,
            paginationType: that.paginationType,
            prevButton: prevClass,
            preloadImages: that.preloadImages,
            updateOnImagesReady: that.updateOnImagesReady,
            lazyLoading: true,
            lazyLoadingInPrevNext: true,
            lazyLoadingInPrevNextAmount: 2,
            lazyLoadingOnTransitionStart: true,
            paginationClickable: true,
            spaceBetween: that.spaceBetween,
            slidesPerView: that.slidesPerView,
            slidesPerColumn: that.slidesPerColumn,
            slidesPerGroup: that.slidesPerGroup,
            watchSlidesVisibility: that.watchSlidesVisibility,
            autoplay: that.autoplayDuration,
            direction: that.direction,
            initialSlide: that.initialSlide,
            grabCursor: true,
            loop: that.loop,
            mousewheelControl: that.mousewheelControl,
            roundLengths: true,
            autoHeight: that.autoHeight,
            paginationOnImageBottom: false,
            zoom: false,
            runCallbacksOnInit: true,
            onTransitionEnd() {
                // hide or show pagecounter depending on slide image or slide ad

            },
        });
    }

    if (that.view === 'mobile' && that.cube === true) {
        that.swiperInstance = new Swiper(that.rootNode, {
            effect: 'cube',
            cube: {
                shadow: false,
                slideShadows: false,
                shadowOffset: 0,
                shadowScale: 0,
            },
            lazyLoading: false,
            lazyLoadingInPrevNext: false,
            lazyLoadingInPrevNextAmount: 2,
            lazyLoadingOnTransitionStart: false,
            pagination: paginationClass,
            preloadImages: true,
            paginationType: that.paginationType,
            paginationClickable: true,
            setWrapperSize: false,
            watchSlidesVisibility: that.watchSlidesVisibility,
            autoplay: that.autoplayDuration,
            grabCursor: true,
            loop: that.loop,
            autoHeight: false,
            paginationOnImageBottom: false,
            runCallbacksOnInit: true,
        });
    }

    // pagecount manipulation:
    // GPT Ads and Outbrain Recommendations don't appear as separate slides for the user, only for the swiper's logic
    if (that.view === 'mobile' || that.view === 'desktop') {
        that.gptAdCount = that.$rootNode.find('.swiper-slide-ad').not('.swiper-slide-duplicate').length;
        that.outbrainAdCount = that.$rootNode.find('.swiper-slide-outbrain').not('.swiper-slide-duplicate').length;
        that.imageCount = that.$rootNode.find('.swiper-pagination').find('.swiper-pagination-bullet').length;
        that.imageCountSubAdCount = that.imageCount - that.gptAdCount - that.outbrainAdCount;

        // set up the data-swiper-slide-index attribute, starting by 1
        // the ad slides and outbrain slides will get the slideIndexes from the previous slides
        let adOrOutbrainSlideCount = 0;
        that.$rootNode.find('.swiper-slide').not('.swiper-slide-duplicate').each(function (idx) {
            const $this = jQuery(this);

            if ($this.hasClass('swiper-slide-ad') || $this.hasClass('swiper-slide-outbrain')) {
                adOrOutbrainSlideCount++;
            }
            $this.attr('data-swiper-slide-index', (idx + 1 - adOrOutbrainSlideCount));
        });

        // what this basically does:
        // if loop is enabled, index of the first regular slide will be copied to the duplicate right slide,
        // and the index of the last regular slide will be copied to the duplicate left slide
        if (that.loop) {
            that.$rootNode.find('.swiper-slide:first-child').attr('data-swiper-slide-index', that.imageCountSubAdCount);
            that.$rootNode.find('.swiper-slide:last-child').attr('data-swiper-slide-index', 1);
        }

        if (!that.$rootNode.hasClass('container-leader')) {
            if (!that.$rootNode.find('.swiper-pagecount').length && that.pageCount !== false) {
                that.$rootNode.append(`<div class="swiper-pagecount">${that.countPrefix} 1 ${that.countSplitter} ${that.imageCountSubAdCount}</div>`);
            }
        }
    }

    that.setDataImageHeight();

    if (that.$rootNode.parent().hasClass('inline-block--right-25') || that.$rootNode.parent().hasClass('inline-block--right') || that.$rootNode.parent().hasClass('inline-block--left-25') || that.$rootNode.parent().hasClass('inline-block--left')) {
        that.$rootNode.find('.swiper-slide-ad').remove();
        that.swiperInstance.update();
    }

    that.$rootNode.addClass('swiper-loaded');

    that.swiperTransitions(that.swiperInstance);
};

// SWIPER TRANSITIONS

CarouselAjax.prototype.swiperTransitions = function () {
    const that = this;

    let swiperWidth = that.$rootNode.width();

    that.currentSlideId = that.$rootNode.find('.swiper-slide-visible').attr('data-hash');

    // Autoplay Swiper
    try {
        if (that.autoplay === true) {
            that.swiperInstance.startAutoplay();
        } else {
            that.swiperInstance.stopAutoplay();
        }
    } catch (err) {
        window.console.log(err.message);
    }

    // ######## CALLBACKS ########

    // Get swipe direction
    that.swiperInstance.on('onSlideNextStart', () => {
        that.swipeTo = 'next';
        // window.console.log("SWIPE NEXT");
    });

    that.swiperInstance.on('onSlidePrevStart', () => {
        that.swipeTo = 'prev';
        // window.console.log("SWIPE Prev Start");
    });

    // Transition Start
    that.swiperInstance.on('onTransitionStart', () => {
        that.currentSlideId = that.$rootNode.find('.swiper-slide-visible').attr('data-hash');

        if (that.$rootNode.find('.swiper-slide-visible').attr('data-adrefresh')) {
            // #### Refresh Ads use Widxget settings ####
            if (that.dfpGalleryAds !== 0) {
                that.adsGalleryReload();
                // window.console.log("dfpGalleryAds Running");
            }
        }

        that.refreshPageCount();
        that.arrowPosition();
    });

    // TRACKING
    // Transition End
    that.swiperInstance.on('onTransitionEnd', () => {
        that.currentSlideId = that.$rootNode.find('.swiper-slide-visible').attr('data-hash');

        try {
            const link = that.$rootNode.find('.swiper-slide-visible').find('img').attr('srcset'),
                slideindex = that.$rootNode.find('.swiper-slide-visible').data('swiper-slide-index');

            // srcset fix for IE (FDC-1532)
            if (window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./) || (navigator.userAgent.indexOf('Safari') > -1)) {
                const imageElement = that.$rootNode.find('.swiper-slide-visible').find('img');

                if (imageElement.attr('src').indexOf('/placeholder.png') >= 0) {
                    let srcElement = link.substr(0, link.lastIndexOf(' '));
                    srcElement = srcElement.substr(srcElement.lastIndexOf(' ') + 1, srcElement.length);
                    imageElement.attr('src', srcElement);
                }
            }

            ga('send', 'event', `Bildergalerie - Bild - ${that.view}, swipe`, link);
            that.ivwTracking();
            that.reloadAds();

            window.dataLayer.push({
                event: 'galleryclick',
                bildCount: slideindex,
                gallerytitle: that.galleryTitle,
            });
            // window.console.log("transistion ended index" + slideindex);
        } catch (err) {
            // window.console.log("Google Analytics integrieren: " + err.message);
        }

        // Set Current Slide Id to Slider and Trigger data-current-slide function in pin-it.js (BDF only) Function for Pinterest

        try {
            that.$rootNode.attr('data-current-slide', that.currentSlideId);
            jQuery(document).trigger('data-current-slide');
        } catch (err) {
            window.console.log(`There is no data-current-slide function: ${err.message}`);
        }

        if (that.dfpGlobalAdRefresh !== false) {
            that.adsGlobalReload();
            // window.console.log("adsGlobalReload Running");
        }
    });

    that.swiperInstance.on('onLazyImageReady', () => {
        that.swiperInstance.update();
        that.arrowPosition();
        // window.console.log("Image was loaded");
    });

    // Reinit Swiper Setup if Swiper width change before window load.
    const refreshIntervalSwiperWidth = setInterval(() => {
        // window.console.log("Swiper run Interval SwiperWidth", swiperWidth, "!==", that.$rootNode.width());
        if (swiperWidth !== that.$rootNode.width()) {
            // window.console.log("#### Swiper Resize SwiperWidth ####");
            that.setupSwiper();
            that.swiperInstance.update();
            swiperWidth = that.$rootNode.width();
        }
    }, 1000);

    jQuery(window).on('load', () => {
        clearInterval(refreshIntervalSwiperWidth);
    });
};

CarouselAjax.prototype.refreshPageCount = function () {
    const $currentSlide = this.$rootNode.find('.swiper-slide-visible'),
        $pageCount = this.$rootNode.find('.swiper-pagecount'),
        slideIndex = $currentSlide.attr('data-swiper-slide-index'),
        hidePageCount = $currentSlide.is('.swiper-slide-outbrain, .swiper-slide-ad');

    $pageCount.text(`${this.countPrefix} ${slideIndex} ${this.countSplitter} ${this.imageCountSubAdCount}`);
    $pageCount.css('visibility', hidePageCount ? 'hidden' : 'visible');
};

/**
 * Set min-height for each image in relation to rootNode width
 */
CarouselAjax.prototype.setDataImageHeight = function () {
    const that = this,
        defaultHeightRatio = 3 / 4,
        slideElements = that.$rootNode.find('.swiper-slide'),
        slideElementLength = slideElements.length;

    slideElements.each((idx, slideElem) => {
        const $slideElem = jQuery(slideElem);

        slideElem.slideWidth = $slideElem.width();
        slideElem.slideHeight = $slideElem.height();
        slideElem.imageNewHeight = slideElem.slideWidth * defaultHeightRatio;
        slideElem.imageWidth = parseInt($slideElem.find('img').attr('width'), 10);
        slideElem.imageHeight = parseInt($slideElem.find('img').attr('height'), 10);
        // window.console.log(slideElem.slideWidth, slideElem.imageWidth, slideElem.imageHeight, slideElem.slideHeight);

        // Landscape & Portrait image Ratio
        if (slideElem.imageWidth > slideElem.imageHeight) {
            slideElem.ratio = slideElem.imageWidth / slideElem.imageHeight;
            slideElem.maxWidth = '100%';
        } else {
            slideElem.ratio = slideElem.imageHeight / slideElem.imageWidth;
            slideElem.maxWidth = `${Math.round(slideElem.slideWidth / slideElem.ratio, 10)}px`;
        }

        if ((that.view === 'desktop')) {
            that.minImageHeight = slideElem.imageHeight;
        }

        $slideElem.find('img').css({
            minHeight: `${that.minImageHeight}px`,
            maxHeight: `${slideElem.imageNewHeight}px`,
            maxWidth: slideElem.maxWidth,
        });

        if (!isNaN(slideElem.imageNewHeight)) {
            $slideElem.attr('data-image-height', slideElem.imageNewHeight);
        }

        if (idx === slideElementLength - 1) {
            that.arrowPosition();
        }
    });
};

CarouselAjax.prototype.adsGalleryReload = function () {
    const that = this;

    if (that.dfpAdHandlingEnabled) {
        if (that.$rootNode.find('.swiper-slide-visible').hasClass('swiper-slide-ad')) {
            const $swiperButtons = that.$rootNode.find('.swiper-buttons');

            // Disable Swiping for 1500ms wenn Werbung angezeigt wird
            that.swiperInstance.lockSwipes();
            $swiperButtons.css({ opacity: 0.25 });

            setTimeout(() => {
                that.swiperInstance.unlockSwipes();
                $swiperButtons.css({ opacity: '' });
            }, 1500);
        }

        // Find next or prev Ad Container
        that.adRefresh = that.$rootNode.find('.swiper-slide-visible');

        if (that.adRefresh && that.$rootNode.find('.swiper-slide-active')) {
            console.log('slide active && visible');
            // Trigger adRefresh in jsp
            // In der jsp wird eine Javascript Variable mit Funktion genneriert. Der folgende Code ruft diese Variable auf und triggert damit den Refresh bzw. initialisiert das Ad.
            const gId = that.adRefresh.attr('data-gId');
            const elAd = document.getElementById(`gAds-${gId}`); // find once loaded element that contains  included Ads
            const slotIdParts = that.adRefresh.attr('data-hash').split('-', 2);
            const isSparkAd = $(elAd).find('.carousel-spark-ad').length > 0;

            try {
                let slotId;
                elAd.style.display = 'flex';
                that.adRefresh.append(elAd);

                if (slotIdParts.length === 2) {
                    slotId = `${slotIdParts[0]}-${slotIdParts[1]}`;
                } else {
                    slotId = `${slotIdParts[0]}`;
                }

                if (isSparkAd) {
                    const refreshEvent = new CustomEvent('refresh-carousel-spark-ad', {
                        detail: {
                            slot: slotId,
                        },
                    });
                    document.dispatchEvent(refreshEvent);
                } else {
                    adRefresh(slotId, gId);
                }
            } catch (e) {
                window.console.log('Ads could not be displayed!', e);

                // Slide automatisch zum nächsten Slide wenn keine Werbung ausgespielt wird.
                const $swiperButtons = that.$rootNode.find('.swiper-buttons');

                // Disable Swiping for 1500ms wenn Werbung angezeigt wird
                that.swiperInstance.lockSwipes();
                $swiperButtons.css({ opacity: 0.25 });

                setTimeout(() => {
                    that.swiperInstance.unlockSwipes();
                    $swiperButtons.css({ opacity: '' });
                }, 1500);

                // Slide automatisch zum nächsten Slide wenn keine Werbung ausgespielt wird.
                // unlock swiper lock that.swiperInstance.unlockSwipes();
                if (that.swipeTo === 'next') {
                    that.swiperInstance.unlockSwipes();
                    $swiperButtons.css({ opacity: '' });
                    that.swiperInstance.slideNext(false, 200);
                    that.refreshPageCount();
                }

                if (that.swipeTo === 'prev') {
                    that.swiperInstance.unlockSwipes();
                    $swiperButtons.css({ opacity: '' });
                    that.swiperInstance.slidePrev(false, 200);
                    that.refreshPageCount();
                }
            }

            // Ad Container initialize
            try {
                if (that.adRefresh.find('.ad--marker').length && that.adRefresh.find('.ad--marker').attr('style') && that.adRefresh.find('.ad--marker').attr('style').indexOf('display') !== -1) {
                    if (!that.adRefresh.hasClass) {
                        that.adRefresh.find('.ad--marker').removeAttr('style');
                    }
                }
            } catch (err) {
                window.console.log(`There is a bug with ads: ${err.message}`);
            }
        } else {
            elAd.style.display = 'none';
        }
    }
};

CarouselAjax.prototype.adsGlobalReload = function () {
    const that = this;

    if (that.dfpAdHandlingEnabled) {
        that.gptAdCount = that.$rootNode.find('.swiper-slide-ad').length;

        if (that.globalRandomCounter === undefined) {
            that.globalRandomCounter = 1;
        } else {
            that.globalRandomCounter += 1;
        }

        try {
            // Global Ad randomRefresh "var gptAdSlots = [] definiert in stroer_define.jsp.
            // Der Random Refresh wird nur alle adRandomRefreshAfter ausgeführ und nur auf ein random Banner
            // Refresh random globalAd Slot each from Widget Slides
            // Refresh Superbanner and Skyscraper if one of them start refresh

            const globalAdRandomRefresh = Math.floor(Math.random() * that.gptAdSlots);

            if (that.globalRandomCounter >= that.dfpGlobalAdRefresh) {
                that.gptAdSlots = window.gptAdSlots.length;

                if (that.dfpGlobalAdRefresh !== false && that.autoplay === false && !isNaN(globalAdRandomRefresh)) {
                    if (globalAdRandomRefresh === 0) {
                        if (!jQuery('#omsv_sky_DhtmlLayer').length) {
                            window.adRefresh(globalAdRandomRefresh);
                            window.adRefresh(1);
                            // window.console.log('Fire refresh global Ad Slot', globalAdRandomRefresh, 1);
                        } else {
                            jQuery('#oms_gpt_skyscraper').css({ display: 'none' });
                        }
                    } else if (globalAdRandomRefresh === 1) {
                        if (!jQuery('#omsv_sky_DhtmlLayer').length) {
                            window.adRefresh(globalAdRandomRefresh);
                            window.adRefresh(0);
                            // window.console.log('Fire refresh global Ad Slot', globalAdRandomRefresh, 0);
                        } else {
                            jQuery('#oms_gpt_skyscraper').css({ display: 'none' });
                        }
                    } else {
                        window.adRefresh(globalAdRandomRefresh);
                        // window.console.log('Fire refresh global Ad Slot', globalAdRandomRefresh);
                    }
                    that.globalRandomCounter = undefined;
                }
            }
        } catch (err) {
            window.console.log(`There is a bug with ads: ${err.message}`);
        }
    }
};

/**
 * calculates and defines the arrow position. the arrows are intended to be positioned in the vertical middle of the *image*,
 * as opposed to the vertical middle of the whole slide. the whole slide could contain a caption in addition to the image.
 *
 * - if the currently active slide contains a dfp ad or outbrain recommendations, all css styles are removed
 * - else the currently active's slide's image height will be considered
 */
CarouselAjax.prototype.arrowPosition = function () {
    const that = this,

        $swiperButtons = that.$rootNode.find('.swiper-buttons');

    if (that.slidesPerView === 1) {
        const $activeSlide = that.$rootNode.find('.swiper-slide-visible'),
            $buttonNext = that.$rootNode.find('.swiper-button-next');

        if ($activeSlide.hasClass('swiper-slide-ad') || $activeSlide.hasClass('swiper-slide-outbrain')) {
            $swiperButtons.removeAttr('style');
        } else {
            const top = (parseInt($activeSlide.find('img').height(), 10) / 2) - ($buttonNext.height() / 2);
            $swiperButtons.css({
                top: Math.round(top, 10),
            });
        }
    } else {
        $swiperButtons.removeAttr('style');
    }
};

CarouselAjax.prototype.paginationPosition = function () {
    const that = this;

    if (that.paginationOnImageBottom) {
        const activeImageHeight = (parseInt(that.$rootNode.find('.swiper-slide-visible').find('img').height(), 10)),
            activeImageWidth = (parseInt(that.$rootNode.find('.swiper-slide-visible').find('img').width(), 10)),
            pagination = that.$rootNode.find('.swiper-pagination'),
            paginationHeight = parseInt(pagination.height(), 10);

        pagination.css({
            top: activeImageHeight - paginationHeight,
            bottom: 'inherit',
            maxWidth: activeImageWidth,
        });

        if (activeImageHeight === 0 || activeImageHeight < 0) {
            pagination.removeAttr('style');
        }
    }
};

CarouselAjax.prototype.reloadAds = function () {
    const that = this,
        changeAfter = 5;

    if (that.count === changeAfter) {
        that.count = 0;
        // Asmi
        window.sas_callAds();
        // window.console.log("AdCounter, " + that.count);
    }

    that.count += 1;
};

CarouselAjax.prototype.ivwTracking = function () {
    // console.log("invokePi()", typeof(window.countIVW));
    if (typeof (window.countIVW) === 'function') {
        try {
            // window.console.log("will call");
            window.countIVW();
        } catch (exp) {
            // window.console.log("Error invoking page impression: ", exp);
        }
    }
};

jQuery.fn.carouselAjax = function (opts) {
    return this.each(function () {
        new CarouselAjax(this, opts);
    });
};

function initCarousels() {
    jQuery('.carousel-ajax').each(function () {
        const $this = jQuery(this);
        if (!$this.hasClass('swiper-loaded')) {
            $this.carouselAjax($this.data('options'));
        }
    });
}

jQuery(() => {
    initCarousels();
    jQuery(document).on('paywall-deobfuscation-complete', initCarousels);
    jQuery(document).ajaxStop(initCarousels);
});
