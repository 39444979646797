

function CompareSlider(rootNode, opts) {
    this.rootNode = rootNode;
    this.init();
    this.caption('right');
    this.resize();
    // window.dataLayer.push({
    //  "event": "slider_klick"
    // });
}

CompareSlider.prototype.init = function (opts) {
    const that = this,
        parentOffset = jQuery(this.rootNode).find('.compare-slider--wrapper').offset(),
        wrapperWidth = jQuery(this.rootNode).find('.compare-slider--wrapper').width();

    // Responsive min-width for Image. important!

    jQuery(this.rootNode).find('.compare-slider--img-left img').css({
        minWidth: `${wrapperWidth}px`,
        maxWidth: `${wrapperWidth}px`,
    });

    // Ad move Class if Slide Line clicked or touched

    jQuery(this.rootNode).find('.compare-slider--button').on('mousedown touchstart', function (e) {
        // window.console.log("TEST", jQuery(that.rootNode).attr("id"), e.type);
        jQuery(this).addClass('move');
        jQuery(this).removeClass('animate');
        jQuery(that.rootNode).find('.compare-slider--img-left').removeClass('animate');
    }).on('mouseup touchend', function () {
        jQuery(this).removeClass('move').removeClass('animate');
    });

    // Slide Line move. Set Position of Slide Line and width of img-left container

    jQuery(this.rootNode).find('.compare-slider--wrapper').on('mousemove touchmove', function (e) {
        e.preventDefault();
        if (e.type === 'mousemove') {
            this.relX = e.pageX - parentOffset.left;
        } else if (e.type === 'touchmove') {
            this.relX = e.originalEvent.touches[0].pageX - parentOffset.left;
        }

        if (jQuery(that.rootNode).find('.compare-slider--button').hasClass('move') && this.relX >= 0 && this.relX <= jQuery(this).width()) {
            jQuery(that.rootNode).find('.compare-slider--button').css({
                left: `${this.relX - jQuery(that.rootNode).find('.compare-slider--button').outerWidth() / 2}px`,
            });

            jQuery(that.rootNode).find('.compare-slider--img-left').css({
                width: `${this.relX}px`,
            });

            // Call Caption Animation

            if (this.relX >= jQuery(this).width() / 2) {
                that.caption('right');
            } else {
                that.caption('left');
            }
        }
    });


    // Snap Line per Touch or click;

    jQuery(this.rootNode).find('.compare-slider--wrapper').on('mousedown touchstart', function (e) {
        if (!jQuery(that.rootNode).find('.compare-slider--button').hasClass('move')) {
            // window.console.log(e.type);
            if (event.type === 'mousedown') {
                this.relX = e.pageX - parentOffset.left;
                that.snap(this.relX);
            } else if (event.type === 'touchstart') {
                this.relX = e.originalEvent.touches[0].pageX - parentOffset.left;
                that.snap(this.relX);
            }
        }
    });

    jQuery(this.rootNode).on('mouseleave', () => {
        jQuery(that.rootNode).find('.compare-slider--button').removeClass('move').removeClass('animate');
        jQuery(that.rootNode).find('.compare-slider--img-left').removeClass('animate');
    });
};

// Animate Snap Line

CompareSlider.prototype.snap = function (relX) {
    const rootNodeWidth = jQuery(this.rootNode).width();

    try {
        // window.console.log(jQuery(this.rootNode).find(".compare-slider--button").width() / 2, relX, rootNodeWidth);

        jQuery(this.rootNode).find('.compare-slider--button').addClass('animate').css({
            left: relX - jQuery(this.rootNode).find('.compare-slider--button').width() / 2,
        });

        jQuery(this.rootNode).find('.compare-slider--img-left').addClass('animate').css({
            width: relX,
        });

        if (jQuery(this.rootNode).find('.compare-slider--button').width() / 2 + relX > rootNodeWidth) {
            jQuery(this.rootNode).find('.compare-slider--button').addClass('animate').css({
                left: rootNodeWidth - jQuery(this.rootNode).find('.compare-slider--button').width() / 2,
            });

            jQuery(this.rootNode).find('.compare-slider--img-left').addClass('animate').css({
                width: rootNodeWidth,
            });
        }

        if (relX < jQuery(this.rootNode).find('.compare-slider--button').width() / 2) {
            jQuery(this.rootNode).find('.compare-slider--button').addClass('animate').css({
                left: -jQuery(this.rootNode).find('.compare-slider--button').width() / 2,
            });

            jQuery(this.rootNode).find('.compare-slider--img-left').addClass('animate').css({
                width: 0,
            });
        }

        // Call Caption Animation

        if (relX - jQuery(this.rootNode).find('.compare-slider--button').width() / 2 >= rootNodeWidth / 2) {
            this.caption('right');
        } else {
            this.caption('left');
        }
    } catch (err) {
        window.console.log(err.message);
    }
};


CompareSlider.prototype.caption = function (direction) {
    if (jQuery(this.rootNode).find('.compare-slider--caption-left').length > 0 && jQuery(this.rootNode).find('.compare-slider--caption-right').length > 0) {
        if (direction === 'left') {
            jQuery(this.rootNode).find('.compare-slider--caption-right').removeClass('hidden');
            jQuery(this.rootNode).find('.compare-slider--caption-left').addClass('hidden');
        }

        if (direction === 'right') {
            jQuery(this.rootNode).find('.compare-slider--caption-left').removeClass('hidden');
            jQuery(this.rootNode).find('.compare-slider--caption-right').addClass('hidden');
        }
    } else {
        jQuery(this.rootNode).find('.compare-slider--caption-right').removeClass('hidden');
    }
};
// Resize Container

CompareSlider.prototype.resize = function () {
    const that = this;
    let windowWidthLog = 0;

    jQuery(window).on('resize', () => {
        if (jQuery(window).width() !== windowWidthLog) {
            jQuery(that.rootNode).find('.compare-slider--img-left').removeAttr('style');
            jQuery(that.rootNode).find('.compare-slider--img-left img').removeAttr('style');
            jQuery(that.rootNode).find('.compare-slider--button').removeAttr('style');

            const wrapperWidth = jQuery(that.rootNode).find('.compare-slider--wrapper').width();

            jQuery(that.rootNode).find('.compare-slider--img-left img').css({
                minWidth: `${wrapperWidth}px`,
                maxWidth: `${wrapperWidth}px`,
            });

            windowWidthLog = jQuery(window).width();

            that.init();
        }
    });
};

jQuery.fn.compareSlider = function (opts) {
    return this.each(function () {
        new CompareSlider(this, opts);
    });
};
