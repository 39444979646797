

// Use of shareplugin
jQuery(document).on('page.ready', (event) => {
    try {
        jQuery('.share-button.facebook').not('.header .share-button.facebook').sharrre({
            share: {
                facebook: true,
            },
            enableHover: false,
            enableTracking: false,
            click(api, options) {
                api.simulateClick();
                api.openPopup('facebook');
            },
            template: "<span class='share-button__icon'></span><span class='share-button__count'>{total}</span>",
        });

        jQuery('.share-button.twitter').not('.header .share-button.twitter').sharrre({
            share: {
                twitter: true,
            },
            enableHover: false,
            enableTracking: false,
            click(api, options) {
                api.simulateClick();
                api.openPopup('twitter');
            },
            template: "<span class='share-button__icon'></span>",
        });
    } catch (err) {
        console.log('sharrre:', err.message);
    }
});

jQuery(() => {
    jQuery(document).trigger('page.ready');
});
