

function TrimText(rootNode, opts) {
    this.rootNode = rootNode;
    this.trimText(rootNode, opts);
}

TrimText.prototype.trimText = function(rootNode, opts) {
    const that = this;
    jQuery(this.rootNode).hide();
    console.log(opts.limit);
    const minimizedElements = this.rootNode,
        t = jQuery(minimizedElements).text();
    if (t.length < opts.limit) {
        return;
    }
    jQuery(this.rootNode).html(`${t.slice(0, opts.limit)}<span>&nbsp;... </span><a href='#' class='trimTextMore'>${opts.mehr}</a><span style='display:none;'>${t.slice(opts.limit, t.length)}&nbsp;<a href='#' class='trimTextLess'>${opts.weniger}</a></span>`);

    that.bindEvents(that.rootNode, opts);
};

TrimText.prototype.bindEvents = function(rootNode, opts) {
    const that = this;
    jQuery(rootNode).find('.trimTextMore').on('click', function(event) {
        event.preventDefault();
        jQuery(this).hide().prev().hide();
        jQuery(this).next().show();
    });
    jQuery(rootNode).find('.trimTextLess').on('click', function(event) {
        event.preventDefault();
        jQuery(this).parent().hide().prev()
            .show()
            .prev()
            .show();
    });

    jQuery(rootNode).show();
};

jQuery.fn.trimText = function(opts) {
    return this.each(function() {
        new TrimText(this, opts);
    });
};
