/* globals funky */
/* eslint-disable */
// TODO: Refactor for compliance with eslint rules

funky.BreakingNews = {};

funky.BreakingNews.Model = function (opts) {
    let options = {
        cookieName: 'bnc',
        cookieTtl: 2,
    };

    // - private functions
    function createCookie() {
        const currentCookieValue = window.readCookie(options.cookieName);
        if (currentCookieValue === null) { // just set cookie, if it does not exist
            window.createCookie(options.cookieName, '', options.cookieTtl);
        }
    }

    function init(opts) {
        options = $.extend(options, opts);

        createCookie();
    }

    // - privileged functions
    // cookie handling
    this.newsIsDisabled = function (articleId, lastModified) {
        const currentCookieValue = window.readCookie(options.cookieName);
        console.log('Model.newsIsDisabled:', 'articleId:', articleId, 'lastModified:', lastModified);
        return currentCookieValue != null && currentCookieValue.indexOf(`|${this.getArticleMarker(articleId, lastModified)}`) > -1;
    };

    this.storeNewsAsDisabled = function (articleId, lastModified) {
        console.dir(arguments);
        const currentCookieValue = window.readCookie(options.cookieName);
        window.createCookie(options.cookieName, `${currentCookieValue}|${this.getArticleMarker(articleId, lastModified)}`, options.cookieTtl);
    };

    // handling of the URI
    this.getWidgetUpdateUri = function () {
        return options.widgetUrl;
    };

    this.getArticleMarker = function (articleId, lastModified) {
        console.log('Model.getArticleMarker:', 'articleId:', articleId, 'lastModified:', lastModified);
        if (articleId !== undefined || lastModified !== undefined) {
            return `${articleId}-${(new Date(lastModified)).getTime()}`;
        }

        return '-';
    };

    // hook to make _private_ function init so to say ~public~ on construction time
    init(opts);
};

funky.BreakingNews.View = function ($elm, opts) {
    let options = {
            closeButtonCssClass: '.icon-close',
            articleLinkCssClass: '.breaking-news__article-link',
            menuContainerCssClass: '.header',
            mainContainerCssClass: '.main',
            pageContainerCssClass: '.page',
            breakingNewsInnContainerCssClass: '.breaking-news__inner-container',
            breakingNewsAnimateInCssClass: 'breaking-news-animate-in',
            breakingNewsAnimateOutCssClass: 'breaking-news-animate-out',
            breakingNewsAnimateInDuration: 500,
            breakingNewsAnimateOutDuration: 500,
            view: '',
        },
        $container = null,
        $innerContainer = null,
        $menu = null,
        $main = null,
        $page = null,
        $closeButton = null,
        $articleLink = null,
        windowWidth = null,
        isVisible = false;

    // - private functions
    // use $.utils.isMobileByWidth or $.utils.isDesktopByWidth
    function getMenuHeight() {
        return $menu.height();
    }

    function getPageYOffset() {
        return $page.offset().top;
    }

    function getMainRightPos() {
        return $main.position().left + $main.width();
    }

    function init($elm, opts) {
        options = $.extend(options, opts);
        $container = $elm;
        $innerContainer = $elm.find(options.breakingNewsInnContainerCssClass);
        $closeButton = $elm.find(options.closeButtonCssClass);
        $menu = $(options.menuContainerCssClass);
        $main = $(options.mainContainerCssClass);
        $page = $(options.pageContainerCssClass);
        windowWidth = $(window).width();
    }

    // - privileged functions
    // update the container with the newest breaking news content
    this.update = function (newContent) {
        $innerContainer.html(newContent);
    };

    this.getCurrentContent = function () {
        return $innerContainer.html();
    };

    this.hideContainer = function () {
        $container.removeClass(options.breakingNewsAnimateInCssClass);
        $container.hide();
        isVisible = false;
    };

    this.showContainer = function () {
        if (options.view === 'mobile' || windowWidth <= 1024) {
            $container.fadeIn(200);
        } else {
            $container.show();
            $container.addClass(options.breakingNewsAnimateInCssClass);
        }
        isVisible = true;
    };

    this.animateContainerOut = function () {
        $container.removeClass(options.breakingNewsAnimateInCssClass);
        $container.addClass(options.breakingNewsAnimateOutCssClass);
        isVisible = false;
        setTimeout(() => {
            $container.hide();
            $container.removeClass(options.breakingNewsAnimateOutCssClass);
        }, options.breakingNewsAnimateOutDuration);
    };

    this.getCurrentArticle = function () {
        const $currentArticle = $container.find('[data-art-id][data-art-lmd]').first();
        return $currentArticle;
    };

    this.getCloseButton = function () {
        return $closeButton;
    };

    /**
	 * need to look any call time, 'cause the content of inner-container changes
	 * @returns jQuery-Object
	 */
    this.getArticleLink = function () {
        console.dir('getArticleLink', '$articleLink', $container.find(options.articleLinkCssClass));
        return $container.find(options.articleLinkCssClass);
    };

    this.isVisible = function () {
        return isVisible === true;
    };

    this.getContainer = function () {
        return $container;
    };

    this.getInnerContainer = function () {
        return $innerContainer;
    };

    this.getOptions = function () {
        return options;
    };

    // hook to make _private_ function init so to say ~public~ on construction time
    init($elm, opts);
};

funky.BreakingNews.Controller = function ($elm, opts) {
    let options = {
            updateInterval: 2 * 60 * 1000, // 2 minutes
        },
        model = null,
        view = null;

    // - private functions
    // event handler
    function initEventHandler() {
        view.getCloseButton().on('click', onCloseButtonClickHandler);
        view.getInnerContainer().on('click', onInnerContainerClickHandler);
        view.getContainer().one('click', view.getOptions().articleLinkCssClass, trackClick);
    }

    function onCloseButtonClickHandler(e) {
        e.preventDefault();
        view.animateContainerOut();
        // save the articleMarker in model
        const $currentArticle = view.getCurrentArticle();
        model.storeNewsAsDisabled($currentArticle.data('art-id'), $currentArticle.data('art-lmd'));
    }

    function onInnerContainerClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        // save the articleMarker in model
        const $currentArticle = view.getCurrentArticle();
        model.storeNewsAsDisabled($currentArticle.data('art-id'), $currentArticle.data('art-lmd'));
        console.dir(view.getArticleLink());
        window.location.href = view.getArticleLink().attr('href');
    }

    function getMarkerFromCurrentArticle() {
        const $currentArticle = view.getCurrentArticle();
        console.log('getting marker from current article:', model.getArticleMarker($currentArticle.data('art-id'), $currentArticle.data('art-lmd')));
        return model.getArticleMarker($currentArticle.data('art-id'), $currentArticle.data('art-lmd'));
    }

    function getMarkerFromAjaxString(data) {
        const dataHtml = $(data);
        console.log('getting marker from ajax string:', model.getArticleMarker(dataHtml.data('art-id'), dataHtml.data('art-lmd')));
        return model.getArticleMarker(dataHtml.data('art-id'), dataHtml.data('art-lmd'));
    }

    function compareCurrentLocationWithArticleId() {
        const currentLocation = (window.location.href);
        if (currentLocation.indexOf('-id') > -1) {
            let $currentArticle = view.getCurrentArticle(),
                currentLocationArticleId = parseInt(currentLocation.match(new RegExp(/\d+(?!.*-)/)), 10);
            if (currentLocationArticleId === $currentArticle.data('art-id')) {
                model.storeNewsAsDisabled($currentArticle.data('art-id'), $currentArticle.data('art-lmd'));
            }
        }
    }

    function update(updateType) {
        $.get(model.getWidgetUpdateUri()).done((data, textStatus, $xhr) => {
            console.dir('updating');
            const currentArticle = view.getCurrentArticle();
            console.log('current article: ', currentArticle);
            console.log('not disabled: ', !model.newsIsDisabled(currentArticle.data('art-id'), currentArticle.data('art-lmd')));
            console.log('is visible: ', view.isVisible());
            data = data.replace(/\s?\/\>/g, '>');
            if (data !== '') {
                if (getMarkerFromCurrentArticle() !== getMarkerFromAjaxString(data)) {
                    if (view.isVisible()) {
                        view.hideContainer();
                    }
                    view.update(data);
                    const $currentArticle = view.getCurrentArticle();
                    compareCurrentLocationWithArticleId();
                    if (!model.newsIsDisabled($currentArticle.data('art-id'), $currentArticle.data('art-lmd'))) {
                        view.showContainer();
                    }
                }
            } else if (view.isVisible()) {
                view.hideContainer();
            }
            if (updateType === 'init' && view.isVisible()) {
                trackVisible();
            }
        }).fail(function () {
            window.console.error(arguments);
        }).done(() => {
            triggerNextUpdate();
        });
    }

    function triggerNextUpdate() {
        window.setTimeout(
            update,
            options.updateInterval,
        );
    }

    function trackVisible() {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'news_toast',
                toast_action: 'news_toast_view',
            });
        }
    }

    function trackClick() {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'news_toast',
                toast_action: 'news_toast_klick',
            });
        }
    }

    function init($elm, opts) {
        options = $.extend(options, opts);

        if (typeof options.widgetUrl === 'undefined') {
            options.widgetUrl = $elm.data('breaking-news-url');
        }
        if (typeof options.widgetUrl === 'undefined') {
            throw 'options.widgetUrl is missing. Aborting!';
        }
        model = new funky.BreakingNews.Model(options);
        view = new funky.BreakingNews.View($elm, options);

        initEventHandler();
        update('init');
    }

    // hook to make _private_ function init so to say ~public~ on construction time
    init($elm, opts);
};

$.fn.breakingNews = function (opts) {
    return this.each(function () {
        new funky.BreakingNews.Controller($(this), opts);
    });
};
