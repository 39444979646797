// Keycloak login plugin
$(() => {
    class KeycloakLogin {
        constructor(options) {
            this.loginUrl = options.loginUrl;
            this.logoutUrl = options.logoutUrl;
            this.registrationUrl = options.registrationUrl;
            this.changePasswordUrl = options.changePasswordUrl;
            this.init();
        }

        init() {
            if (this.isSetupCorrectly()) {
                this.registerHashtagHandler();
                this.registerLoginHandler();
                this.registerLogoutHandler();
                this.redirectHandler();
                this.connectKeycloakAndPiano();
            }
        }

        isSetupCorrectly() {
            const errorPrefix = 'ERROR IN keycloakLogin.js:';
            let setupCorrect = true;

            if (typeof window.jwt_decode === 'undefined') {
                console.error(`${errorPrefix} "jwt_decode()" is "undefined"!`);
                setupCorrect = false;
            } else if (!this.loginUrl) {
                console.error(`${errorPrefix} "loginUrl" is "undefined"!`);
                setupCorrect = false;
            } else if (!this.logoutUrl) {
                console.error(`${errorPrefix} "logoutUrl" is "undefined"!`);
                setupCorrect = false;
            }

            return setupCorrect;
        }

        /* eslint-disable */
        createUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
        /* eslint-enable */

        /**
         * Get previous URL from local storage or return home URL
         */
        // eslint-disable-next-line class-methods-use-this
        getPreviousUrl() {
            const homeUrl = window.location.origin;

            return window.localStorage.getItem('keycloak-previous-url') || homeUrl;
        }

        /**
         * Get redirect URL, where JWT can be read from the URL
         */
        // eslint-disable-next-line class-methods-use-this
        getRedirectUrl() {
            return `${window.location.origin}/resources/static/keycloak-redirect.html`;
        }

        /**
         * Register handler fo hashtag handling
         */
        registerHashtagHandler() {
            this.redirectByHash();
            window.addEventListener('hashchange', () => {
                this.redirectByHash();
            }, false);
        }

        /**
         * Redirect to login/logout according to hashtag
         */
        redirectByHash() {
            if (window.location.hash === '#keycloak-login') {
                this.goToLogin(true);
            } else if (window.location.hash === '#keycloak-logout') {
                this.goToLogout(true);
            } else if (window.location.hash === '#keycloak-registration') {
                this.goToRegistration(true);
            } else if (window.location.hash === '#keycloak-change-password') {
                this.goToChangePassword(true);
            }
        }

        /**
         * Redirect to login when clicking "Anmelden" Button
         */
        registerLoginHandler() {
            $('.header-top-button--keycloak-login').click((e) => {
                e.preventDefault();
                this.goToLogin();
            });
        }

        /**
         * Redirect to logout when clicking "Abmelden" Button
         */
        registerLogoutHandler() {
            $('.header-top-button--keycloak-logout').click((e) => {
                e.preventDefault();
                this.goToLogout();
            });
        }

        goToLogin(isRedirect) {
            const nonce = this.createUUID();
            const redirectUrl = this.getRedirectUrl();
            const uri = encodeURIComponent(redirectUrl);
            const loginUrl = this.loginUrl
                .replace('{nonce}', nonce)
                .replace('{uri}', uri);
            if (isRedirect) {
                window.location.replace(loginUrl);
            } else {
                window.location.href = loginUrl;
            }
        }

        goToLogout(isRedirect) {
            window.localStorage.removeItem('loggedin');
            const uri = encodeURIComponent(this.getPreviousUrl());
            const jwt = window.sessionStorage.getItem('jwt') || '';
            let logoutUrl;
            if (jwt) {
                window.sessionStorage.setItem('oldjwt', jwt);
                window.sessionStorage.removeItem('jwt');
                window.tp.pianoId.logout();
                logoutUrl = this.logoutUrl.replace('{uri}', uri).replace('{loginToken}', jwt);
            } else {
                logoutUrl = this.getPreviousUrl();
            }
            if (isRedirect) {
                window.location.replace(logoutUrl);
            } else {
                window.location.href = logoutUrl;
            }
        }

        goToRegistration(isRedirect) {
            const nonce = this.createUUID();
            const redirectUrl = this.getRedirectUrl();
            const uri = encodeURIComponent(redirectUrl);
            const registrationUrl = this.registrationUrl
                .replace('{nonce}', nonce)
                .replace('{uri}', uri);
            if (isRedirect) {
                window.location.replace(registrationUrl);
            } else {
                window.location.href = registrationUrl;
            }
        }

        goToChangePassword(isRedirect) {
            const nonce = this.createUUID();
            const redirectUrl = this.getRedirectUrl();
            const uri = encodeURIComponent(redirectUrl);
            const changePasswordUrl = this.changePasswordUrl
                .replace('{nonce}', nonce)
                .replace('{uri}', uri);
            if (isRedirect) {
                window.location.replace(changePasswordUrl);
            } else {
                window.location.href = changePasswordUrl;
            }
        }

        redirectHandler() {
            const urlHash = window.location.hash.substr(1);
            const hashParams = new URLSearchParams(urlHash);
            const jwt = hashParams.get('id_token') || '';
            let decodedJwt = '';

            if (!jwt) {
                return;
            }

            try {
                decodedJwt = window.jwt_decode(jwt);
            } catch (e) {
                return;
            }

            if (decodedJwt.evolverid === ' ') {
                this.goToLogin();
            } else {
                window.sessionStorage.setItem('jwt', jwt);
                window.location.href = this.getPreviousUrl();
            }
        }

        connectKeycloakAndPiano() {
            // ignore Keycloak hashtag redirect urls to prevent loops
            if (window.location.hash.indexOf('#keycloak') < 0) {
                window.localStorage.setItem('keycloak-previous-url', window.location.href);
            }
            window.localStorage.setItem('keycloak-login-url', this.loginUrl);

            window.tp = window.tp || [];
            window.tp.push(['setUseTinypassAccounts', false]);
            window.tp.push(['setUsePianoIdUserProvider', false]);
            window.tp.push(['setUsePianoIdLiteUserProvider', true]);
            window.tp.push(['setExternalJWT', window.sessionStorage.jwt]);
            window.tp.push(['addHandler', 'externalCheckoutComplete', function () {
                window.location.reload();
            }]);

            window.tp.push([
                'addHandler',
                'experienceExecute',
                (e) => {
                    // eslint-disable-next-line array-callback-return
                    e.accessList.map((access) => {
                        if (access.active) {
                            document.getElementById('paywall-header-logedin').checked = true;
                            window.localStorage.setItem('loggedin', 'true');
                        }
                    });
                    if (e.user && e.user.uid !== 'anon') {
                        document.getElementById('paywall-header-logedin').checked = true;
                        window.localStorage.setItem('loggedin', 'true');
                    }
                },
            ]);

            window.tp.push([
                'addHandler',
                'loginSuccess',
                function (data) {
                    document.getElementById('paywall-header-logedin').checked = true;
                    if (data.source === 'PIANOID') {
                        // This reloads the page after direct login (NOT login inside checkout)
                        window.location.reload();
                    }
                    window.localStorage.setItem('loggedin', 'true');
                },
            ]);

            if (window.localStorage.getItem('loggedin') === 'true') {
                if (!window.sessionStorage.getItem('jwt')) {
                    window.localStorage.removeItem('loggedin');
                    this.goToLogin();
                }
            }
        }
    }

    if (typeof window.fdKeycloakLoginOptions === 'object') {
        const options = window.fdKeycloakLoginOptions;

        if (options.enabled === true) {
            new KeycloakLogin(options);
        }
    }
});
