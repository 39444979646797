(($) => {
    class SocialMediaWidget {
        constructor(rootNode, opts) {
            this.rootNode = rootNode;
            this.rawPortalName = opts.portal;
            this.portal = opts.portal.toLowerCase();
            this.embedId = opts.embedId;
            // Facebook only
            this.type = opts.type;
            this.privacyUrl = opts.privacyUrl || '';
            this.isEmbedded = false;
            this.isRendered = false;
            this.consentBoxDisabled = opts.consentBoxDisabled === 'true';
            this.renderedInterval = null;
            this.apiLog = false;
            this.$consentBox = $('<div />');
            this.$toggle = $('<input />');
            // @todo investigate for FDSUPPORT-5956 (paywall-container on mobile safari)
            this.embedWidgetId = $(this.rootNode).is('FIGURE') ? $(this.rootNode).parent('.social-embed').attr('id') : $(this.rootNode).attr('id');
            this.portalNames = {
                facebook: 'Facebook',
                twitter: 'Twitter',
                instagram: 'Instagram',
                opinary: 'Opinary',
                pinterest: 'Pinterest',
                vimeo: 'Vimeo',
                youtube: 'YouTube',
                xhtml: 'verschiedenen Quellen',
            };
            this.init();
        }

        init() {
            if (!this.consentBoxDisabled) {
                this.renderConsentBox();
            }
            this.refresh();

            $(document).on('socialMediaConsentChanged', () => {
                this.refresh();
            });
        }

        refresh() {
            if (this.isConsented() || this.consentBoxDisabled) {
                if (!this.isEmbedded) {
                    this.isEmbedded = true;
                    if (this.portal === 'facebook') {
                        this.embedFacebook();
                    } else if (this.portal === 'twitter') {
                        this.embedTwitter();
                    } else if (this.portal === 'instagram') {
                        this.embedInstagram();
                    } else if (this.portal === 'pinterest') {
                        this.embedPinterest();
                    } else if (this.portal === 'vimeo') {
                        this.embedVimeo();
                    } else {
                        // YouTube, XHTML and others use this embed
                        this.embedXhtml();
                    }
                }
                if (!this.consentBoxDisabled) {
                    this.showCompactView();
                }
            } else if (!this.consentBoxDisabled) {
                this.showDescriptionView();
            }
        }

        renderConsentBox() {
            const $headline = $('<strong />')
                .addClass('consent-box__headline')
                .text('Empfohlener externer Inhalt');
            const $description = $('<div />')
                .addClass(`consent-box__description consent-box__description--${this.portal}`)
                .text(`An dieser Stelle befindet sich ein externer Inhalt von ${this.getDisplayPortalName()},
                    der von unserer Redaktion empfohlen wird. Er ergänzt den Artikel und kann mit einem Klick angezeigt
                    und wieder ausgeblendet werden.`);
            const $toggleWrapper = $('<label>')
                .addClass('consent-box__toggle-wrapper')
                .attr('title', `Empfohlene externe Inhalte von ${this.getDisplayPortalName()} ein-/ausschalten`);
            this.$toggle
                .attr({
                    type: 'checkbox',
                    name: 'consent',
                })
                .addClass('consent-box__toggle');
            const $toggleVisual = $('<strong />')
                .addClass('consent-box__toggle-visual');
            $toggleWrapper.append(this.$toggle, $toggleVisual);
            const $privacyText = $('<div />')
                .addClass('consent-box__privacy-text')
                .html(`Ich bin damit einverstanden, dass mir dieser externe Inhalt angezeigt wird.
                    Es können dabei personenbezogene Daten an den Anbieter des Inhalts und Drittdienste übermittelt
                    werden. Mehr dazu in unserer <a href="${this.privacyUrl}">Datenschutzerklärung</a>.`);
            const $privaryUrl = $('<a />')
                .addClass('consent-box__privacy-url')
                .attr('href', this.privacyUrl)
                .text('Datenschutzerklärung');

            if (this.isConsented()) {
                this.$toggle[0].checked = true;
            }
            this.$toggle.on('change', () => {
                this.handleToggleConsent();
            });
            this.$consentBox
                .addClass('consent-box')
                .append($headline, $description, $toggleWrapper, $privacyText, $privaryUrl);
            this.$consentBox.insertAfter($(this.rootNode).closest('.social-embed'));
        }

        handleToggleConsent() {
            const wasConsented = this.isConsented();
            this.persistConsent(!wasConsented);
            $(document).trigger('socialMediaConsentChanged');

            if (wasConsented && !this.isConsented()) {
                // Reload page to unload all libraries and tracking stuff
                document.location.reload();
            }
        }

        getDisplayPortalName() {
            return this.portalNames[this.portal] || this.rawPortalName;
        }

        persistConsent(isAllowed) {
            window.sessionStorage.setItem(`funke-social-media-consent-${this.portal}`, isAllowed ? 'allowed' : 'not-allowed');
        }

        isConsented() {
            return window.sessionStorage.getItem(`funke-social-media-consent-${this.portal}`) === 'allowed';
        }

        showDescriptionView() {
            this.$toggle[0].checked = false;
            this.$consentBox
                .addClass('consent-box--show-description')
                .removeClass('consent-box--show-compact');
        }

        showCompactView() {
            this.$toggle[0].checked = true;
            this.$consentBox
                .addClass('consent-box--show-compact')
                .removeClass('consent-box--show-description');
        }

        embedFacebook() {
            $(this.rootNode).addClass('social-facebook');

            // Append HTML elements described by Facebook's docs
            // https://developers.facebook.com/docs/plugins/embedded-posts
            // https://developers.facebook.com/docs/plugins/embedded-video-player
            // https://developers.facebook.com/docs/plugins/embedded-comments
            let fbElem;
            const width = Math.floor($(this.rootNode).width());
            switch (this.type) {
            case 'post':
                fbElem = `<div class="fb-post" data-href="${this.embedId}" data-width="${width}"></div>`;
                break;
            case 'video':
                fbElem = `<div class="fb-video" data-href="${this.embedId}" data-allowfullscreen="true"></div>`;
                break;
            case 'comment-embed':
                // make the element at least 560px in width because facebook won't render it any smaller, even though
                // the doc says so
                fbElem = `<div class="fb-comment-embed" data-href="${this.embedId}" data-width="${Math.max(560, width)}"></div>`;
                break;
            }
            $(this.rootNode).append(fbElem);

            // Embed Facebook connect or trigger re-render
            if (window.FB) {
                window.FB.XFBML.parse();
            } else {
                (function (d, s, id) {
                    const fjs = d.getElementsByTagName(s)[0];

                    if (d.getElementById(id)) {
                        return;
                    }

                    const js = d.createElement(s);
                    js.id = id;
                    js.src = '//connect.facebook.net/de_DE/all.js';
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            }

            // Set up the logging function
            const facebookSdkVersion = 'v2.8',
                facebookSdkAppId = '1650819678556421';

            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: facebookSdkAppId,
                    xfbml: true,
                    version: facebookSdkVersion,
                });
                if (window.FB.AppEvents) {
                    window.FB.AppEvents.logPageView();
                }
            };

            $(document).on('elementRendered', () => {
                this.postprocessFacebook();
            });

            this.checkRendered();
        }

        postprocessFacebook() {
            this.elementRenderData = 'fb-xfbml-state';

            if ($(this.rootNode).find(`.fb-${this.type}`).attr(this.elementRenderData) === 'rendered') {
                if (!this.isRendered) {
                    $(`#${this.embedWidgetId}`).facebookInlineResizer({
                        type: 'socialEmbed',
                    });
                    this.isRendered = true;
                    clearInterval(this.renderedInterval);
                    this.showEmbeddedMedia();
                }
            }
        }

        embedTwitter() {
            if ($("script[src='//platform.twitter.com/widgets.js']").length === 0) {
                window.twttr = (function (d, s, id) {
                    const fjs = d.getElementsByTagName(s)[0],
                        t = window.twttr || {};
                    if (d.getElementById(id)) {
                        return t;
                    }
                    const js = d.createElement(s);
                    js.id = id;
                    js.src = '//platform.twitter.com/widgets.js';
                    fjs.parentNode.insertBefore(js, fjs);

                    /* eslint-disable */
                    t._e = [];
                    t.ready = function (f) {
                        t._e.push(f);
                        window.twttr.widgets.load(document.getElementById(this.embedWidgetId));
                    };
                    /* eslint-enable */
                    return t;
                }(document, 'script', 'twitter-wjs'));
            }

            $(document).on('elementRendered', () => {
                this.postprocessTwitter();
            });

            this.checkRendered();
        }

        postprocessTwitter() {
            this.elementClass = 'twitter-tweet';
            this.elementRenderClass = 'twitter-tweet-rendered';

            if ($(this.rootNode).find(`.${this.elementClass}`).hasClass(this.elementRenderClass)) {
                if (!this.isRendered) {
                    this.isRendered = true;
                    clearInterval(this.renderedInterval);
                    this.showEmbeddedMedia();
                }
            }
        }

        embedInstagram() {
            if ($("script[src='//platform.instagram.com/de_DE/embeds.js']").length === 0) {
                this.jsCode = document.createElement('script');
                this.jsCode.type = 'text/javascript';
                this.jsCode.async = true;
                this.jsCode.defer = true;
                this.jsCode.setAttribute('src', '//platform.instagram.com/en_US/embeds.js');
                document.body.appendChild(this.jsCode);
            }

            $(document).on('elementRendered', () => {
                this.postprocessInstagram();
            });

            this.checkRendered();
        }

        postprocessInstagram() {
            try {
                if (!this.apiLog) {
                    window.instgrm.Embeds.process();
                    this.apiLog = true;
                    window.console.log('Instargam Api Found');
                }
            } catch (err) {
                window.console.log(this.portal, err.message);
            }

            this.elementClass = 'instagram-media';
            this.elementRenderClass = 'instagram-media-rendered';

            if ($(this.rootNode).find(`.${this.elementClass}`).hasClass(this.elementRenderClass)) {
                if (!this.isRendered) {
                    this.isRendered = true;
                    clearInterval(this.renderedInterval);
                    this.showEmbeddedMedia();
                }
            }
        }

        embedPinterest() {
            if ($("script[src='//assets.pinterest.com/js/pinit.js']").length === 0) {
                this.jsCode = document.createElement('script');
                this.jsCode.async = true;
                this.jsCode.defer = true;
                this.jsCode.setAttribute('src', '//assets.pinterest.com/js/pinit.js');
                document.body.appendChild(this.jsCode);
            }

            $(document).on('elementRendered', () => {
                this.postprocessPinterest();
            });

            this.checkRendered();
        }

        postprocessPinterest() {
            if ($(this.rootNode).find('[data-pin-log^=embed_pin]').length) {
                if (!this.isRendered) {
                    this.isRendered = true;
                    clearInterval(this.renderedInterval);
                    $(`#${this.embedWidgetId}`).pinterestInlineResizer();
                    this.showEmbeddedMedia();
                }
            }
        }

        embedVimeo() {
            const iframeHeight = $(this.rootNode).find('iframe').height(),
                iframeWidth = $(this.rootNode).find('iframe').width(),
                iframeNewHeight = Math.round(iframeHeight * ($(this.rootNode).width() / iframeWidth), 10);

            $(this.rootNode).find('iframe').css({
                width: '100%',
                height: `${iframeNewHeight}px`,
            });

            $(document).on('elementRendered', () => {
                this.postprocessVimeo();
            });

            this.checkRendered();
        }

        postprocessVimeo() {
            this.elementClass = 'iframe';

            $(this.rootNode).find(this.elementClass).on('load', () => {
                this.isRendered = true;
                clearInterval(this.renderedInterval);
                this.showEmbeddedMedia();
            });
        }

        embedXhtml() {
            const $rootNode = $(this.rootNode);
            const templateString = $rootNode.attr('data-embed-html');
            const keepScriptTags = true;
            const $template = $.parseHTML(templateString, document, keepScriptTags);
            $rootNode.replaceWith($template);
            this.isRendered = true;
        }

        checkRendered() {
            $(document).trigger('elementRendered');

            this.renderedInterval = setInterval(() => {
                if (!this.isRendered) {
                    $(document).trigger('elementRendered');
                } else {
                    clearInterval(this.renderedInterval);
                }
            }, 1000);

            // Clear Interval after max 30s
            setTimeout(() => {
                clearInterval(this.renderedInterval);
                this.showEmbeddedMedia();
            }, 30000);
        }

        showEmbeddedMedia() {
            // Show social container with 1.5s delay
            setTimeout(() => {
                // @todo investigate for FDSUPPORT-5956 (paywall-container on mobile safari)
                const $widgetNode = $(this.rootNode).is('FIGURE') ? $(this.rootNode).parent('.social-embed') : $(this.rootNode);
                $widgetNode.removeClass('widget-hidden');
            }, 1500);
        }
    }

    $.fn.socialMediaWidget = function (opts) {
        return this.each(function () {
            new SocialMediaWidget(this, opts);
        });
    };
})(jQuery);
